import { CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import breakpoints from '~/styles/breakpoints.module.scss';

import './ModalMobile.scss';

const breakpointMobile = parseInt(breakpoints.xsMax.slice(0, -2));

// @todo Refacto with api notification

function ModalMobile() {
  const [visible, setVisible] = useState(() => {
    // Check sessionStorage to see if the modal should be visible for this session
    const storedValue = sessionStorage.getItem('adsCatalogueModalVisible');
    return window.innerWidth < breakpointMobile && (storedValue === null || storedValue === 'true');
  });

  useEffect(() => {
    // Save the visibility status in sessionStorage when it changes
    sessionStorage.setItem('adsCatalogueModalVisible', String(visible));
  }, [visible]);

  return (
    <>
      {visible && (
        <div className='modal-info-mobile-block'>
          <p className='body1'>
            Les listes de commandes et d'annonces ne sont peut-être pas adaptées à votre téléphone. Utilisez votre téléphone en format paysage ou consultez
            cette page sur votre ordinateur.
          </p>
          <CloseOutlined className='modal-close-icon' onClick={() => setVisible(false)} />
        </div>
      )}
    </>
  );
}

export default ModalMobile;
