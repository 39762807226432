import { HeatMapOutlined } from '@ant-design/icons';
import IconCategoryBook from '~/assets/svg/icon-categ-book.svg?react';
import IconCategoryGames from '~/assets/svg/icon-categ-games.svg?react';
import IconCategoryHouse from '~/assets/svg/icon-categ-house.svg?react';
import IconCategoryMode from '~/assets/svg/icon-categ-mode.svg?react';
import IconCategoryTech from '~/assets/svg/icon-categ-tech.svg?react';
import { CATEGORY_API_IDS } from '~/helpers/adDetails';

const { MAISON, MODE, LOISIRS, LIBRAIRIE, HIGH_TECH_ELECTRO } = CATEGORY_API_IDS;

export default function categoryIdToIconMapper(categoryId) {
  const categoryIcon = {
    [MAISON.ID]: IconCategoryHouse,
    [MODE.ID]: IconCategoryMode,
    [LOISIRS.ID]: IconCategoryGames,
    [LIBRAIRIE.ID]: IconCategoryBook,
    [HIGH_TECH_ELECTRO.ID]: IconCategoryTech,
  };

  if (Object.keys(categoryIcon).includes(String(categoryId))) {
    return categoryIcon[categoryId];
  }
  return HeatMapOutlined;
}
