import { Flex, Input } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { AD_DETAILS_API_IDS } from '~/helpers/adDetails';
import { HANDLE_CHANGE } from '~/store/reducers/products';

import './Comment.scss';

const commentKey = AD_DETAILS_API_IDS.ATTRIBUTES.COMMENT;

function Comment({ dispatch, editionMode, commentValue }) {
  const displayHelper = !editionMode || !commentValue;
  return (
    <>
      <div className='form-item-header'>
        <Flex justify='space-between' align='center'>
          <p className='sub2'>Commentaire</p>
        </Flex>
      </div>
      <Flex vertical gap={8}>
        {displayHelper && <p className='body1'>Ajouter une note interne afin de communiquer entre vous. Elle ne sera pas visible sur l'annonce.</p>}
        <Input.TextArea
          className='comment-input'
          id={commentKey}
          defaultValue={commentValue}
          autoSize
          onBlur={(event) => {
            dispatch({
              type: HANDLE_CHANGE,
              payload: {
                input_key: AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY,
                substate_key: commentKey,
                value: event.target.value,
              },
            });
          }}
        />
      </Flex>
    </>
  );
}

export default connect((state) => {
  const { form_fields } = state.products;
  const commentValue = form_fields[AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY]?.[commentKey];
  return {
    commentValue,
  };
})(Comment);
