import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { adsCatalogueDataMongoValue, getAdsCatalogueData } from '~/helpers/cookies';
import { api_root_url } from '~/services/api';

export const useAdsExport = (merchantId) => {
  return useQuery({
    queryKey: ['exportAdsCsv', merchantId, window.location.search],
    queryFn: () => {
      const dataType = getAdsCatalogueData();
      let baseUrl;
      if (dataType === adsCatalogueDataMongoValue) {
        baseUrl = `/v2/${merchantId}/mongo_products/export/`;
      } else {
        baseUrl = `/${merchantId}/products/export/`;
      }
      return axios
        .get(`${api_root_url}${baseUrl}${window.location.search}`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data) {
            return new Blob([res.data], { type: 'text/csv' });
          }
        });
    },
    enabled: false,
    retry: false,
  });
};

export const useOrdersExport = (merchantId) => {
  return useQuery({
    queryKey: ['exportOrdersCsv', merchantId, window.location.search],
    queryFn: () =>
      axios
        .get(`${api_root_url}/${merchantId}/orders/export/${window.location.search}`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data) {
            return new Blob([res.data], { type: 'text/csv' });
          }
        }),
    enabled: false,
    retry: false,
  });
};
