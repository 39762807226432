import { App, Button, Table } from 'antd';
import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { getOrdersPickingInfo } from '~/services/api.calls';

import './PickingRoute.scss';

export default function PickingRoute({ merchantId, orderIds }) {
  const { notification } = App.useApp();

  const [isLoading, setIsLoading] = useState(false);
  const pickingRouteRef = useRef();
  const [pickingRouteInfo, setPickingRouteInfo] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => pickingRouteRef.current,
  });

  const printPickingRoute = () => {
    setIsLoading(true);
    getOrdersPickingInfo(
      merchantId,
      orderIds,
      (data) => {
        setIsLoading(false);
        // Max length of names is 100 characters
        setPickingRouteInfo(data.map((item) => ({ ...item, name: item.name.length > 65 ? item.name.slice(0, 65) + '...' : item.name })));
        handlePrint();
      },
      (error) => {
        setIsLoading(false);
        notification.open({
          className: 'notification-dialogue-error',
          message: 'Erreur lors de la génération de la feuille de picking',
          description: error.toString(),
          placement: 'topRight',
        });
      }
    );
  };
  return (
    <>
      <Button type='primary' disabled={isLoading} onClick={printPickingRoute}>
        Imprimer ma feuille de picking
      </Button>
      <div className='hidden'>
        <div ref={pickingRouteRef} className='picking-route-wrapper'>
          <Table
            columns={[
              {
                title: 'SKU',
                dataIndex: 'sku',
                key: 'sku',
                className: 'sku',
              },
              {
                title: 'Nom',
                dataIndex: 'name',
                key: 'name',
                className: 'name',
              },
              {
                title: 'Emplacement',
                dataIndex: 'storage',
                key: 'storage',
                className: 'storage',
              },
              {
                title: 'Quantité',
                dataIndex: 'quantity',
                key: 'quantity',
                width: 75,
              },
            ]}
            dataSource={pickingRouteInfo}
            pagination={false}
          />
        </div>
      </div>
    </>
  );
}
