import { Spin } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { MerchantExternalRouting } from '~/helpers/general';
import { useOrderToConfirmCount } from '~/hooks/useOrders';

import './NewOrdersInfo.scss';

function NewOrdersInfo({ activeMerchant }) {
  const now = dayjs().startOf('day');
  const { isLoading, data } = useOrderToConfirmCount(activeMerchant.id);

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spin />
      </div>
    );
  }

  return (
    <div className='new-orders-block'>
      <a
        className={`${!data ? 'anchor-disabled' : ''}`}
        target='_blank'
        rel='noopener noreferrer'
        href={MerchantExternalRouting.orders(activeMerchant.merchant_slug)}
      >
        <div className='new-order-info'>
          {data ? (
            <>
              <p className='sub1'>Hourra ! vous avez : </p>
              <p className='sub1 new-order-count'>
                {data} nouvelle{data > 1 && 's'} commandes
              </p>
            </>
          ) : (
            <p className='sub1'>Pour l'instant, vous n'avez pas de nouvelles commandes à confirmer.</p>
          )}
        </div>
        <p className='caption1'>Le {now.format('DD.MM.YYYY')}</p>
      </a>
    </div>
  );
}

export default connect((state) => ({ activeMerchant: state.session.active_merchant }))(NewOrdersInfo);
