import { Button, Descriptions, Divider, Flex, Modal, Radio, Select } from 'antd';
import { useAtom } from 'jotai';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { adsCatalogueDataWithCookieAtom } from '~/atoms';
import ExpandableContent from '~/components/shared/ExpandableContent/ExpandableContent';
import { adsCatalogueDataElasticValue, adsCatalogueDataMongoValue, getFavoriteMerchantId, setFavoriteMerchantId } from '~/helpers/cookies';
import { ACTIVE_MERCHANT_UPDATE, USER_LOGOUT } from '~/store/reducers/session';

import './MyAccount.scss';

function MyAccount({ activeMerchant, merchants, dispatch }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [adsCatalogueData, setAdsCatalogueData] = useAtom(adsCatalogueDataWithCookieAtom);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const paramValue = useRef();

  const merchantsCleaned = merchants.filter((merchant) => merchant.id !== activeMerchant.id);
  const merchantOptions = merchantsCleaned.map((merchant) => ({
    value: merchant.id,
    label: merchant.name,
    slug: merchant.slug,
  }));

  const deconnectUser = () => {
    dispatch({ type: USER_LOGOUT });
    setIsModalOpen(false);
  };

  const changeMerchant = (idMerchant) => {
    dispatch({
      type: ACTIVE_MERCHANT_UPDATE,
      payload: { merchant_id: idMerchant },
    });
    setIsModalOpen(false);
  };

  const filterOption = (input, option) => {
    const name = option?.label ?? '';
    const slug = option?.slug ?? '';
    const id = option?.value ?? '';

    return name.toLowerCase().includes(input.toLowerCase()) || slug.toLowerCase().includes(input.toLowerCase()) || id.includes(input);
  };

  const descriptionItems = [
    {
      key: '1',
      label: 'Nom',
      children: <p>{activeMerchant.name}</p>,
    },
    {
      key: '2',
      label: 'Id marchand',
      children: <p>{activeMerchant.id}</p>,
    },
  ];

  return (
    <>
      <a
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <span className='body1'>Mon compte</span>
      </a>
      <Modal
        centered
        open={isModalOpen}
        footer={null}
        closeIcon={null}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <div className='my-account-body'>
          <Descriptions title='Informations du compte' items={descriptionItems} size='middle' />
          {merchantOptions.length > 0 && activeMerchant.id !== getFavoriteMerchantId() && (
            <Button
              type='link'
              onClick={() => {
                setFavoriteMerchantId(activeMerchant.id);
                setIsModalOpen(false);
              }}
            >
              Définir comme marchand favori
            </Button>
          )}
          <Divider />
          <ExpandableContent title="Paramètre de ma liste d'annonces" titleClassName='body2' withArrow>
            <Radio.Group
              value={adsCatalogueData}
              onChange={(e) => {
                paramValue.current = e.target.value;
                setConfirmationModal(true);
              }}
            >
              <Flex className='ads-catalogue-params-wrapper' vertical gap={16}>
                <Radio value={adsCatalogueDataMongoValue}>Par défaut : Affiche immédiatement les annonces créées manuellement ou par API.</Radio>
                <Radio value={adsCatalogueDataElasticValue}>
                  Affichage vendeur par flux : Affiche les annonces créées par import de flux. Les annonces créées manuellement apparaîtront avec une latence.
                </Radio>
              </Flex>
            </Radio.Group>
            <Modal centered open={confirmationModal} closeIcon={null} footer={null} maskClosable={false}>
              <Flex vertical gap={32}>
                <p className='sub2'>Souhaitez-vous appliquer ce paramètre ?</p>
                <Flex vertical gap={16}>
                  <Button
                    type='primary'
                    onClick={() => {
                      setAdsCatalogueData(paramValue.current);
                      setConfirmationModal(false);
                    }}
                  >
                    Appliquer le paramètre
                  </Button>
                  <Button onClick={() => setConfirmationModal(false)}>Annuler</Button>
                </Flex>
              </Flex>
            </Modal>
          </ExpandableContent>
          {merchantOptions.length > 0 && (
            <>
              <Divider />
              <div className='my-account-select-merchant-block'>
                <p>Changer de marchand :</p>
                <Select
                  size='large'
                  listHeight={200}
                  showSearch
                  placeholder='Choisir un marchand'
                  onChange={changeMerchant}
                  options={merchantOptions}
                  filterOption={filterOption}
                />
              </div>
            </>
          )}
          <Divider />
          <Button block onClick={deconnectUser} type='text'>
            Déconnexion
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default connect((state) => ({
  activeMerchant: state.session.active_merchant,
  merchants: state.session.user.merchants,
}))(MyAccount);
