import { getUserCookie, removeUserCookie, setUserCookie } from '~/helpers/cookies';
import { USER_LOGIN_SUCCESS, USER_LOGOUT } from '~/store/reducers/session';

export function syncLocalStorageUser() {
  return (next) => (action) => {
    // ignore thunk middlewares
    if (typeof action !== 'function') {
      if (action.type === USER_LOGIN_SUCCESS) {
        const currentUserInLocalStorage = getUserCookie();

        if (currentUserInLocalStorage) {
          const newUser = action.user;
          const parsedUser = JSON.parse(currentUserInLocalStorage);

          if (parsedUser.id !== newUser.id) {
            setUserCookie(action.user);
          }
        } else {
          setUserCookie(action.user);
        }
      }

      if (action.type === USER_LOGOUT) {
        removeUserCookie();
      }
    }
    next(action);
  };
}
