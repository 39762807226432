import Icon, { EditOutlined, FileUnknownOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Flex, Spin } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import TrashIcon from '~/assets/svg/icon-trash.svg?react';
import { getImageUrl } from '~/helpers/general';
import { IMAGE_UPLOAD_ERROR } from '~/store/reducers/products';

function PhotoItem({ classNameBlock, image, handleRemove, activateEdition }) {
  const dispatch = useDispatch();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: image.order });

  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);

  if (fetchError) {
    return (
      <Flex className={classNameBlock} justify='center' align='center' vertical gap={8}>
        <FileUnknownOutlined style={{ fontSize: '50px' }} />
        <p className='body1 text-center'>L'image n'a pas pu être téléchargée</p>
      </Flex>
    );
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    zIndex: 10,
  };

  const listenersProps = !loading ? listeners : {};

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <Flex vertical gap={4}>
        <div className={classNameBlock} {...listenersProps}>
          {loading && <Spin size='large' />}
          {!image.uploading && (
            <img
              className={`${loading ? 'hidden' : ''}`}
              src={getImageUrl(image.url, 200, 'cropcenter')}
              onLoad={() => {
                setLoading(false);
              }}
              onError={() => {
                setFetchError(true);
                setTimeout(() => {
                  const idImage = image.random_id ? image.random_id : image.id;
                  dispatch({
                    type: IMAGE_UPLOAD_ERROR,
                    random_id: idImage,
                  });
                }, 2000);
              }}
            />
          )}
        </div>
        {!loading && (
          <Flex gap='small' justify='center'>
            <Button className='btn-icon' type='link' icon={<EditOutlined />} onClick={activateEdition} />
            <Button className='btn-icon' type='link' icon={<Icon component={TrashIcon} />} onClick={() => handleRemove(image)} />
          </Flex>
        )}
      </Flex>
    </div>
  );
}

export default PhotoItem;
