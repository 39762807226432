import { Flex } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DownloadFileButton from '~/components/shared/DownloadFileButton/DownloadFileButton';
import { INITIAL_SEARCH, getCleanedSearchParamsFromURL } from '~/helpers/catalogue';
import { RoutingUrl, appendParamsToEndpoint } from '~/helpers/general';
import { useAdsExport } from '~/hooks/useExports';
import { CLEAN_FORM_STATE } from '~/store/reducers/products';
import { loadFormSchemaThunk, loadProducts } from '~/store/reducers/products/actions';

import './AdsCatalogue.scss';
import AdvancedSearch from './AdvancedSearch/AdvancedSearch';
import BulkEdition from './BulkEdition/BulkEdition';
import { MainSearchInput } from './MainSearchInput/MainSearchInput';
import ModalMobile from './ModalMobile/ModalMobile';
import QuickFilterSelector from './QuickFilterSelector/QuickFilterSelector';
import { ResultsTable } from './ResultsTable/ResultsTable';
import { SearchFiltersDeleter } from './SearchFiltersDeleter/SearchFiltersDeleter';
import TablePagination from './TablePagination/TablePagination';

let event = new Date();
event.setMonth(event.getMonth() - 6);
event = event.toISOString('fr-FR');
event = event.slice(0, 10);

class AdsCatalogue extends Component {
  state = {
    currentPage: 1,
    currentLimit: 100,
    selectedProductIds: [],
    searchParams: INITIAL_SEARCH(),
  };

  componentDidMount() {
    // As loadFormSchemaThunk will call /product/create/, we specify that we don't want to mess with the sku number to keep a logical autoincrement
    this.props.dispatch(loadFormSchemaThunk(null, false));

    let { location, last_query_params, history } = this.props;
    // l'utilisateur a cliqué sur le bouton précédent et est revenu sur la liste d'annonces
    // ou bien il a accedé directement à l'url
    if (history.action === 'POP') {
      let last_query_params_empty = Object.keys(last_query_params).length === 0;
      if (last_query_params_empty) {
        if (location.search !== '') {
          return this.retrieveGetParamsAndSearch();
        }
        return this.loadProducts();
      }
      return this.loadProducts(last_query_params);
    }
    // on refait ça ainsi afin de prendre en compte les paramètres d'url si jamais l'utilisateurs
    // navigue directement sur la liste des annonces en mettant des paramètres à la main
    if (location.search !== '') {
      return this.loadProducts(location.search);
    }
    return this.loadProducts();
  }

  componentDidUpdate(prevProps) {
    let { location, dataType } = this.props;
    if (prevProps.location.search !== location.search) {
      this.retrieveGetParamsAndSearch();
    } else if (prevProps.merchant_id !== this.props.merchant_id || prevProps.dataType !== dataType) {
      this.loadProducts(location.search);
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: CLEAN_FORM_STATE });
  }

  updateSelectedProductIds = (selectedProductIds) => {
    this.setState({ selectedProductIds });
  };

  retrieveGetParamsAndSearch() {
    let { searchParams } = this.state;
    let currentResearchParams = getCleanedSearchParamsFromURL(searchParams);
    this.setState({
      searchParams: currentResearchParams,
      currentPage: parseInt(currentResearchParams.page),
      currentLimit: parseInt(currentResearchParams.limit),
    });
    return this.loadProducts(currentResearchParams);
  }

  loadProducts = (params) => {
    this.props.dispatch(loadProducts(params));
  };

  // eslint-disable-next-line no-unused-vars
  onPageChange = (page, pageSize) => {
    let { searchParams } = this.state;

    let currentResearchParams = getCleanedSearchParamsFromURL(searchParams);
    currentResearchParams.page = page;
    this.setState({ currentPage: page });

    this.props.history.push(appendParamsToEndpoint(RoutingUrl.catalogue(), currentResearchParams));
  };

  onLimitChange = (limit) => {
    let { searchParams } = this.state;
    let currentResearchParams = getCleanedSearchParamsFromURL(searchParams);
    currentResearchParams.limit = limit;
    currentResearchParams.page = 1;
    this.setState({ currentPage: 1, currentLimit: limit });

    this.props.history.push(appendParamsToEndpoint(RoutingUrl.catalogue(), currentResearchParams));
  };

  render() {
    let { searchParams, selectedProductIds } = this.state;
    let { is_loading, product_list, merchant_id, history, products_count } = this.props;
    const rowSelection = {
      selectedRowKeys: selectedProductIds,
      onChange: this.updateSelectedProductIds,
      hideDefaultSelections: true,
    };

    if (Object.keys(this.props.form_schema).length === 0) return null;

    return (
      <div className='catalogue'>
        <h4 className='h4'>Animer les annonces</h4>
        <Flex className='filter-wrapper panel bordered' gap={16}>
          <div className='search-bar-block'>
            <p className='body1'>Filtrer les annonces</p>
            <MainSearchInput history={history} searchParams={searchParams} />
          </div>
          <div className='main-filter-block'>
            <p className='body1'>Afficher</p>
            <div className='filter-button-wrapper'>
              <QuickFilterSelector searchParams={searchParams} history={history} />
              <Flex gap={8}>
                <AdvancedSearch {...this.props} searchParams={searchParams} />
                <DownloadFileButton
                  useQuery={() => useAdsExport(this.props.merchant_id)}
                  textConfig={{
                    errorTitle: "Erreur de l'export des produits",
                    tooltip:
                      "Exporter votre catalogue sur un fichier Excel pour faire un inventaire de votre stock et éviter les annulations de commandes à cause d'articles perdus, cassés, volés...",
                  }}
                />
              </Flex>
              <SearchFiltersDeleter history={history} searchParams={searchParams} />
            </div>
          </div>
        </Flex>
        <BulkEdition
          searchParams={searchParams}
          selectedProductIds={selectedProductIds}
          updateSelectedProductIds={this.updateSelectedProductIds}
          currentPage={this.state.currentPage}
          currentLimit={this.state.currentLimit}
          productsList={product_list}
          totalItems={products_count}
        />
        <ResultsTable
          currentPage={this.state.currentPage}
          rowSelection={rowSelection}
          loading={is_loading}
          productList={product_list}
          searchParams={searchParams}
          merchantId={merchant_id}
          history={history}
        />
        <TablePagination
          className='pagination-bottom'
          totalItems={products_count}
          onPageChange={this.onPageChange}
          currentLimit={this.state.currentLimit}
          onLimitChange={this.onLimitChange}
          currentPage={this.state.currentPage}
          showTotal={(total, range) => `${range[0]} à ${range[1]} sur ${total}`}
        />
        <ModalMobile />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    products: { is_loading_product_list, product_list, products_count, last_query_params, form_schema, form_fields, params_drawer },
    session: { active_merchant },
  } = state;

  let user_products_list = [];
  let current_page = 1;
  let associated_products_for_active_merchant = product_list[active_merchant.id];

  if (associated_products_for_active_merchant) {
    current_page = associated_products_for_active_merchant.currentPage || 1;
    user_products_list = product_list[active_merchant.id];
  }

  return {
    last_query_params,
    products_count,
    currentPage: current_page,
    product_list: user_products_list || [],
    is_loading: is_loading_product_list,
    merchant_id: active_merchant.id,
    form_fields: form_fields,
    form_schema: form_schema,
    user_email: state.session.user.email,
    active_merchant: state.session.active_merchant,
    params_drawer: params_drawer,
  };
}

export default connect(mapStateToProps)(AdsCatalogue);
