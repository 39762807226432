import { Row } from 'antd';
import React from 'react';

import './LayoutRow.scss';

const gutter = {
  sm: 16,
  md: 24,
  lg: 24,
  xl: 24,
  xxl: 24,
};

export default function LayoutRow(props) {
  return <Row {...props} gutter={gutter} className={`layout-row${props.className ? props.className : ''}`} />;
}
