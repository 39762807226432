import React from 'react';
import CustomPagination from '~/components/shared/CustomPagination/CustomPagination';
import CustomSelect from '~/components/shared/CustomSelect/CustomSelect';

import './TablePagination.scss';

export default function TablePagination(props) {
  let { totalItems, className = '', currentPage, onPageChange, currentLimit, onLimitChange, ...remainingProps } = props;

  return (
    <div className='pagination-wrapper'>
      <CustomSelect
        className='ads-select-limit'
        options={[
          { value: 10, label: '10 / page' },
          { value: 20, label: '20 / page' },
          { value: 50, label: '50 / page' },
          { value: 100, label: '100 / page' },
        ]}
        value={currentLimit}
        onChange={(value) => onLimitChange(value)}
      />
      <CustomPagination
        {...remainingProps}
        current={currentPage}
        defaultCurrent={1}
        pageSize={currentLimit}
        className={'pagination-block ' + className}
        total={totalItems}
        onChange={onPageChange}
        showTotal={false}
        showSizeChanger={false}
        showLessItems
      />
    </div>
  );
}
