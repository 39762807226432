import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

import '../common/Authentication.scss';
import './ResetPassword.scss';
import ResetPasswordForm from './ResetPasswordForm';

export default function ResetPassword({ match, history }) {
  const token1 = match.params.token1;
  const token2 = match.params.token2;

  return (
    <>
      <div className='authentication-block'>
        <div className='title-block'>
          <h3 className='h3'>Réinitialisation de mot de passe</h3>
          <p className='body1'>Veuillez renseigner deux fois le nouveau mot de passe que vous souhaitez utiliser pour vous connecter à LaBO.</p>
          <p className='body1'>
            Le mot de passe doit contenir au moins un chiffre, une majuscule, une minuscule et un caractère spécial.
            <Tooltip className='tooltip-password-desc' title='.-/!$@'>
              <QuestionCircleOutlined />
            </Tooltip>
          </p>
          <p className='body1'>Le mot de passe doit contenir au moins 8 caractères.</p>
        </div>
        <ResetPasswordForm token1={token1} token2={token2} history={history} />
      </div>
    </>
  );
}
