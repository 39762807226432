function categoryToChoiceMapper(category) {
  return {
    id: category.id,
    name: category.value,
    leaf: category.choices.length === 0,
  };
}

export function getChoicesForSelection(categories, selection) {
  let choices = categories.map(categoryToChoiceMapper);

  let categoriesTraveler = categories;
  for (let i = 0; i < selection.length; i++) {
    const selectedCategory = selection[i];
    const selectedCategoryData = categoriesTraveler.find((category) => category.id === selectedCategory.id);
    if (selectedCategoryData) {
      choices = selectedCategoryData.choices.map(categoryToChoiceMapper);
      categoriesTraveler = selectedCategoryData.choices;
    }
  }
  return choices;
}
