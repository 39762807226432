import { Button, Flex, QRCode } from 'antd';
import React, { forwardRef, useRef } from 'react';
import { connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { AD_DETAILS_API_IDS } from '~/helpers/adDetails';

import './PrintSticker.scss';

const StickerPage = forwardRef((props, ref) => {
  const { image, title, sku } = props;
  return (
    <div ref={ref} className='sticker-page'>
      <Flex gap={16} className='sticker-content-wrapper' justify='space-between'>
        <Flex className='sticker-image' align='center' justify='center'>
          <img src={image} alt={title} />
        </Flex>
        <div className='sticker-details'>
          <p className='sticker-title sub1'>{title}</p>
          <p className='sticker-sku sub2'>SKU: {sku}</p>
        </div>
        <QRCode className='sticker-qr-code' value={window.location.origin + window.location.pathname} />
      </Flex>
    </div>
  );
});
StickerPage.displayName = 'StickerPage';

function PrintSticker({ editionMode, isPrintable, image, title, sku }) {
  if (!isPrintable && editionMode) {
    return null;
  }
  const stickerRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => stickerRef.current,
  });
  return (
    <>
      <Button className='print-sticker-btn fit-content' onClick={() => handlePrint()}>
        Imprimer mon étiquette
      </Button>
      <div className='hidden'>
        <StickerPage ref={stickerRef} image={image} title={title} sku={sku} />
      </div>
    </>
  );
}

export default connect((state) => {
  const { form_fields } = state.products;
  const componentProps = {
    isPrintable: false,
    image: null,
    title: null,
    sku: null,
  };

  const images = form_fields[AD_DETAILS_API_IDS.IMAGES.IMAGES];
  const title = form_fields[AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.TITLE];
  const sku = form_fields[AD_DETAILS_API_IDS.TECHNICAL_INFO.SKU];

  if (images && images.length > 0 && title.length > 0 && sku.length > 0) {
    componentProps.isPrintable = true;
    componentProps.image = images[0].url;
    componentProps.title = title;
    componentProps.sku = sku;
  }

  return componentProps;
})(PrintSticker);
