import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Pagination } from 'antd';
import React from 'react';

import './CustomPagination.scss';

const mapTypeToComponent = {
  prev: {
    type: 'icon',
    component: <LeftOutlined />,
  },
  next: {
    type: 'icon',
    component: <RightOutlined />,
  },
  'jump-prev': {
    type: 'text',
    component: '...',
  },
  'jump-next': {
    type: 'text',
    component: '...',
  },
};

function CustomPagination(props) {
  const renderPaginationItems = (page, type) => {
    if (type !== 'page') {
      return (
        <Button type='text' size='large' className='btn-icon' icon={mapTypeToComponent[type].type === 'icon' ? mapTypeToComponent[type].component : null}>
          {mapTypeToComponent[type].type === 'text' ? mapTypeToComponent[type].component : null}
        </Button>
      );
    }
    return (
      <Button type={props.current === page ? 'default' : 'text'} className='btn-icon'>
        {page}
      </Button>
    );
  };
  return <Pagination {...props} className={`${props.className ? `${props.className} ` : ''}custom-pagination`} itemRender={renderPaginationItems} />;
}

export default CustomPagination;
