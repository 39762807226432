import { configureStore } from '@reduxjs/toolkit';
import { IS_IN_PRODUCTION } from '~/helpers/general';

import middlewares from './middlewares';
import reducers from './reducers';

const store = configureStore({
  reducer: reducers,
  middleware: (defaultMiddlewares) => defaultMiddlewares().concat(middlewares),
  devTools: !IS_IN_PRODUCTION,
});

export default store;
