import { Flex, Radio } from 'antd';
import React from 'react';
import CustomSelect from '~/components/shared/CustomSelect/CustomSelect';
import { AD_STATUS } from '~/helpers/adDetails';
import { RoutingUrl, appendParamsToEndpoint, pushIfNotIncluded } from '~/helpers/general';

import './QuickFilterSelector.scss';

const enableNewSearch = (searchParams, history) => {
  if (searchParams) {
    history.push(appendParamsToEndpoint(RoutingUrl.catalogue(), searchParams));
  }
};

const getQuickFilters = () => {
  return [
    {
      key: 'in_sale',
      title: 'En vente',
      caption: 'Annonces actives et en stock',
      getState: (searchParams) => searchParams.status.includes(AD_STATUS.ACTIVE.API_ID) && searchParams.stock && searchParams.stock !== '0',
      apply: (searchParams) => {
        const newSearchParams = searchParams;
        pushIfNotIncluded(newSearchParams.status, AD_STATUS.ACTIVE.API_ID);
        newSearchParams.stock = 1;
        return newSearchParams;
      },
      remove: (searchParams) => {
        const newSearchParams = searchParams;
        newSearchParams.status = newSearchParams.status.filter((item) => item !== AD_STATUS.ACTIVE.API_ID);
        newSearchParams.stock = '';
        return newSearchParams;
      },
    },
    {
      key: 'in_draft',
      title: 'Brouillons',
      getState: (searchParams) => searchParams.status.includes(AD_STATUS.DRAFT.API_ID),
      apply: (searchParams) => {
        const newSearchParams = searchParams;
        pushIfNotIncluded(newSearchParams.status, AD_STATUS.DRAFT.API_ID);
        return newSearchParams;
      },
      remove: (searchParams) => {
        const newSearchParams = searchParams;
        newSearchParams.status = newSearchParams.status.filter((item) => item !== AD_STATUS.DRAFT.API_ID);
        return newSearchParams;
      },
    },
    {
      key: 'sleeping',
      title: 'Dormantes',
      caption: 'En vente et actives depuis plus de 6 mois',
      getState: (searchParams) => searchParams.status.includes(AD_STATUS.SLEEPING.API_ID) && searchParams.stock && searchParams.stock !== '0',
      apply: (searchParams) => {
        const newSearchParams = searchParams;
        pushIfNotIncluded(newSearchParams.status, AD_STATUS.SLEEPING.API_ID);
        newSearchParams.stock = 1;
        return newSearchParams;
      },
      remove: (searchParams) => {
        const newSearchParams = searchParams;
        newSearchParams.status = newSearchParams.status.filter((item) => item !== AD_STATUS.SLEEPING.API_ID);
        newSearchParams.stock = '';
        return newSearchParams;
      },
    },
  ];
};

function QuickFilterSelector({ searchParams, history }) {
  let quickFilters = getQuickFilters();

  quickFilters = quickFilters.map((filter) => ({ ...filter, state: filter.getState(searchParams) }));

  const allChecked = quickFilters.filter((filter) => filter.state).length !== 1;

  if (allChecked) {
    quickFilters = quickFilters.map((filter) => ({ ...filter, state: false }));
  }

  quickFilters.unshift({
    key: 'all',
    title: 'Toutes',
    state: allChecked,
    getState: () => allChecked,
    apply: (params) => {
      let newParams = params;
      quickFilters.forEach((filter) => {
        newParams = filter.remove(newParams);
      });
      return newParams;
    },
    remove: (searchParams) => searchParams,
  });

  const items = quickFilters.map((filter) => {
    return {
      key: filter.key,
      value: (
        <Flex
          vertical
          gap='small'
          onClick={() => {
            let newParams = searchParams;

            // Remove other filters that are active
            quickFilters.forEach((item) => {
              if (filter.key !== item.key && item.getState(newParams)) {
                newParams = item.remove(newParams);
              }
            });
            newParams = filter.state ? filter.remove(newParams) : filter.apply(newParams);
            if (newParams) {
              enableNewSearch(newParams, history);
            }
          }}
        >
          <Radio checked={filter.state}>{filter.title}</Radio>
          {filter.caption && <p className='caption1'>{filter.caption}</p>}
        </Flex>
      ),
    };
  });

  const checkedFilters = quickFilters.filter((item) => item.state);
  const currentFilter = checkedFilters.length > 0 ? checkedFilters.map((item) => item.title).join(', ') : 'Filtres';

  return (
    <>
      <CustomSelect
        onSelect={(val) => val.props.onClick()}
        className='quick-filter-select'
        options={items}
        placeholder={currentFilter}
        listHeight={300}
        popupMatchSelectWidth={230}
      />
    </>
  );
}

export default QuickFilterSelector;
