import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { INITIAL_SEARCH, computeParametersNumber } from '~/helpers/catalogue';
import { RoutingUrl, appendParamsToEndpoint } from '~/helpers/general';

export function SearchFiltersDeleter({ searchParams, history, onEraseFilters = null }) {
  const [isAnyFilter, setIsAnyFilter] = useState(false);

  useEffect(() => {
    if (computeParametersNumber(searchParams) !== 0) {
      setIsAnyFilter(true);
    } else {
      setIsAnyFilter(false);
    }
  }, [searchParams]);

  const deleteFilters = () => {
    history.push(appendParamsToEndpoint(RoutingUrl.catalogue(), INITIAL_SEARCH()));
    if (onEraseFilters) {
      onEraseFilters();
    }
  };

  return (
    <>
      {isAnyFilter && (
        <Button type='text' onClick={deleteFilters}>
          Effacer les filtres
        </Button>
      )}
    </>
  );
}
