import React from 'react';
import SearchInput from '~/components/shared/SearchInput/SearchInput';
import { RoutingUrl, appendParamsToEndpoint } from '~/helpers/general';

import './MainSearchInput.scss';

export function MainSearchInput({ searchParams, history }) {
  const updateUrl = (query) => {
    const finalSearchParams = { ...searchParams };
    finalSearchParams.q = query + 'sku:' + (searchParams.sku ? searchParams.sku : '');
    finalSearchParams.sku = '';
    finalSearchParams.categoryIds = finalSearchParams.categoryIds.map((cat) => cat.id).join(',');
    history.push(appendParamsToEndpoint(RoutingUrl.catalogue(), finalSearchParams));
  };

  return <SearchInput placeholder='Rechercher une annonce par titre' defaultValue={searchParams.q} search={updateUrl} />;
}
