const userKey = 'user';

export function getUserCookie() {
  return localStorage.getItem(userKey);
}

export function setUserCookie(user) {
  localStorage.setItem(userKey, JSON.stringify(user));
}

export function removeUserCookie() {
  localStorage.removeItem(userKey);
}

const collapsedKey = 'collapsed';

export function setCollapseCookie(value) {
  localStorage.setItem(collapsedKey, JSON.stringify(value));
}

export function getCollapseCookie() {
  if (localStorage.getItem(collapsedKey) === null) {
    setCollapseCookie('true');
  }
  return JSON.parse(localStorage.getItem(collapsedKey));
}

const onBoardingTokenKey = 'phaseOnBoarded';

export function getOnBoardingPhase() {
  return localStorage.getItem(onBoardingTokenKey);
}

export function setOnBoardingPhase(phase) {
  localStorage.setItem(onBoardingTokenKey, phase);
}

const adsCatalogueDataKey = 'adsCatalogueData';

export const adsCatalogueDataMongoValue = 'labo_products_mongo';
export const adsCatalogueDataElasticValue = 'labo_products_elastic';

export function getAdsCatalogueData() {
  if (localStorage.getItem(adsCatalogueDataKey) === null) {
    setAdsCatalogueData(adsCatalogueDataMongoValue);
  }
  return JSON.parse(localStorage.getItem(adsCatalogueDataKey));
}

export function setAdsCatalogueData(data) {
  localStorage.setItem(adsCatalogueDataKey, JSON.stringify(data));
}

const favoriteMerchantKey = 'favoriteMerchantId';

export function getFavoriteMerchantId() {
  return localStorage.getItem(favoriteMerchantKey);
}

export function setFavoriteMerchantId(id) {
  localStorage.setItem(favoriteMerchantKey, id);
}
