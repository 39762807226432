import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR';
import 'normalize.css';
import React, { StrictMode, useEffect } from 'react';
// eslint-disable-next-line
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from '~/components/App/App';
import { antTheme } from '~/styles/antdTheme';

import { IS_IN_PRODUCTION, LABO_URLS } from './helpers/general';
import store from './store/setupStore';

if (IS_IN_PRODUCTION) {
  Sentry.init({
    dsn: 'https://47fcede82b0652e95370a9e2000376fe@o4506337689075712.ingest.sentry.io/4506337691041793',
    environment: IS_IN_PRODUCTION ? 'production' : 'development',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [...LABO_URLS, 'localhost:3000'],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const ClarityScript = () => {
  useEffect(() => {
    if (!IS_IN_PRODUCTION) return () => {};
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 956186, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', 'ev2v09smbe');
    return () => {};
  }, []);
  return null;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// I Swapped back to React17 render, i have tearing possibilities with redux store
// Can't find solution, happens when in AdDetails edition mode on an ad, go to creation.
// The store is updated (clean schema / form), ad details updates with props not cleaned : form_fields (empty in store) full in props

const container = document.getElementById('root');
render(
  <>
    <StrictMode>
      <ClarityScript />
      <Provider store={store}>
        <ConfigProvider locale={frFR} theme={antTheme}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter
              getUserConfirmation={() => {
                /* Empty callback to block the default browser prompt and put our custom
        ask confirmation of leaving with our Modal LeavingConfirmationModal.js */
              }}
            >
              <App />
            </BrowserRouter>
          </QueryClientProvider>
        </ConfigProvider>
      </Provider>
    </StrictMode>
  </>,
  container
);
