import { CloseOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import React, { useState } from 'react';

import './Dialogue.scss';

function Dialogue({ type = 'primary', light = false, message = null, closable = false, vertical = false, buttons = null, children = null }) {
  const [visible, setVisible] = useState(true);
  return (
    <Flex className={`dialogue dialogue-${type}${light ? ' light' : ''}${visible ? '' : ' hidden'}`} vertical={vertical} gap={!buttons ? 10 : 16}>
      {!children ? (
        <>
          <p className='body1'>{message}</p>
          {closable && <Button className='btn-icon' size='large' icon={<CloseOutlined />} onClick={() => setVisible(false)} type='text' />}
          {buttons && buttons}
        </>
      ) : (
        children
      )}
    </Flex>
  );
}

export default Dialogue;
