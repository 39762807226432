import Icon from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Flex, Input, Spin, Tag } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import IconStars from '~/assets/svg/icon-stars.svg?react';
import Asterisk from '~/components/shared/Asterisk/Asterisk';
import Dialogue from '~/components/shared/Dialogue/Dialogue';
import { AD_DETAILS_API_IDS, OPENAI_MAX_CALLS_PER_PRODUCT } from '~/helpers/adDetails';
import { api } from '~/services/api';
import { HANDLE_CHANGE } from '~/store/reducers/products';

import { getParametersForOpenai } from './openAiHelpers';

function Description({ dispatch, component, initialOpenAICount, productId, descriptionValue, formFieldDataForOpenAI }) {
  const [openAICount, setOpenAICount] = useState(initialOpenAICount);
  const [value, setValue] = useState(descriptionValue);

  const { key, helptext, validation_rules } = component;
  const { required, max_length } = validation_rules;

  const propagateChange = (value) => {
    dispatch({
      type: HANDLE_CHANGE,
      payload: {
        input_key: key,
        value: value,
      },
    });
  };

  const { isFetching, refetch, error } = useQuery({
    queryKey: ['openai'],
    queryFn: () =>
      api
        .get(`/product/get-ai-description/?${getParametersForOpenai(formFieldDataForOpenAI.attributes, formFieldDataForOpenAI.title, productId)}`)
        .then((res) => {
          if (res.success) {
            return res.data;
          }
          throw new Error(res.message);
        }),
    enabled: false,
    retry: false,
  });

  const updateDescriptionWithAi = async () => {
    if (openAICount >= OPENAI_MAX_CALLS_PER_PRODUCT) {
      return;
    }
    await refetch().then((query) => {
      if (query.isSuccess && query.data) {
        setValue(query.data);
        setOpenAICount(openAICount + 1);
        propagateChange(query.data);
      }
    });
  };

  return (
    <>
      <div className='form-item-header'>
        <Flex gap={8} align='center'>
          <p className='sub2'>Description</p>
          {required && <Asterisk />}
          <Tag color='processing' className='body1'>
            Beta
          </Tag>
        </Flex>
        {helptext && <p className='subtext'>{helptext}</p>}
      </div>
      <div className='form-item-content'>
        {isFetching && (
          <Flex gap={8} align='center'>
            <p className='text-info'>Génération de la description en cours...</p>
            <Spin className='spin-info' />
          </Flex>
        )}
        <Input.TextArea
          id={key}
          value={value}
          maxLength={max_length}
          onChange={(e) => setValue(e.target.value)}
          onBlur={(event) => propagateChange(event.target.value)}
          disabled={isFetching}
          autoSize
        />
        <p className='subtext'>Limite de caractères : {max_length}</p>
      </div>
      <Dialogue
        type='info'
        light
        message={`Propose une description de l'annonce à partir des attributs renseignés ci-dessus, à l'aide de
              l'Intelligence Artificielle (IA) de chatGPT. L'utilisation de cette IA est limitée à ${OPENAI_MAX_CALLS_PER_PRODUCT}
              génération de description par annonce.`}
        vertical
        buttons={
          <Button
            type='text'
            className='btn-info'
            onClick={updateDescriptionWithAi}
            disabled={openAICount >= OPENAI_MAX_CALLS_PER_PRODUCT}
            title={openAICount >= OPENAI_MAX_CALLS_PER_PRODUCT ? 'Le nombre maximum de générations de description par IA a été dépassé' : null}
          >
            Générer une description
            <Icon component={IconStars} className='icon' />
          </Button>
        }
      />
      {error && <p className='text-error-dark'>{error?.message}</p>}
    </>
  );
}

export default connect((state) => {
  const { form_fields } = state.products;
  const descriptionValue = form_fields[AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.DESCRIPTION];
  return {
    descriptionValue,
    formFieldDataForOpenAI: {
      title: form_fields[AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.TITLE],
      attributes: form_fields[AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY],
    },
  };
})(Description);
