/* eslint-disable */
import { Flex } from 'antd';
import React from 'react';
import ExpandableContent from '~/components/shared/ExpandableContent/ExpandableContent';

const paymentTypeTranslationMap = {
  prepayment: 'Pré-paiement',
};

export default function OrderMoreInfo({ idOrder, paymentType }) {
  return (
    <>
      <ExpandableContent title='Informations supplémentaires' withArrow>
        <Flex vertical gap={8}>
          <Flex gap={8}>
            <p className='body2'>ID de la commande</p>
            <p className='body1'>{idOrder}</p>
          </Flex>
          <Flex gap={8}>
            <p className='body2'>Type de paiement</p>
            <p className='body1'>{paymentTypeTranslationMap[paymentType] ? paymentTypeTranslationMap[paymentType] : paymentType}</p>
          </Flex>
        </Flex>
      </ExpandableContent>
    </>
  );
}
