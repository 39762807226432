import { Button, Checkbox, Form, Input } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RoutingUrl } from '~/helpers/general';
import { logUser } from '~/store/reducers/session/actions';

import AuthenticationAlert from '../common/AuthenticationAlert/AuthenticationAlert';
import EmailFormItem from '../common/EmailFormItem';
import SubmitButton from '../common/SubmitButton';
import './LoginForm.scss';

function ForgottenPasswordLink({ className = '' }) {
  return (
    <>
      <Link to={RoutingUrl.passwordResetRequest()} className={`password-reset-link ${className}`}>
        <Button type='link'>Mot de passe oublié</Button>
      </Link>
    </>
  );
}

function LoginForm({ dispatch }) {
  const [form] = Form.useForm();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  const onFinish = (values) => {
    setIsFetching(true);
    return dispatch(
      logUser(values, (error) => {
        if (error) {
          setIsFetching(false);
          const errorMessage =
            typeof error === 'string' ? error : "Une erreur interne est survenue. Merci de contacter l'équipe accompagnement vendeurs pour plus de détails.";
          setError({ type: 'error', message: errorMessage });
        }
      })
    );
  };

  return (
    <>
      <AuthenticationAlert
        alert={error}
        afterClose={() => {
          setError(null);
        }}
      />
      <Form form={form} name='login' onFinish={onFinish} className='form-login'>
        <div className='login-input-wrapper'>
          <EmailFormItem />

          <Form.Item name='password' rules={[{ required: true, message: 'Mot de passe requis' }]}>
            <Input.Password placeholder='Mot de passe' />
          </Form.Item>

          <Form.Item>
            <Form.Item name='rememberMe' valuePropName='checked' initialValue={true} noStyle>
              <Checkbox>Se souvenir de moi</Checkbox>
            </Form.Item>

            <ForgottenPasswordLink className='hidden-xs' />
          </Form.Item>
        </div>
        <div className='authentication-submit-block'>
          <Form.Item noStyle>
            <SubmitButton loading={isFetching}>Se connecter</SubmitButton>
            <ForgottenPasswordLink className='hidden-min-sm' />
          </Form.Item>
        </div>
      </Form>
    </>
  );
}

export default connect()(LoginForm);
