import { AD_DETAILS_API_IDS } from '~/helpers/adDetails/general';

// NOTE: how to use
// how to get a validator function that checks if a string has a minimum length of 4
// let minimumLength = 4;
// let hasMinimumLengthOfFour = hashmapOfValidatorFunctionsToBeCurried['min_length'](minimumLength);
// hasMinimumLengthOfFour('un') -> return false
// hasMinimumLengthOfFour('quatre') -> return true

// NOTE2: the keys has to be in sync with what the PimenTech api's delivers
// in the validation_rules key
const hashmapOfValidatorFunctionsToBeCurried = Object.freeze({
  min_length:
    (limit) =>
    (string = '') =>
      string.length >= limit,
  max_length:
    (limit) =>
    (string = '') =>
      string.length <= limit,
  required: (isRequired) => (value) => {
    if (isRequired === false) return true;

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === 'number') {
      return true;
    }

    return value !== undefined && value !== null && value !== '';
  },
});

/**
 * [_getValidatorFunctionForValidationRules this function is used to return a validator function that
 * will apply all the validation rules onto the desired value and return true
 * if all the validation rules are respected]
 * @param {[Object]} validationRules [object containing all the validation
 * rules and their limits (e.g. = {min_length: 10})]
 * @return {[Function]} [A validator function that accept the value to be checked
 * as its argument it will then run all of the validator functions onto the
 * desired value and returns true if all test have passed]
 */

function getValidatorFunctionForValidationRules(validationRules) {
  if (!validationRules) return null;

  const validationRulesKeys = Object.keys(validationRules);
  const validationRulesFunctions = {};

  validationRulesKeys.forEach((rule) => {
    let ruleValue = validationRules[rule];
    if (!hashmapOfValidatorFunctionsToBeCurried[rule]) return;
    let validationFunction = hashmapOfValidatorFunctionsToBeCurried[rule](ruleValue);

    validationRulesFunctions[rule] = {
      validation: validationFunction,
      limit: ruleValue,
    };
  });

  if (Object.keys(validationRulesFunctions).length > 0) {
    return (value) => {
      const reducer = (validation, function_key) => {
        const currentFunctionIsValid = validationRulesFunctions[function_key].validation(value);
        const errors = !currentFunctionIsValid
          ? [
              ...validation.errors,
              {
                key: function_key,
                limit: validationRulesFunctions[function_key].limit,
              },
            ]
          : validation.errors;
        return {
          isValid: validation.isValid && currentFunctionIsValid,
          errors: errors,
        };
      };
      // note: we set the start value at true in order to properly evaluate the
      // first rule. if we started with false and the first value was indeed valid then
      // it will return false for the first iteration which is bad and not true.
      // also note that the use of the && operator is mandatory since we want to verify
      // that **all rules** are respected.
      return validationRulesKeys.reduce(reducer, {
        isValid: true,
        errors: [],
      });
    };
  }
  return null;
}

const mandatoryForDraftFields = [AD_DETAILS_API_IDS.CATEGORIES.CATEGORIE];
const skipValidationRules = [AD_DETAILS_API_IDS.IMAGES.UPLOAD];

const validateFields = (toPublish, formFields, validationRules, all_form_components) => {
  const errors = {
    required: [],
    min_length: [],
    max_length: [],
  };
  let isValid = true;

  const validationRulesModified = {};
  Object.keys(validationRules).forEach((key) => {
    if (!skipValidationRules.includes(key)) {
      validationRulesModified[key] = validationRules[key];
    }
  });
  validationRulesModified[AD_DETAILS_API_IDS.IMAGES.IMAGES] = { required: true };

  Object.keys(validationRulesModified).forEach((key) => {
    const value = all_form_components[key].field_type === 'attribute' ? formFields[AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY][key] : formFields[key];
    const rules = validationRulesModified[key];

    const validator = getValidatorFunctionForValidationRules(rules);

    if (validator === null) return;
    const validation = validator(value);
    if (rules.required && !toPublish && !validation.isValid && !mandatoryForDraftFields.includes(key)) {
      return;
    }

    if (!validation.isValid) {
      isValid = false;
      const label = all_form_components[key].label ? all_form_components[key].label : key.charAt(0).toUpperCase() + key.slice(1);
      validation.errors.forEach((error) => {
        const { key: error_key, limit } = error;
        errors[error_key].push({
          label: label,
          limit: limit,
        });
      });
    }
  });

  return {
    isValid: isValid,
    errors,
  };
};

export { getValidatorFunctionForValidationRules, validateFields };
