import { Form, Input } from 'antd';
import React from 'react';
import { emailPattern } from '~/helpers/general';

export default function EmailFormItem() {
  return (
    <Form.Item
      name='email'
      validateTrigger='onBlur'
      rules={[
        { required: true, message: 'E-mail requis' },
        { pattern: emailPattern, message: 'Format incorrect. Exemple : xxxxx@hotmail.com' },
      ]}
    >
      <Input placeholder='Email' />
    </Form.Item>
  );
}
