import { Input } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Asterisk from '~/components/shared/Asterisk/Asterisk';
import { AD_DETAILS_API_IDS } from '~/helpers/adDetails';
import { HANDLE_CHANGE } from '~/store/reducers/products';

function Title({ component, titleValue, validation_rules, dispatch }) {
  const [title, setTitle] = useState(titleValue);
  const { key, helptext } = component;

  const required = validation_rules[key].required;
  const max_length = validation_rules[key].max_length;

  const handleChange = (event) => {
    const value = event.target.value;
    let formattedValue = value;
    // Make sure whitespace between words is only one space
    formattedValue = formattedValue.replace(/\s+/g, ' ');
    // Check if they are no words with more then 20% capital letters (dont count first letter of the word), transform the words to lowercase
    const words = formattedValue.split(' ');
    words.forEach((word, index) => {
      const withoutFirstLetter = word.slice(1);
      const wordLength = withoutFirstLetter.length;
      const capitalLetters = withoutFirstLetter.match(/[A-Z]/g);
      if (capitalLetters && capitalLetters.length > wordLength * 0.2) {
        words[index] = word.slice(0, 1) + withoutFirstLetter.toLowerCase();
      }
    });
    formattedValue = words.join(' ');

    // Capitalize first letter of the title
    formattedValue = formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1);
    setTitle(formattedValue);
  };

  const propagateChange = (value) => {
    dispatch({
      type: HANDLE_CHANGE,
      payload: {
        input_key: key,
        value: value.trim(),
      },
    });
  };

  return (
    <>
      <div className='form-item-header'>
        <p className='sub2'>Titre de votre annonce{required && <Asterisk />}</p>
        {helptext && <p className='subtext'>{helptext}</p>}
      </div>
      <div className='form-item-content'>
        <Input
          id={key}
          value={title}
          onChange={handleChange}
          onPressEnter={(event) => propagateChange(event.target.value)}
          onBlur={(event) => propagateChange(event.target.value)}
          maxLength={max_length}
        />
        <p className='subtext'>Limite de caractères : {max_length}</p>
      </div>
    </>
  );
}

export default connect((state) => {
  const { form_fields, validation_rules } = state.products;
  const titleValue = form_fields[AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.TITLE];
  return {
    titleValue,
    validation_rules,
  };
})(Title);
