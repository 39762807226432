import Icon from '@ant-design/icons';
import { Avatar, Menu } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import QuestionMarkIcon from '~/assets/svg/icon-question-mark.svg?react';
import { HELP_EXTERNAL_LINK } from '~/helpers/general';

import MyAccount from './MyAccount';
import './SecondaryNavMenu.scss';

function PortraitImg({ index }) {
  function getImageUrl(name) {
    return new URL(`/src/assets/portraits/${name}.jpg`, import.meta.url).href;
  }

  return (
    <>
      <Avatar src={getImageUrl(`animal-portrait-${index}`)} size='default' className='secondary-menu-item-icon' />
    </>
  );
}

function getRandomArbitrary(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

function SecondaryNavMenu() {
  const secondaryMenuItems = [
    {
      key: '1',
      title: "J'ai besoin d'aide",
      icon: <Icon className='secondary-menu-item-icon' component={QuestionMarkIcon} />,
      label: (
        <a target='_blank' rel='noopener noreferrer' href={HELP_EXTERNAL_LINK}>
          <span className='body1'>J'ai besoin d'aide</span>
        </a>
      ),
    },
    {
      key: '2',
      title: 'Mon compte',
      icon: <PortraitImg index={getRandomArbitrary(1, 10)} />,
      label: <MyAccount />,
    },
  ];

  return (
    <>
      <Menu items={secondaryMenuItems} mode='inline' className='secondary-nav-menu-custom' style={{ border: 0 }} />
    </>
  );
}

export default connect((state) => ({
  activeMerchant: state.session.active_merchant,
}))(SecondaryNavMenu);
