import { Flex, Spin } from 'antd';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AlertBasicServerError from '~/components/shared/AlertBasicServerError/AlertBasicServerError';
import Asterisk from '~/components/shared/Asterisk/Asterisk';
import { AD_DETAILS_API_IDS, AD_DETAILS_NUMERIC_TYPES, getHexaColorFromColorName } from '~/helpers/adDetails';
import { getBorderColorFromColorName } from '~/helpers/adDetails/general';
import { useGetCategoryAttributes } from '~/hooks/useGetCategoryAttributes';
import { HANDLE_CHANGE, SETUP_ATTRIBUTES_REQUIRED } from '~/store/reducers/products';

// import breakpoints from '~/styles/breakpoints.module.scss';
import Attribute from './Attribute/Attribute';

// const breakpointMobile = parseInt(breakpoints.xsMax.slice(0, -2));

const attributesToFilter = [
  AD_DETAILS_API_IDS.ATTRIBUTES.ETAT_PRECISION,
  AD_DETAILS_API_IDS.ATTRIBUTES.ETAT.MAIN_KEY,
  AD_DETAILS_API_IDS.ATTRIBUTES.COMMENT,
  AD_DETAILS_API_IDS.ATTRIBUTES.SHIPPING_OPTIONS,
];

function completeAttributeData(attribute) {
  const { key, label, type } = attribute;

  if (AD_DETAILS_NUMERIC_TYPES.includes(type)) {
    const enRegex = /(.+)\(en\s+(\w+)\)$/;
    const parenthesisRegex = /(.+)\((.*?)\)$/;

    if (enRegex.test(label)) {
      const [, newLabel, unit] = label.match(enRegex);
      attribute.unit = unit;
      attribute.label = newLabel;
    } else if (parenthesisRegex.test(label)) {
      const [, newLabel, unit] = label.match(parenthesisRegex);
      attribute.unit = unit;
      attribute.label = newLabel;
    }
  }

  if (key === AD_DETAILS_API_IDS.ATTRIBUTES.ANNEE) {
    attribute.cleaner = (val) => {
      const maxYear = new Date().getFullYear();

      if (val < 0 || val > maxYear) {
        return '';
      }
      return val;
    };
  }

  if (key === AD_DETAILS_API_IDS.ATTRIBUTES.COULEUR) {
    attribute.choices = attribute.choices.map((choice) => {
      if (choice.value === 'Transparent') {
        return choice;
      }
      const borderColor = getBorderColorFromColorName(choice.value);

      return {
        ...choice,
        additionalContent: (
          <div
            className={`color-choice${borderColor ? ' with-border' : ''}`}
            style={{ background: getHexaColorFromColorName(choice.value), borderColor: borderColor }}
          />
        ),
      };
    });
  }

  if (key === AD_DETAILS_API_IDS.ATTRIBUTES.CREATIONS.MAIN_KEY) {
    attribute.choices = attribute.choices.filter((choice) => choice.id !== AD_DETAILS_API_IDS.ATTRIBUTES.CREATIONS.VALUES.ARTISANAT.ID);
  }

  if (type === AD_DETAILS_API_IDS.TYPES.RADIO.MAIN_KEY) {
    const groupedAttributes = [];

    if (key === AD_DETAILS_API_IDS.ATTRIBUTES.TAILLE.MAIN_KEY) {
      const numberOnlyRegex = /^[0-9]+$/;
      const tSize = /^T.*$/;
      const lettersNotStartingByT = /^[^T][a-zA-Z]*$/;

      groupedAttributes.push(attribute.choices.filter((choice) => numberOnlyRegex.test(choice.value)).sort((a, b) => parseInt(a.value) - parseInt(b.value)));
      groupedAttributes.push(
        attribute.choices.filter((choice) => tSize.test(choice.value)).sort((a, b) => parseInt(a.value.slice(1)) - parseInt(b.value.slice(1)))
      );

      const sortLettersSizing = (a, b) => {
        const getSizeValue = (size) => {
          const match = size.match(/(X*)([A-Z]+)/);
          if (match) {
            const sizeMap = { S: 0, M: 1, L: 2 };
            const xNumber = match[1].length;
            const letter = match[2];
            if (sizeMap[letter] === 0 && xNumber) {
              return sizeMap[letter] - xNumber;
            }
            return sizeMap[letter] + xNumber;
          }
          return Infinity; // If no match, place it at the end
        };

        return getSizeValue(a.value) - getSizeValue(b.value);
      };
      groupedAttributes.push(attribute.choices.filter((choice) => lettersNotStartingByT.test(choice.value)).sort(sortLettersSizing));
    } else {
      groupedAttributes.push(attribute.choices);
    }

    attribute.groupedChoices = groupedAttributes;
  }
  return attribute;
}

function Attributes({ dispatch, component, restrictStockChange, categories, attributesValues, weightValue, stockValue }) {
  const currentCategory = categories[categories.length - 1].id;

  const { isLoading, data } = useGetCategoryAttributes(currentCategory);

  useEffect(() => {
    if (data) {
      dispatch({
        type: SETUP_ATTRIBUTES_REQUIRED,
        payload: {
          attributes: data,
        },
      });
    }
  }, [data]);

  if (isLoading) {
    return (
      <Flex justify='center' align='center'>
        <Spin size='large' />
      </Flex>
    );
  }

  if (!data) {
    return <AlertBasicServerError />;
  }

  const { additional_components } = component;

  const weight = additional_components.find((component) => component.key === AD_DETAILS_API_IDS.TECHNICAL_INFO.WEIGHT);
  const stock = additional_components.find((component) => component.key === AD_DETAILS_API_IDS.TECHNICAL_INFO.STOCK);
  const attributes = _.cloneDeep(data)
    .filter((component) => !attributesToFilter.includes(component.key))
    .map(completeAttributeData);

  const groupedAttributes = [];
  let currentGroup = [];

  const isGroupFull = (group) => {
    // const groupLimit = window.innerWidth < breakpointMobile ? 2 : 4;
    // const groupSize = group.reduce((acc, attribute) => {
    //   switch (attribute.type) {
    //     case AD_DETAILS_API_IDS.TYPES.STRING:
    //       return acc + 2;
    //     case AD_DETAILS_API_IDS.TYPES.BOOL:
    //       return acc + 1;
    //     case AD_DETAILS_API_IDS.TYPES.INT:
    //     case AD_DETAILS_API_IDS.TYPES.DECIMAL:
    //       return acc + 1;
    //   }
    //   return acc;
    // }, 0);

    // return groupSize >= groupLimit;
    return group && group.length > 0 && true;
  };

  for (let i = 0; i < attributes.length; i += 1) {
    const attribute = attributes[i];
    const { type } = attribute;

    if (isGroupFull(currentGroup)) {
      groupedAttributes.push(currentGroup);
      currentGroup = [];
    }

    if (type !== AD_DETAILS_API_IDS.TYPES.RADIO.MAIN_KEY) {
      currentGroup.push(attribute);
      if (i + 1 === attributes.length) {
        groupedAttributes.push(currentGroup);
      }
    } else {
      groupedAttributes.push([attribute]);
    }
  }

  const dispatchTechnicalInfo = (key, value) => {
    dispatch({
      type: HANDLE_CHANGE,
      payload: {
        input_key: key,
        value: value,
      },
    });
  };
  return (
    <>
      <div className='form-item-header'>
        <p className='sub2'>
          Caractéristiques
          <Asterisk />
        </p>
      </div>
      <Flex className='technical-attributes' gap={16}>
        <Attribute
          overridePropagateChange={dispatchTechnicalInfo}
          attribute={{
            key: weight.key,
            label: 'Poids',
            unit: 'kg',
            required: weight.validation_rules.required,
            type: weight.type,
            helptext: weight.helptext,
          }}
          defaultValue={weightValue}
        />
        <Attribute
          overridePropagateChange={dispatchTechnicalInfo}
          attribute={{
            key: stock.key,
            label: stock.label,
            required: stock.validation_rules.required,
            type: stock.type,
            confirmationTexts: ['Si cet article était déjà en vente sur Label Emmaüs, il peut déjà avoir été vendu.'],
          }}
          defaultValue={stockValue}
          askConfirmation={restrictStockChange}
        />
      </Flex>
      {groupedAttributes.map((group, index) => (
        <Flex key={`grouped-attributes-${index}`} gap={16}>
          {group.map((attribute) => (
            <Attribute key={attribute.key} dispatch={dispatch} attribute={attribute} defaultValue={attributesValues[attribute.key]} />
          ))}
        </Flex>
      ))}
    </>
  );
}

export default connect((state) => {
  const { form_fields } = state.products;
  const categories = form_fields[AD_DETAILS_API_IDS.CATEGORIES.CATEGORIE];
  const attributes = form_fields[AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY];
  const attributesValues = {};
  const weightValue = form_fields[AD_DETAILS_API_IDS.TECHNICAL_INFO.WEIGHT];
  const stockValue = form_fields[AD_DETAILS_API_IDS.TECHNICAL_INFO.STOCK];

  for (const key in attributes) {
    if (!attributesToFilter.includes(key)) {
      attributesValues[key] = attributes[key];
    }
  }

  return {
    categories,
    attributesValues,
    weightValue,
    stockValue,
  };
})(Attributes);
