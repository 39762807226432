import Icon from '@ant-design/icons';
import React from 'react';
import MainLogo from '~/assets/svg/logo.svg?react';

export default function Logo() {
  return (
    <>
      <Icon component={MainLogo} style={{ fontSize: '62px' }} />
    </>
  );
}
