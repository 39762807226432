import { Alert } from 'antd';
import React from 'react';

import './AuthenticationAlert.scss';

export default function AuthenticationAlert({ alert, afterClose, topPosition = '-50px' }) {
  if (!alert) {
    return <></>;
  }
  return (
    <>
      <Alert
        className='authentication-alert'
        type={alert.type}
        message={alert.message}
        showIcon
        afterClose={afterClose}
        closable
        style={{ top: topPosition }}
      />
    </>
  );
}
