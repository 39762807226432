import { RedoOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import React from 'react';

export default function ErrorMessage(props) {
  return (
    <>
      <Flex vertical align='center' gap='large'>
        <p className='text-center'>
          Désolé, une erreur est survenue
          {props.errorCode ? ' (' + props.errorCode + ')' : ''}. <br />
          Veuillez patienter quelques secondes, puis réessayer. Si le problème persiste, merci de contacter l'équipe accompagnement vendeurs.
        </p>
        <Button type='primary' className='btn-icon' icon={<RedoOutlined />} onClick={() => window.location.reload()} />
      </Flex>
    </>
  );
}
