import { useQuery } from '@tanstack/react-query';
import { Button, Divider } from 'antd';
import axios from 'axios';
import React from 'react';
import InfoCard from '~/components/shared/InfoCard/InfoCard';
import { createGoogleSheetUrl } from '~/helpers/general';

function Announcement() {
  const { data } = useQuery({
    queryKey: ['alerts'],
    queryFn: () =>
      axios.get(createGoogleSheetUrl('alerts')).then((res) => {
        const alertData = res.data.values[1];
        if (alertData[0] === '') {
          return null;
        }
        return {
          title: alertData[0],
          message: alertData[1],
          fontSize: alertData[4],
          link: alertData[2],
          linkText: alertData[3],
          linkSize: alertData[5],
        };
      }),
  });

  if (!data) {
    return <></>;
  }

  return (
    <div className='announcement-item'>
      <InfoCard
        title={data.title}
        description={data.message}
        footer={
          <>
            <Button target='_blank' rel='noopener noreferrer' href={data.link}>
              {data.linkText}
            </Button>
          </>
        }
      />
      <Divider className='home-divider' />
    </div>
  );
}

export default Announcement;
