import Icon from '@ant-design/icons';
import { Affix, Divider, Layout, Tooltip } from 'antd';
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import DoubleArrowLeft from '~/assets/svg/icon-double-arrow-left.svg?react';
import DoubleArrowRight from '~/assets/svg/icon-double-arrow-right.svg?react';
import { collapsedWithCookieAtom } from '~/atoms';
import NavMenu from '~/components/nav/NavMenu/NavMenu';
import SecondaryNavMenu from '~/components/nav/SecondaryNavMenu/SecondaryNavMenu';
import CreateAdButton from '~/components/shared/CreateAdButton/CreateAdButton';

import Logo from './Logo';
import './SideHeader.scss';

const { Sider } = Layout;

export default function SideHeader() {
  const [panelContainer, setPanelContainer] = useState(null);
  const [collapsed, setCollapsed] = useAtom(collapsedWithCookieAtom);

  return (
    <>
      <div className='panel full-size-panel overflow-hidden panel-sider-fixed-height'>
        <div className='inner-panel panel-sider-fixed-height' ref={setPanelContainer}>
          <Sider
            collapsible
            trigger={null}
            collapsed={collapsed}
            collapsedWidth={81}
            theme='light'
            width={290}
            style={{ borderRadius: 'inherit', height: '100%' }}
            className='ant-sider-custom sider-hide-mobile'
          >
            <div className={`side-header-wrapper${collapsed ? ' sider-collapsed' : ''}`}>
              <div className='side-header-top-block'>
                <Affix target={() => panelContainer}>
                  <div className='logo-block'>
                    {!collapsed && <Logo />}
                    <Tooltip title={collapsed ? 'Déplier' : 'Replier'} placement='right' trigger={['hover', 'click']}>
                      <div className='collapser-btn' onClick={() => setCollapsed(!collapsed)}>
                        {collapsed ? <Icon component={DoubleArrowRight} className='text-primary' /> : <Icon component={DoubleArrowLeft} />}
                      </div>
                    </Tooltip>
                  </div>
                </Affix>
                <div className='side-header-create-ad-wrapper'>
                  <div className='side-header-create-ad-block'>
                    <CreateAdButton iconMode={collapsed} />
                    <Divider className='side-header-divider' />
                  </div>
                </div>
                <div className='main-nav-menu-wrapper'>
                  <NavMenu />
                </div>
              </div>
              <div className='side-header-bottom-block'>
                <SecondaryNavMenu />
              </div>
            </div>
          </Sider>
        </div>
      </div>
    </>
  );
}
