import Icon, { RightOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Flex, Radio } from 'antd';
import React from 'react';
import categoryIdToIconMapper from '~/components/shared/CategoryIcon/CategoryIcon';
import { api } from '~/services/api';

export default function CategorySuggestion({ changeCategory, title, selectedCategories }) {
  const { data } = useQuery({
    queryKey: ['categoriesSuggestion', title],
    queryFn: () =>
      api.get(`/suggerer_categorie/?type=${title}`).then((res) => {
        if (res.success) {
          return res.data.results.slice(0, 3);
        }
        if (typeof res.message?.error === 'string') {
          throw new Error(res.message.error);
        }
        throw new Error(res.message.error.message);
      }),
  });

  if (!data || data.length === 0) {
    return null;
  }

  const currentCategory = selectedCategories && selectedCategories.length > 0 ? selectedCategories[selectedCategories.length - 1].id : null;
  const currentCategoryData = data.find((categorie) => categorie.id === currentCategory) ? currentCategory : null;

  return (
    <>
      <Flex vertical gap={16}>
        <Radio.Group
          value={currentCategoryData}
          onChange={(e) => {
            const value = e.target.value;
            const categorie = data.find((categorie) => categorie.id === value);
            changeCategory(
              categorie.ordered_parents.map((id, index) => ({
                id: id,
                value: categorie.parents_names[index],
              }))
            );
          }}
        >
          <Flex vertical gap={16}>
            {data.map((categorie) => {
              const parentCategoryId = categorie.ordered_parents.length > 0 ? categorie.ordered_parents[0] : null;
              const childCategoryName = categorie.parents_names.length > 0 ? categorie.parents_names[categorie.parents_names.length - 1] : '';

              return (
                <Flex key={categorie.id} gap={8} align='center'>
                  <Radio id={categorie.id} value={categorie.id} />
                  <label htmlFor={categorie.id}>
                    <Flex gap={8} align='center'>
                      <Icon className='category-icon' component={categoryIdToIconMapper(parentCategoryId)} />
                      <RightOutlined className='small-arrow' />
                      <p className='body1'>{childCategoryName}</p>
                    </Flex>
                  </label>
                </Flex>
              );
            })}
          </Flex>
        </Radio.Group>
        <p className='body1 text-grey-medium'>Ou</p>
      </Flex>
    </>
  );
}
