/* eslint-disable */
import { useQuery } from '@tanstack/react-query';
import { Button, Flex } from 'antd';
import React from 'react';
import ExpandableContent from '~/components/shared/ExpandableContent/ExpandableContent';
import { api } from '~/services/api';

import { transitionApiKeys } from '../OrderDetails';

const isInvoiceCustom = (invoice) => invoice.file_source === 'external_uri';

export default function OrderDocuments({ order, activeMerchantId }) {
  if (!order.status_history.some((transition) => transition.transition === transitionApiKeys.CONFIRMED)) {
    return null;
  }

  const { data } = useQuery({
    queryKey: ['orderInvoices', order.id, activeMerchantId],
    queryFn: () =>
      api.post(`/${activeMerchantId}/orders/${order.id}/ask_invoice/`).then((res) => {
        if (res.success) {
          return res.data?.customer_invoices;
        }
        if (typeof res.message?.error === 'string') {
          throw new Error(res.message.error);
        }
        throw new Error(res.message.error.message);
      }),
  });

  if (!data) {
    return null;
  }
  console.log(data);

  return (
    <>
      <ExpandableContent title='Documents' withArrow>
        {data.map((invoice) => (
          <Flex key={`invoice-${invoice.id}`} vertical gap={8}>
            {!isInvoiceCustom(invoice) ? (
              <Button type='link' href={invoice.pdf_file} target='_blank' rel='noopener noreferrer'>
                Télécharger la facture auto-générée
              </Button>
            ) : (
              <Button type='link' href={invoice.invoice_url} target='_blank' rel='noopener noreferrer'>
                Télécharger la facture
              </Button>
            )}
          </Flex>
        ))}
      </ExpandableContent>
    </>
  );
}
