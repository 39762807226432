import { Flex, InputNumber, Switch, Tag, Tooltip } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Asterisk from '~/components/shared/Asterisk/Asterisk';
import { AD_DETAILS_API_IDS, getLabelAffaireIdsFromCategoriesList, getValueByIdInLabelAffaire } from '~/helpers/adDetails';
import { HELP_EXTERNAL_LINK } from '~/helpers/general';
import { HANDLE_CHANGE } from '~/store/reducers/products';

import './Price.scss';
import SimilarAds from './SimilarAds/SimilarAds';

function cleanNumberValue(val) {
  if (val === '') {
    return '';
  }
  const cleanedVal = val.toString().replace(',', '.');
  const parsedValue = parseFloat(cleanedVal);
  return isNaN(parsedValue) ? '' : parsedValue;
}

function getLabelAffaireId(categories) {
  const labelAffaireForCurrentCategories = getLabelAffaireIdsFromCategoriesList(categories, []);
  if (labelAffaireForCurrentCategories.length > 0) {
    return labelAffaireForCurrentCategories.pop();
  }
  return null;
}

const labelAffairePercentage = 0.2;

function Price({ dispatch, component, editionMode = false, defaultPrice, defaultPreviousPrice, labelAffaireValue, categories }) {
  const [price, setPrice] = useState(defaultPrice);
  const [previousPrice, setPreviousPrice] = useState(defaultPreviousPrice);

  const { key, validation_rules, label_affaire } = component;
  const { required } = validation_rules;

  const idLabelAffaireCorrespondantToCateg = getLabelAffaireId(categories);
  const labelAffaireToApply = {
    id: idLabelAffaireCorrespondantToCateg,
    value: getValueByIdInLabelAffaire(idLabelAffaireCorrespondantToCateg, label_affaire.choices),
  };
  const labelAffaireIsApplied = labelAffaireValue.some((labelAffaire) => labelAffaire.id === labelAffaireToApply.id);

  const changePrice = (val) => {
    if (val === price) {
      return;
    }

    setPrice(val);
    dispatch({
      type: HANDLE_CHANGE,
      payload: {
        input_key: AD_DETAILS_API_IDS.TECHNICAL_INFO.PRICE,
        value: val,
      },
    });
  };

  const applyLabelAffaire = (value) => {
    dispatch({
      type: HANDLE_CHANGE,
      payload: {
        input_key: label_affaire.key,
        value: value ? [labelAffaireToApply] : [],
      },
    });
    if (value) {
      setPrice((price * (1 - labelAffairePercentage)).toFixed(2));
      setPreviousPrice(price);
    } else {
      if (previousPrice) {
        setPrice(previousPrice);
        setPreviousPrice(null);
      }
    }
  };

  return (
    <>
      <div className='form-item-header'>
        <Flex gap={8} align='center'>
          <p className='sub2'>Prix</p>
          {required && <Asterisk />}
          <Tooltip
            title={
              <>
                Fixer un prix attractif pour votre futur·e client·e.
                <br />
                Vous pourrez avec une offre Label Affaire ou un prix réduit une fois votre article publié.
              </>
            }
            trigger={editionMode ? [] : 'hover'}
          >
            <Tag color='processing' className='body1'>
              Nouveau
            </Tag>
          </Tooltip>
        </Flex>
      </div>
      <InputNumber
        className='input-price'
        id={key}
        value={price}
        disabled={labelAffaireIsApplied}
        onStep={(value) => changePrice(cleanNumberValue(value))}
        onBlur={(elem) => changePrice(cleanNumberValue(elem.target.value))}
        addonAfter='€'
        decimalSeparator=','
        step={1}
        controls
      />
      {editionMode && previousPrice > 0 && <p className='body1 text-grey-medium'>Prix barré (affiché sur l’annonce) :{previousPrice}€</p>}
      {editionMode && labelAffaireToApply.id && (
        <Flex gap={8}>
          <Switch id={label_affaire.key} checked={labelAffaireIsApplied} onChange={applyLabelAffaire} />
          <Flex vertical gap={4}>
            <p className='body1'>
              Appliquer une offre{' '}
              <a href={`${HELP_EXTERNAL_LINK}animer-mon-catalogue/quest-ce-que-label-affaire`} target='_blank' rel='noopener noreferrer'>
                Bon plan (Label Affaire)
              </a>
            </p>
            <p className='caption1 text-grey-medium'>(-20% toute l'année, -50% en période de braderie)</p>
          </Flex>
        </Flex>
      )}
      <SimilarAds />
    </>
  );
}

export default connect((state) => {
  const { form_fields } = state.products;
  const defaultPrice = form_fields[AD_DETAILS_API_IDS.TECHNICAL_INFO.PRICE];
  const defaultPreviousPrice = form_fields[AD_DETAILS_API_IDS.TECHNICAL_INFO.CROSSED_PRICE];

  const labelAffaireValue = form_fields[AD_DETAILS_API_IDS.PROMOTION.LABEL_AFFAIRE];
  const categories = form_fields[AD_DETAILS_API_IDS.CATEGORIES.CATEGORIE];
  return {
    defaultPrice,
    defaultPreviousPrice,
    labelAffaireValue,
    categories,
  };
})(Price);
