import { useQuery } from '@tanstack/react-query';
import { Checkbox, Flex, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import Dialogue from '~/components/shared/Dialogue/Dialogue';
import { AD_DETAILS_API_IDS } from '~/helpers/adDetails';
import { api } from '~/services/api';
import { HANDLE_CHANGE } from '~/store/reducers/products';

import './ShippingOptions.scss';

/*
The shipping options here represent the different shipping options available for a merchant.
Only the checked options will be available for the client.
If none are unchecked we do not apply any override so all shipping options are avaible.
 */
function ShippingOptions({ dispatch, defaultShippingOptions, merchantId }) {
  const { isLoading: shippingOptionsLoading, data: shippingOptions } = useQuery({
    queryKey: ['shippingOptions', merchantId],
    queryFn: () =>
      api.get(`/${merchantId}/shipping_options/`).then((res) => {
        if (res.success) {
          return res.data;
        }
        return null;
      }),
  });

  const addShippingOptionsOverride = (options) => {
    const shippingOptionsOverride = options.length !== shippingOptions.length ? options.join(',') : null;
    dispatch({
      type: HANDLE_CHANGE,
      payload: {
        input_key: AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY,
        substate_key: AD_DETAILS_API_IDS.ATTRIBUTES.SHIPPING_OPTIONS,
        value: shippingOptionsOverride,
      },
    });
  };

  if (shippingOptionsLoading) {
    return (
      <Flex justify='center' align='center'>
        <Spin size='large' />
      </Flex>
    );
  }

  if (!shippingOptions) {
    return null;
  }

  const defaultIds = defaultShippingOptions
    ? defaultShippingOptions.split(',').map((optionStr) => parseInt(optionStr))
    : shippingOptions.map((option) => option.id);

  const options = shippingOptions.map((option) => ({ label: option.label, value: option.id }));

  return (
    <>
      <div className='form-item-header'>
        <p className='sub2'>Livraison(s)</p>
      </div>
      <Dialogue
        type='warning'
        light
        message="Décochez les options de livraison qui ne sont pas compatibles avec votre produit. 
Conseil : laissez le plus d'options possibles pour augmenter les chances de vendre votre article."
      />
      <Checkbox.Group className='shipping-options-list' defaultValue={defaultIds} options={options} onChange={(values) => addShippingOptionsOverride(values)} />
    </>
  );
}

export default connect((state) => {
  const { active_merchant } = state.session;
  const { form_fields } = state.products;

  const defaultShippingOptions = form_fields[AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY]?.[AD_DETAILS_API_IDS.ATTRIBUTES.SHIPPING_OPTIONS];

  return {
    merchantId: active_merchant.id,
    defaultShippingOptions,
  };
})(ShippingOptions);
