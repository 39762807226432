import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

function SeeOnlineProductButton({ productUrl }) {
  return (
    <>
      <Button type='link' className='btn-link-dark' target='_blank' rel='noopener noreferrer' href={productUrl + '?labo_similar_product=true'}>
        Voir l'article en ligne <ArrowRightOutlined className='external-link-icon' />
      </Button>
    </>
  );
}

export default SeeOnlineProductButton;
