import Icon from '@ant-design/icons';
import { Button, Flex, Modal } from 'antd';
import React from 'react';
import PublishImg from '~/assets/publish-draw.png';
import CustomAddIcon from '~/assets/svg/icon-add.svg?react';
import IconDupplicate from '~/assets/svg/icon-dupplicate.svg?react';

export default function PublishedModal({ show, setShow, goBack, goToCreation, duplicate, createPublishNotification }) {
  const onClickWrapper = (callback) => () => {
    createPublishNotification();
    setShow(false);
    callback();
  };

  return (
    <>
      <Modal open={show} closeIcon={null} footer={null} maskClosable={false} width={380}>
        <Flex vertical gap={32}>
          <Flex justify='center'>
            <img src={PublishImg} alt='publish-success' width={80} />
          </Flex>
          <Flex vertical gap={16}>
            <p className='sub2'>Votre annonce est sur le point d'être publiée sur Label Emmaüs</p>
            <p className='body1'>L'annonce est en cours de synchronisation sur Izberg. Elle sera en ligne d'ici quelques minutes.</p>
            <p className='body1'>Merci pour votre contribution à construire un marché plus solidaire et responsable !</p>
          </Flex>
          <Flex vertical gap={16}>
            <Button className='btn-success' onClick={onClickWrapper(goToCreation)} icon={<Icon component={CustomAddIcon} />}>
              Créer une nouvelle annonce
            </Button>
            <Button onClick={onClickWrapper(duplicate)} icon={<Icon component={IconDupplicate} />}>
              Duppliquer cette annonce
            </Button>
            <Button type='text' onClick={onClickWrapper(goBack)}>
              Quitter
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
