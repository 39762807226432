/* eslint-disable no-unexpected-multiline */
import { AD_DETAILS_API_IDS, getLabelAffaireIdsFromCategoriesList } from '~/helpers/adDetails';
import { BULK_ACTION_EVENTS } from '~/helpers/catalogue';
import { isDefinedAndIsNotEmptyString } from '~/helpers/general';

import { api } from './api';

const deactivateAd = (productId, callBack, errorHandler) => {
  api
    .patch('/product/' + productId + '/deactivate/')
    .then((response) => {
      if (response.success) {
        callBack(response.data);
      } else {
        errorHandler(productId, response);
      }
    })
    .catch((err) => {
      errorHandler(productId, err);
    });
};

const activateProduct = (productId, callBack = undefined, errorHandler = undefined) => {
  api
    .patch(`/product/${productId}/activate/`)
    .then((response) => {
      if (response.success) {
        if (callBack) {
          callBack(response.data);
        } else {
          // eslint-disable-next-line
          console.log(response.data);
        }
      } else if (errorHandler) {
        errorHandler(productId, response);
      }
    })
    .catch((err) => {
      if (errorHandler) {
        errorHandler(productId, err);
      } else {
        // eslint-disable-next-line
        console.log(err);
      }
    });
};

const updateOneProductOfBulkEdition = (body, productId, currentProductData, callBack = undefined, errorHandler = undefined) => {
  api
    .patch(`/product/${productId}/`, { body })
    .then((response) => {
      if (response.success) {
        if (callBack) {
          callBack(response.data);
        } else {
          return response.data;
        }
      } else if (errorHandler) {
        errorHandler(productId, response, currentProductData);
      }
    })
    .catch((err) => {
      if (errorHandler) {
        errorHandler(productId, err, currentProductData);
      } else {
        // eslint-disable-next-line
        console.log(err);
      }
    });
};

const updatePassword = (body, successCallback, failureCallback) => {
  api
    .patch('/password_reset/', { body })
    .then((response) => {
      if (response.success) {
        successCallback(response.data);
      } else if (failureCallback) {
        failureCallback(retrieveAPIerror(response));
      }
    })
    .catch((err) => {
      failureCallback(retrieveAPIerror(err));
    });
};

const applyBulkEditionToOneProduct = (
  productId,
  form_fields,
  merchantId,
  bulkActionEvent,
  bulk_stock_value,
  callBack = undefined,
  errorHandler = undefined,
  currentProductData = undefined
) => {
  let { TECHNICAL_INFO, PROMOTION, CATEGORIES } = AD_DETAILS_API_IDS;
  let update_endpoint_data = {};

  switch (bulkActionEvent) {
    case BULK_ACTION_EVENTS.EDIT_STOCK:
      update_endpoint_data[TECHNICAL_INFO.STOCK] = bulk_stock_value;
      break;

    case BULK_ACTION_EVENTS.UPDATE_CATEGORY:
      update_endpoint_data[CATEGORIES.CATEGORIE] = form_fields.categorie;
      break;

    case BULK_ACTION_EVENTS.ADD_LABEL_AFFAIRE:
      {
        let currentProductCategories = currentProductData.find((c) => c.key === CATEGORIES.MAIN_KEY)['components'][0]['default_value'];
        let currentProductRelatedLabelAffaireIds = getLabelAffaireIdsFromCategoriesList(currentProductCategories, []);

        let labelAffairesDataToSend = [];
        currentProductData
          .find((c) => c.key === PROMOTION.MAIN_KEY)
          ['components'].find((c) => c.key === PROMOTION.LABEL_AFFAIRE)
          ['choices'][0]['choices'].forEach((labelAffaire) => {
            if (currentProductRelatedLabelAffaireIds.includes(labelAffaire.id)) {
              labelAffairesDataToSend.push({
                id: labelAffaire.id,
                value: labelAffaire.value,
              });
            }
          });

        update_endpoint_data[PROMOTION.LABEL_AFFAIRE] = labelAffairesDataToSend;
      }
      break;

    case BULK_ACTION_EVENTS.EDIT_INSPIRATION:
      update_endpoint_data[PROMOTION.INSPIRATIONS] = form_fields.inspirations;
      break;

    case BULK_ACTION_EVENTS.REMOVE_LABEL_AFFAIRE:
      update_endpoint_data[PROMOTION.LABEL_AFFAIRE] = [];
      break;

    case BULK_ACTION_EVENTS.ACTIVATE:
      activateProduct(productId, callBack, errorHandler);
      return;

    case BULK_ACTION_EVENTS.DELETE:
      deactivateAd(productId, callBack, errorHandler);
      return;

    default:
      break;
  }

  // On update le produit si l'action n'est pas une activation (=publication)
  let body = JSON.stringify({
    merchant: merchantId,
    components: [update_endpoint_data],
  });
  updateOneProductOfBulkEdition(body, productId, currentProductData, callBack, errorHandler);
};

function retrieveAPIerror(apiResponse) {
  let errorMessage = `Une erreur est survenue. Si le problème persiste,
    merci de contacter l'équipe accompagnement vendeurs.`;

  if (typeof apiResponse === 'object') {
    if (isDefinedAndIsNotEmptyString(apiResponse.message) && typeof apiResponse.message === 'string') {
      errorMessage = apiResponse.message;
    } else if (
      typeof apiResponse.message === 'object' &&
      isDefinedAndIsNotEmptyString(apiResponse.message.error) &&
      typeof apiResponse.message.error === 'string'
    ) {
      errorMessage = apiResponse.message.error;
    }
  }

  return errorMessage;
}

const apiPOSTresults = (endpoint, body, successCallback = undefined, failureCallback = undefined) => {
  api
    .post(endpoint, { body })
    .then((response) => {
      if (response.success && successCallback) {
        successCallback(response.data);
      } else if (!response.success && failureCallback) {
        failureCallback(retrieveAPIerror(response));
      }
      return response.data;
    })
    .catch((err) => {
      if (failureCallback) {
        failureCallback(err);
      }
      // eslint-disable-next-line
      console.log(err);
    });
};

const computeSimilarAds = (merchant_id, form_fields, callback = undefined) => {
  let form_data_to_send = { merchant: merchant_id, components: [form_fields] };
  return apiPOSTresults('/product/similaires/', JSON.stringify(form_data_to_send), callback);
};

const sendPasswordResetRequest = (email, successCallback, failureCallback) => {
  let body = JSON.stringify({ email });
  return apiPOSTresults('/password_reset_request/', body, successCallback, failureCallback);
};

const apiGETresults = (endpoint, callBack, failureCallback = undefined, productId = undefined, successFalseCallback = undefined) => {
  api
    .get(endpoint)
    .then((response) => {
      if (response.success) {
        if (callBack) {
          callBack(response.data);
        } else {
          return response.data;
        }
      } else {
        if (callBack) {
          successFalseCallback(response.data);
        } else {
          return response.data;
        }
      }
    })
    .catch((err) => {
      if (failureCallback) {
        if (productId) {
          failureCallback(err, productId);
        } else {
          failureCallback(err);
        }
      } else {
        // eslint-disable-next-line
        console.log(err);
      }
    });
};

const getProductDetails = (productId, successCallBack, failureCallback) => {
  return apiGETresults(`/product/${productId}/`, successCallBack, failureCallback, productId);
};

const getAutocompleteSuggestion = (value, callBack) => {
  return apiGETresults(`/attributes/autocomplete/?text=${value}&attribute_key=type`, callBack);
};

const getCategorySuggestions = (value, callBack) => {
  return apiGETresults(`/suggerer_categorie/?type=${value}`, callBack);
};

const getAllShippingOptionsOfMerchant = (merchant_id, callBack) => {
  return apiGETresults(`/${merchant_id}/shipping_options/`, callBack);
};

const getProductStats = (product_id, callBack) => {
  return apiGETresults(`/product/${product_id}/stats/`, callBack);
};

const getCurrentProductShippingOptions = (merchant_id, product_id, callBack = undefined) => {
  return apiGETresults(`/${merchant_id}/product/${product_id}/shipping_assignments/`, callBack);
};

export function getOpenAiDescription(adParametersForAi, callBack, failureCallback, successFalseCallBack) {
  return apiGETresults('/product/get-ai-description/?' + adParametersForAi, callBack, failureCallback, undefined, successFalseCallBack);
}

function getOrdersPickingInfo(merchantId, orderIds, callBack, failureCallback) {
  return apiPOSTresults(`/${merchantId}/merchant_orders/picking_route_info/`, JSON.stringify({ orders: orderIds }), callBack, failureCallback);
}

export {
  applyBulkEditionToOneProduct,
  computeSimilarAds,
  deactivateAd,
  getAllShippingOptionsOfMerchant,
  getAutocompleteSuggestion,
  getCategorySuggestions,
  getCurrentProductShippingOptions,
  getOrdersPickingInfo,
  getProductDetails,
  getProductStats,
  sendPasswordResetRequest,
  updatePassword,
};
