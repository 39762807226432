import { atom } from 'jotai';
import { getAdsCatalogueData, getCollapseCookie, setAdsCatalogueData, setCollapseCookie } from '~/helpers/cookies';

const collapsedAtom = atom(getCollapseCookie());

export const collapsedWithCookieAtom = atom(
  (get) => get(collapsedAtom),
  (get, set, update) => {
    setCollapseCookie(update);
    set(collapsedAtom, update);
  }
);

const adsCatalogueDataAtom = atom(getAdsCatalogueData());

export const adsCatalogueDataWithCookieAtom = atom(
  (get) => get(adsCatalogueDataAtom),
  (get, set, update) => {
    setAdsCatalogueData(update);
    set(adsCatalogueDataAtom, update);
  }
);
