import { MenuOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

import './BackdropHeader.scss';
import BurgerHeader from './BurgerHeader';

export default function BackdropHeader({ isFocus, children }) {
  const [backdropHeadOpen, setBackdropHeadOpen] = useState(false);

  return (
    <>
      <div className={`backdrop${isFocus ? ' focused' : ''}`}>
        {!isFocus && (
          <div className='backdrop-head'>
            <div className='backdrop-head-activator'>
              <MenuOutlined onClick={() => setBackdropHeadOpen(true)} />
            </div>
            <div className='backdrop-head-content'>
              <BurgerHeader open={backdropHeadOpen} setOpen={setBackdropHeadOpen} />
            </div>
          </div>
        )}

        <div className='backdrop-content'>{children}</div>
      </div>
    </>
  );
}
