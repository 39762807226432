import colors from './colors.module.scss';

export const antTheme = {
  token: {
    fontFamily: 'FS Dillon',
    fontSize: 16,
    borderRadius: 16,
    wireframe: false,
    colorPrimary: colors.primaryDefault,
    colorPrimaryHover: colors.primaryMedium,
    colorInfo: colors.infoDefault,
    colorSuccess: colors.successDefault,
    colorWarning: colors.warningDefault,
    colorError: colors.errorDefault,
    colorLink: colors.primaryDefault,
  },
  components: {
    Button: {
      colorBorder: colors.primaryDefault,
      colorText: colors.primaryDefault,
      colorErrorActive: colors.errorDark,
      colorErrorHover: colors.errorDark,
      colorErrorBorderHover: colors.errorDark,
      colorPrimaryActive: colors.primaryMedium,
    },
    Checkbox: {
      borderRadiusSM: 4,
    },
    Divider: {
      colorSplit: colors.greyDefault,
    },
    Badge: {
      colorError: colors.primaryDefault,
    },
  },
};
