import sanitize from 'sanitize-html';
import { IS_IN_PRODUCTION, isDefinedAndIsNotEmptyString, selectValueOfChildJSONWhereOtherAttributeEquals } from '~/helpers/general';

export const AD_DETAILS_API_IDS = {
  STATUS: 'status',
  IS_LABO_PRODUCT_MORE_UPDATED: 'is_labo_product_more_updated',
  OPENAI_CALLS_COUNT: 'openai_calls_count',
  PRODUCT_URL: 'label_url',
  IMAGES: {
    MAIN_KEY: 'fieldset_image',
    UPLOAD: 'imageupload',
    IMAGES: 'images',
  },
  CATEGORIES: {
    MAIN_KEY: 'categories',
    CATEGORIE: 'categorie',
  },
  PROMOTION: {
    MAIN_KEY: 'promotion',
    LABEL_AFFAIRE: 'labelaffaire',
    INSPIRATIONS: 'inspirations',
  },
  ATTRIBUTES: {
    MAIN_KEY: 'attributes',
    AGE: 'age',
    ANNEE: 'annee',
    AUTEUR: 'auteur',
    AUTONOMIE: 'autonomie',
    AVEC_CONGELATEUR: 'avec_congelateur',
    AVEC_FREEZER: 'avec_freezer',
    BATTERIE: 'batterie',
    BLUETOOTH: 'bluetooth',
    BOOK_FORMAT: 'book_format',
    CAPACITE_L: 'capacite_l',
    CAPACITE_LAVAGE: 'capacite_lavage',
    CAPACITE_STOCKAGE_GO: 'capacite_de_stockage_go',
    CAPTEUR_FRONTAL: 'capteur_frontal',
    CAPTEUR_PRINCIPAL: 'capteur_principal',
    CARTE_GRAPHIQUE: 'carte_graphique',
    CHARGEMENT_SANS_FIL: 'chargement_sans_fil',
    CLASSE_ENERGETIQUE: 'classe_energetique',
    COLLECTION: 'collection',
    CONNECTEUR: 'connecteur',
    CONNECTIQUE_ECRAN: 'connectique_ecran',
    CONNECTIVITE: 'connectivite',
    CONSOMMATION_EAU: 'consommation_d_eau',
    COULEUR: 'couleur',
    COUPE: 'coupe',
    DAS: 'das_wkg',
    DISQUE_DUR: 'disque_dur',
    DOUBLE_SIM: 'double_sim',
    ECRAN_TACTILE: 'ecran_tactile',
    EDITIONS: 'editions',
    ETAT: {
      MAIN_KEY: 'etat',
      VALUES: {
        COMME_NEUF: {
          ID: IS_IN_PRODUCTION ? 663 : 37974,
          TOOLTIP: 'comme-neuf',
        },
        TRES_BON_ETAT: {
          ID: IS_IN_PRODUCTION ? 22449 : 1083512,
          TOOLTIP: 'très bon état',
        },
        BON_ETAT: {
          ID: IS_IN_PRODUCTION ? 661 : 37975,
          TOOLTIP: 'bon état',
        },
        ETAT_CORRECT: {
          ID: IS_IN_PRODUCTION ? 659 : 1083912,
          TOOLTIP: 'état-correct',
        },
        NEUF: {
          ID: IS_IN_PRODUCTION ? 660 : 37972,
          TOOLTIP: 'neuf',
        },
        RECONDITIONNE: {
          ID: IS_IN_PRODUCTION ? 662 : 37973,
        },
      },
    },
    ETAT_PRECISION: 'etat_precision',
    FINITION: 'finition',
    FORMAT: 'format',
    FORMAT_CARTE_SIM: 'format_carte_sim',
    GARANTIE: 'garantie',
    GPS: 'gps',
    HAUTEUR: 'hauteur',
    HAUTEUR_DU_PIED: 'hauteur_du_pied',
    LANGUE: 'langue',
    LARGEUR: 'largeur',
    LASER: 'laser',
    LONGUEUR: 'longueur',
    MARQUE: 'marque',
    MARQUE_EDITORIALE: 'marque_editoriale',
    MARQUE_PROCESSEUR: 'marque_processeur',
    MATIERE: 'matiere',
    MATIERE_CORPS: 'matiere_du_corps',
    MEMOIRE_INTERNE: 'memoire_interne',
    MODELE: 'modele',
    MODELE_PROCESSEUR: 'modele_processeur',
    MOTIF: 'motif',
    NFC: 'nfc',
    NIVEAU_SONORE: 'niveau_sonore_db',
    NIVEAUX_VITESSE: 'niveaux_de_vitesse',
    NOMBRE_COEURS: 'nombre_de_coeurs',
    NOMBRE_COUVERTS: 'nombre_de_couverts',
    POINTURE: 'pointure',
    PRESSION: 'pression',
    PRIX_LITTERAIRE: 'prix_litteraire',
    PROCESSEUR: 'processeur',
    PROFONDEUR: 'profondeur',
    PUISSANCE: 'puissance',
    RAM: 'ram',
    RECHARGEABLE: 'rechargeable',
    RELIURE: 'reliure',
    RESEAU_MOBILE: 'edge_3G_4G',
    RESOLUTION: 'resolution',
    SANS_FIL: 'sans_fil',
    SYSTEME_EXPLOITATION: 'systeme_exploitation',
    STYLE: 'style',
    TAILLE: {
      MAIN_KEY: 'taille',
      VALUES: [
        ['30', '32', '34', '36', '38', '40', '42', '44', '46', '48', '50', '52', '54', '56', '58', '60'],
        ['T0', 'T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8', 'T9', 'T10', 'TU'],
        ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
      ],
    },
    TAILLE_ECRAN: 'taille_ecran',
    TYPE_ASPIRATEUR: 'type_daspirateur',
    TYPE_CONGELATEUR: 'type_de_congelateur',
    TYPE_DOSETTES: 'type_de_dosettes',
    TYPE_FROID: 'type_de_froid',
    TYPE_LAVE_LINGE: 'type_de_lave_linge',
    TYPE_LAVE_VAISSELLE: 'type_de_lave_vaisselle',
    TYPE_MACHINE_CAFE: 'type_de_machine_a_cafe',
    TYPE_REFRIGERATEUR: 'type_de_refrigerateur',
    TYPE_ROBOT: 'type_de_robot',
    TYPE_STOCKAGE: 'type_de_stockage',
    TYPE_VENTILATEUR: 'type_de_ventilateur',
    TYPE_OUVERTURE: 'type_douverture',
    VITESSE_DU_PROCESSEUR: 'vitesse_du_processeur',
    WIFI: 'wifi',
    COMMENT: 'labo_commentaire',
    CREATIONS: {
      MAIN_KEY: 'creations',
      VALUES: {
        UPCYCLING: {
          ID: IS_IN_PRODUCTION ? 22049 : 1084369,
          TOOLTIP: 'Upcycling',
        },
        ARTISANAT: {
          ID: IS_IN_PRODUCTION ? 22048 : 1117500,
        },
      },
    },
    SHIPPING_OPTIONS: 'shipping_options',
  },
  TITLE_AND_DESCRIPTION: {
    MAIN_KEY: 'content',
    TITLE: 'title',
    DESCRIPTION: 'description',
  },
  TECHNICAL_INFO: {
    MAIN_KEY: 'gestion',
    CROSSED_PRICE: 'previous_price_with_vat',
    PRICE: 'price_with_vat',
    SKU: 'sku',
    WEIGHT: 'package_weight',
    LOCATION: 'emplacement',
    STOCK: 'stock',
  },
  TYPES: {
    RADIO: {
      MAIN_KEY: 'radiobutton',
      MULTIPLE_STATUS_KEY: 'multiple_allowed',
    },
    STRING: 'string',
    BOOL: 'bool',
    DECIMAL: 'decimal',
    INT: 'int',
  },
};

function computeOrdreEtats() {
  let ordreEtatsIds = [];
  for (const ETAT_INFO in AD_DETAILS_API_IDS.ATTRIBUTES.ETAT.VALUES) {
    if (AD_DETAILS_API_IDS.ATTRIBUTES.ETAT.VALUES[ETAT_INFO].ID !== AD_DETAILS_API_IDS.ATTRIBUTES.ETAT.VALUES.RECONDITIONNE.ID) {
      ordreEtatsIds.push(AD_DETAILS_API_IDS.ATTRIBUTES.ETAT.VALUES[ETAT_INFO].ID);
    }
  }
  return ordreEtatsIds;
}

export const ORDRE_ETATS = computeOrdreEtats();

function computeAdAttributeKeys() {
  let adAttributeNamesList = [];
  for (const ATTR_DATA in AD_DETAILS_API_IDS.ATTRIBUTES) {
    if (typeof AD_DETAILS_API_IDS.ATTRIBUTES[ATTR_DATA] === 'object') {
      adAttributeNamesList.push(AD_DETAILS_API_IDS.ATTRIBUTES[ATTR_DATA].MAIN_KEY);
    } else if (AD_DETAILS_API_IDS.ATTRIBUTES[ATTR_DATA] !== AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY) {
      adAttributeNamesList.push(AD_DETAILS_API_IDS.ATTRIBUTES[ATTR_DATA]);
    }
  }
  return adAttributeNamesList;
}

export const ALL_AD_ATTRIBUTE_KEYS = computeAdAttributeKeys();

export const AD_DETAILS_ORDER = [
  AD_DETAILS_API_IDS.IMAGES.MAIN_KEY,
  AD_DETAILS_API_IDS.CATEGORIES.MAIN_KEY,
  AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY,
  AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.MAIN_KEY,
  AD_DETAILS_API_IDS.TECHNICAL_INFO.MAIN_KEY,
  AD_DETAILS_API_IDS.PROMOTION.MAIN_KEY,
];

export const AD_DETAILS_NUMERIC_TYPES = [AD_DETAILS_API_IDS.TYPES.DECIMAL, AD_DETAILS_API_IDS.TYPES.INT];

export const AD_DETAILS_VIEWS = {
  EDITION: 'edition',
  PREVIEW: 'preview',
  STATISTICS: 'statistics',
};

export const PIXR_CLIENT_KEY = 'labo';

export function getFormFieldsPart(fieldset_name, form_fields) {
  let { TECHNICAL_INFO, TITLE_AND_DESCRIPTION, ATTRIBUTES, CATEGORIES, PROMOTION } = AD_DETAILS_API_IDS;

  switch (fieldset_name) {
    case TECHNICAL_INFO.MAIN_KEY:
      return {
        [TECHNICAL_INFO.SKU]: form_fields[TECHNICAL_INFO.SKU],
        [TECHNICAL_INFO.STOCK]: form_fields[TECHNICAL_INFO.STOCK],
        [TECHNICAL_INFO.PRICE]: form_fields[TECHNICAL_INFO.PRICE],
        [TECHNICAL_INFO.CROSSED_PRICE]: form_fields[TECHNICAL_INFO.CROSSED_PRICE],
        [TECHNICAL_INFO.LOCATION]: form_fields[TECHNICAL_INFO.LOCATION],
        [TECHNICAL_INFO.WEIGHT]: form_fields[TECHNICAL_INFO.WEIGHT],
      };

    case AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.MAIN_KEY:
      return {
        [TITLE_AND_DESCRIPTION.TITLE]: form_fields[TITLE_AND_DESCRIPTION.TITLE],
        [TITLE_AND_DESCRIPTION.DESCRIPTION]: form_fields[TITLE_AND_DESCRIPTION.DESCRIPTION],
      };

    case AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY:
      return { [ATTRIBUTES.MAIN_KEY]: form_fields[ATTRIBUTES.MAIN_KEY] };

    case AD_DETAILS_API_IDS.CATEGORIES.MAIN_KEY:
      return { [CATEGORIES.CATEGORIE]: form_fields[CATEGORIES.CATEGORIE] };

    case AD_DETAILS_API_IDS.PROMOTION.LABEL_AFFAIRE:
      return {
        [PROMOTION.LABEL_AFFAIRE]: form_fields[PROMOTION.LABEL_AFFAIRE],
      };

    case AD_DETAILS_API_IDS.PROMOTION.INSPIRATIONS:
      return { [PROMOTION.INSPIRATIONS]: form_fields[PROMOTION.INSPIRATIONS] };

    default:
      break;
  }
}

// TODO : certaines couleurs sont illisibles (noir, fauve)
// appliquer les conseils de refactoring pour améliorer le contrast-ratio
// entre la couleur de font + la couleur de fond (pun intended héhé)
const COLORS = {
  Bordeau: '#AC2164',
  Cuivre: 'linear-gradient(180deg, #E75719 0%, #FFA1C9 100%)',
  Fushia: '#FF38AF',
  Beige: '#FFE6C8',
  Lila: '#D2B9F1',

  Blanc: '#fff',
  Noir: '#131013',
  Gris: '#D0C6C6',
  Jaune: '#FFDF38',
  Rose: '#FFD4EE',
  Rouge: '#FF3838',
  Fauve: '#CE6707',
  Kaki: '#677F70',
  Vert: '#12A018',
  Doré: 'linear-gradient(180deg, #E7BA19 0%, #FFF0A1 100%)',
  Marron: '#962222',
  Violet: '#8741E1',
  Ecru: '#F3EFE0',
  Multicolore: 'conic-gradient(from 180deg at 50% 50%, #3F11F6 0deg, #E03E3E 125.62500357627869deg, #F345E2 221.24999284744263deg, #FFCE1E 360deg)',
  Bleu: '#119FCC',
  Orange: '#FFAF38',
  Taupe: '#BEA0A0',
  Argenté: 'linear-gradient(180deg, #B4B3B3 0%, #F2F2F2 100%)',
};

export function getHexaColorFromColorName(colorName) {
  return COLORS[colorName];
}

export function getBorderColorFromColorName(colorName) {
  const borderColorMap = {
    Blanc: '#D5D5D8',
    Taupe: '#FFF',
    Beige: '#FFF',
  };
  return borderColorMap?.[colorName] ? borderColorMap[colorName] : null;
}

const LABEL_AFFAIRE_API_IDS__PROD = {
  GENERIC: 32415,
  MODE_FEMME: 34846,
  MODE_HOMME: 34847,
  MODE_ENFANT: 34850,
  OBJETS_MEUBLES: 34848,
  LIVRES: 34849,
  HIGH_TECH: 46680,
  LOISIRS: 51004,
};

export const CATEGORY_API_IDS__PROD = {
  MAISON: {
    ID: 71224,
    ASSISES_ET_COUCHAGES: {
      ID: 23974,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__PROD.OBJETS_MEUBLES],
    },
    ELECTROMENAGER: {
      ID: 52655,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__PROD.HIGH_TECH],
    },
    DECORATION: {
      ID: 23942,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__PROD.OBJETS_MEUBLES],
    },
    MOBILIER_PROFESSIONNEL: {
      ID: 24513,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__PROD.OBJETS_MEUBLES],
    },
    ART_DE_LA_TABLE: {
      ID: 23999,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__PROD.OBJETS_MEUBLES],
    },
    MOBILIER: {
      ID: 34867,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__PROD.OBJETS_MEUBLES],
    },
    ZERO_DECHET: {
      ID: 53537,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__PROD.OBJETS_MEUBLES],
    },
    COLLECTIONNEURS: {
      ID: 80286,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__PROD.OBJETS_MEUBLES],
    },
  },
  MODE: {
    ID: 23946,
    ENFANT: {
      ID: 23967,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__PROD.MODE_ENFANT],
    },
    FEMME: {
      ID: 92973,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__PROD.MODE_FEMME],
    },
    HOMME: {
      ID: 92974,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__PROD.MODE_HOMME],
    },
  },
  LOISIRS: {
    ID: 23976,
    LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__PROD.LOISIRS],
  },
  LIBRAIRIE: {
    ID: 23981,
    LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__PROD.LIVRES],
  },
  HIGH_TECH_ELECTRO: {
    ID: 23972,
    LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__PROD.HIGH_TECH],
  },
};

const LABEL_AFFAIRE_API_IDS__SAND = {
  GENERIC: 1184272,
  MODE_FEMME: 1184274,
  MODE_HOMME: 1184275,
  MODE_ENFANT: 1184276,
  OBJETS_MEUBLES: 1184273,
  LIVRES: 1184271,
  HIGH_TECH: 1184277,
  LOISIRS: 1184278,
};

export const CATEGORY_API_IDS__SAND = {
  MAISON: {
    ID: 1179253,
    ASSISES_ET_COUCHAGES: {
      ID: 22321,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__SAND.OBJETS_MEUBLES],
    },
    ELECTROMENAGER: {
      ID: 1110830,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__SAND.HIGH_TECH],
    },
    DECORATION: {
      ID: 22469,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__SAND.OBJETS_MEUBLES],
    },
    MOBILIER_PROFESSIONNEL: {
      ID: 89307,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__SAND.OBJETS_MEUBLES],
    },
    ART_DE_LA_TABLE: {
      ID: 22300,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__SAND.OBJETS_MEUBLES],
    },
    MOBILIER: {
      ID: 89304,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__SAND.OBJETS_MEUBLES],
    },
    ZERO_DECHET: {
      ID: 1110840,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__SAND.OBJETS_MEUBLES],
    },
    COLLECTIONNEURS: {
      ID: 1179254,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__SAND.OBJETS_MEUBLES],
    },
  },
  MODE: {
    ID: 22505,
    ENFANT: {
      ID: 22549,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__SAND.MODE_ENFANT],
    },
    FEMME: {
      ID: 1181858,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__SAND.MODE_FEMME],
    },
    HOMME: {
      ID: 1181859,
      LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__SAND.MODE_HOMME],
    },
  },
  LOISIRS: {
    ID: 22579,
    LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__SAND.LOISIRS],
  },
  LIBRAIRIE: {
    ID: 22580,
    LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__SAND.LIVRES],
  },
  HIGH_TECH_ELECTRO: {
    ID: 22632,
    LABEL_AFFAIRE: [LABEL_AFFAIRE_API_IDS__SAND.HIGH_TECH],
  },
};

let CATEGORY_API_IDS = IS_IN_PRODUCTION ? CATEGORY_API_IDS__PROD : CATEGORY_API_IDS__SAND;
let LABEL_AFFAIRE_API_IDS = IS_IN_PRODUCTION ? LABEL_AFFAIRE_API_IDS__PROD : LABEL_AFFAIRE_API_IDS__SAND;
const OUTSIDER_LABEL_AFFAIRE_ID = IS_IN_PRODUCTION ? LABEL_AFFAIRE_API_IDS__PROD.LIVRES : LABEL_AFFAIRE_API_IDS__SAND.LOISIRS;

// Pour aller chercher dans les enfants pour selectValueOfChildJSONWhereOtherAttributeEquals
const CATEGORIES_SEARCHABLE_LIST = [CATEGORY_API_IDS, CATEGORY_API_IDS.MAISON, CATEGORY_API_IDS.MODE];

export { CATEGORIES_SEARCHABLE_LIST, CATEGORY_API_IDS, LABEL_AFFAIRE_API_IDS, OUTSIDER_LABEL_AFFAIRE_ID };

export const MAX_LABEL_LENGTH_BEFORE_COLUMN_LAYOUT = 25;

export function getLabelAffaireIdsFromCategoriesList(productCategoriesList, initialLabelAffaireIds) {
  let labelAffaireIds = initialLabelAffaireIds;
  let productCategoryIds = productCategoriesList.map((cat) => cat.id);

  productCategoryIds.forEach((categoryId) => {
    let relatedLabelAffaires = '';

    CATEGORIES_SEARCHABLE_LIST.forEach((categoriesList) => {
      if (!isDefinedAndIsNotEmptyString(relatedLabelAffaires)) {
        relatedLabelAffaires = selectValueOfChildJSONWhereOtherAttributeEquals('LABEL_AFFAIRE', categoriesList, 'ID', categoryId);
        if (isDefinedAndIsNotEmptyString(relatedLabelAffaires)) {
          labelAffaireIds = labelAffaireIds.concat(relatedLabelAffaires);
        }
      }
    });
  });

  return labelAffaireIds;
}

export function getValueByIdInLabelAffaire(id, labelAffaire) {
  if (labelAffaire.length !== 1) {
    return '';
  }
  const labelAffaireMain = labelAffaire[0];
  return labelAffaireMain.id === id ? labelAffaireMain.value : labelAffaireMain.choices.find((label) => label.id === id)?.value;
}

// Renvoie un tableau vide si les label affaires courante ne sont pas compatibles avec la nouvelle categorie selectionnee
export function updateLabelAffairesToCurrentCategories(productRelatedCategories, currentlySelectedLabelAffaires, labelAffaires) {
  const currentProductRelatedLabelAffaireIds = getLabelAffaireIdsFromCategoriesList(productRelatedCategories, []);
  const labelAffairesUpdatedToCurrentCategories = [];

  currentlySelectedLabelAffaires.forEach((labelAffaire) => {
    if (!currentProductRelatedLabelAffaireIds.includes(labelAffaire.id)) {
      const labelAffaireId = currentProductRelatedLabelAffaireIds.pop();
      labelAffairesUpdatedToCurrentCategories.push({ id: labelAffaireId, value: getValueByIdInLabelAffaire(labelAffaireId, labelAffaires) });
    } else {
      labelAffairesUpdatedToCurrentCategories.push(labelAffaire);
    }
  });
  return labelAffairesUpdatedToCurrentCategories;
}

/**
 * [reconciliateAttributes given a new attributes list, clean the current attributes state
 * if one of its keys isn't found in the attributes. It also keep track of previous values
 * by saving them in the deletedAttributes state so that it can put again onto the new
 * attribute state if the user selects a previous selected category]
 * @param  attributes                 [current attributes state in the products reducer]
 * @param  deletedAttributes          [current deletedAttributes state in the products reducer]]
 * @param  newAttributesComponentList [new fetched attributes in a form of an array object
 * each object represents an attribute ({ key, value, required, ... })]
 * @return                             [return false if the current state is empty or returns the new
 * attributes / deletedAttributes state]
 */
export function reconciliateAttributes({ attributes, deletedAttributes }, newAttributesComponentList) {
  let current_attributes_keys = Object.keys(attributes);
  // TODO: this condition is a problem because it doesn't take the scenario
  // where some key present in the newAttributesComponentList are in the deletedAttributes state
  if (current_attributes_keys.length > 0) {
    let newAttributeState = { ...attributes };
    let newDeletedAttributes = { ...deletedAttributes };
    current_attributes_keys.forEach((attributeKey) => {
      let is_on_new_attributes_list = newAttributesComponentList.some((newAttr) => newAttr.key === attributeKey);
      if (!is_on_new_attributes_list) {
        newDeletedAttributes[attributeKey] = attributes[attributeKey];
        delete newAttributeState[attributeKey];
      }
    });

    newAttributesComponentList.forEach((newAttribute) => {
      let already_here = current_attributes_keys.some((stateAttrKey) => stateAttrKey === newAttribute.key);
      if (!already_here) {
        let found_in_deleted_attributes = Object.keys(newDeletedAttributes).some((deletedAttributeKey) => deletedAttributeKey === newAttribute.key);
        if (found_in_deleted_attributes) {
          let oldValue = newDeletedAttributes[newAttribute.key];
          newAttributeState[newAttribute.key] = oldValue;
        }
      }
    });
    return {
      attributes: newAttributeState,
      deletedAttributes: newDeletedAttributes,
      reconciliated: true,
    };
  }

  return {
    attributes: {},
    reconciliated: false,
  };
}

/*
  We generate the form initial state thanks to the component's keys of each fieldsets
  The form initial state is basically the json needed to create a product
 */
export function createInitialFormFieldsAndValidationRules(schema) {
  const form_fields = {};
  const validation_rules = {};
  const all_form_components = {};

  const { ATTRIBUTES, IMAGES, TITLE_AND_DESCRIPTION, CATEGORIES } = AD_DETAILS_API_IDS;

  // This is debt to fix when new backend is ready
  const keysToGetHelptextInFieldset = [CATEGORIES.CATEGORIE, IMAGES.UPLOAD];
  const keysToGetLabelInFieldset = [IMAGES.UPLOAD];

  schema['components'][0]['components'].forEach((fieldset) => {
    if (fieldset['key'] === ATTRIBUTES.MAIN_KEY) {
      form_fields[fieldset['key']] = fieldset['default_value'];
    }
    fieldset['components'] &&
      fieldset['components'].forEach((component) => {
        if (component['key']) {
          all_form_components[component['key']] = {
            ...component,
            field_type: 'main',
          };
          if (keysToGetHelptextInFieldset.includes(component['key'])) {
            all_form_components[component['key']].helptext = fieldset['helptext'];
          }
          if (keysToGetLabelInFieldset.includes(component['key'])) {
            all_form_components[component['key']].label = fieldset['label'];
          }
          validation_rules[component['key']] = component['validation_rules'];
          form_fields[component['key']] = component['key'] === IMAGES.IMAGES ? component['items'] : component['default_value'];
          // Clean old descriptions from html tags
          if (component['key'] === TITLE_AND_DESCRIPTION.DESCRIPTION) {
            const description = component['default_value'].replace(/<br\s*\/?>/g, '\n');
            form_fields[component['key']] = sanitize(description, { allowedTags: [] });
          }
        }
      });
  });

  return { form_fields, validation_rules, all_form_components };
}

export function fillFormFieldsWithTempData(form_fields, temp_data) {
  Object.keys(temp_data).forEach((key) => {
    if (key !== AD_DETAILS_API_IDS.TECHNICAL_INFO.SKU) {
      form_fields[key] = temp_data[key];
    }
  });
}

const isAttributeEmpty = (attr) => {
  if (!attr) {
    return false;
  }
  if (attr === {}) {
    return true;
  }
  // C'est un cas d'instanciation uniquement si tous null ou "", donc vide
  let values = Object.values(attr);
  for (let k = 0; k < values.length; k++) {
    if ((values[k] !== null) & (values[k] !== '')) {
      return false;
    }
  }
  return true;
};

export const areAttributesValuesStringsEquals = (value1, value2) => {
  if (!value1 && !value2) {
    return true;
  }

  // Les chaines de charactères ou les nombres peuvent être
  // modifiés puis finalement supprimé
  if ((!value1 && value2 === '') || (!value2 && value1 === '') || (!value1 && value2 === '0') || (!value2 && value1 === '0')) {
    return true;
  }

  return value1 === value2;
};

export const areNumbersEquals = (value1, value2) => {
  if (!value1 && !value2) {
    return true;
  }

  if ((!value1 && value2 === '0') || (!value2 && value1 === '0')) {
    return true;
  }

  // Les nombres sont parsés car en local les inputs gèrent des Strings
  // Mais des nombres sont envoyés au départ mais on pouvait avoir : "7" != 7
  return parseFloat(value1) === parseFloat(value2);
};

export const areAttributesEquals = (categories1, categories2) => {
  if (isAttributeEmpty(categories1) && isAttributeEmpty(categories2)) {
    return true;
  }
  if ((isAttributeEmpty(categories1) && categories2) || (categories1 && isAttributeEmpty(categories2))) {
    return false;
  }

  // Certains attributs ne sont pas gardé dans le processus car inutile ici : location, country_code, city, state
  // zipcode, country_province_code, emplacement (Ils sont configuré dans Iceberg et ont une utilité technique exclusivement)
  const keys_to_ignore = [
    'location',
    'country_code',
    'city',
    'state',
    'zipcode',
    'country_province_code',
    'emplacement',
    'shipping_options',
    'langue',
    'format',
  ];

  const keys_for_attributes_1 = Object.keys(categories1).filter((key) => !keys_to_ignore.includes(key));
  const keys_for_attributes_2 = Object.keys(categories2).filter((key) => !keys_to_ignore.includes(key));
  if (categories1 && categories2) {
    for (let key of keys_for_attributes_1) {
      if (!areAttributesValuesStringsEquals(categories1[key], categories2[key])) {
        return false;
      }
    }
    for (let key2 of keys_for_attributes_2) {
      if (!areAttributesValuesStringsEquals(categories1[key2], categories2[key2])) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export function doArraysContainTheSameValues(array1, array2) {
  if (array1.length === array2.length) {
    // eslint-disable-next-line
    return array1.every((element, index) => {
      return array2.includes(element);
    });
  }
  return false;
}

export const OPENAI_MAX_CALLS_PER_PRODUCT = 1;

export function findComponent(components, key) {
  return components.find((component) => component.key === key);
}

export function changeDescriptionForMultiline(description) {
  // To counter description cleaning done by izberg
  return description.replaceAll('\n', '<br><p>&shy;</p>');
}
