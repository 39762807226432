import Icon, { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Affix, Button, Flex, Tag, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import IconDupplicate from '~/assets/svg/icon-dupplicate.svg?react';
import SeeOnlineProductButton from '~/components/shared/SeeOnlineProductButton/SeeOnlineProductButton';
import { AD_STATUS, getColorFromStatus, getStatusLabel } from '~/helpers/adDetails';
import breakpoints from '~/styles/breakpoints.module.scss';

import './ButtonBar.scss';

const breakpointMobile = parseInt(breakpoints.smMax.slice(0, -2));

function NavigationButtons({ productStatus, navigation, disabled }) {
  return (
    <Flex className={`navigation-btn-wrapper${navigation.on ? ' navigation-enabled' : ''}`} justify='center' align='center' gap={4}>
      <p className='caption2'>Annonce :</p>
      {navigation.on && (
        <Button type='text' icon={<LeftOutlined />} disabled={navigation.prev.disabled || disabled} onClick={navigation.prev.event}>
          Précédente
        </Button>
      )}
      <Tag className='light capitalize-first-letter' color={getColorFromStatus(productStatus)}>
        {getStatusLabel(productStatus)}
      </Tag>
      {navigation.on && (
        <Button type='text' disabled={navigation.next.disabled || disabled} onClick={navigation.next.event}>
          Suivante <RightOutlined />
        </Button>
      )}
    </Flex>
  );
}

function SaveButtons({ editionMode, productStatus, saveProduct, duplicate, goBack, saving, productUrl }) {
  const isMobile = window.innerWidth <= breakpointMobile;

  const publishButton = {
    text: 'Publier',
  };

  const saveButton = {
    text: 'Enregistrer le brouillon et continuer',
    visible: true,
  };

  if (editionMode) {
    if (productStatus === AD_STATUS.ACTIVE.API_ID) {
      saveButton.visible = false;
      publishButton.text = 'Publier les modifications';
    } else {
      saveButton.text = 'Enregistrer et continuer';
    }
  }

  const publishing = saving.is && saving.publish;
  const savingDraft = saving.is && !saving.publish;

  return (
    <Flex className='save-btn-wrapper' justify='flex-end' align='center' gap={8}>
      {productUrl && !isMobile && <SeeOnlineProductButton productUrl={productUrl} />}
      {editionMode && !isMobile && (
        <Button type='text' onClick={goBack} disabled={saving.is}>
          Quitter
        </Button>
      )}
      {editionMode && (
        <Tooltip title='Dupliquer cette annonce'>
          <Link onClick={duplicate.event} to={duplicate.link}>
            <Button type='text' className='btn-icon' icon={<Icon component={IconDupplicate} />} size='large' disabled={saving.is} />
          </Link>
        </Tooltip>
      )}
      {saveButton.visible && (
        <Button onClick={() => saveProduct(false)} loading={savingDraft} disabled={saving.is}>
          {saveButton.text}
        </Button>
      )}
      <Button type='primary' onClick={() => saveProduct(true)} loading={publishing} disabled={saving.is}>
        {publishButton.text}
      </Button>
    </Flex>
  );
}

function ButtonBar({ panelRef, editionMode, productStatus, saving, navigation, saveProduct, duplicate, goBack, productUrl }) {
  const target = () => {
    if (window.innerWidth <= breakpointMobile) {
      return window;
    }
    return panelRef.current;
  };
  return (
    <Affix rootClassName='button-bar-affix' target={target} offsetBottom={0}>
      <div className={`button-bar-wrapper${editionMode ? ' edition-mode' : ''}`}>
        {editionMode && <NavigationButtons productStatus={productStatus} navigation={navigation} disabled={saving.is} />}
        <SaveButtons
          editionMode={editionMode}
          productStatus={productStatus}
          saveProduct={saveProduct}
          duplicate={duplicate}
          goBack={goBack}
          saving={saving}
          productUrl={productUrl}
        />
      </div>
    </Affix>
  );
}

export default ButtonBar;
