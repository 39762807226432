import { Button, Flex, Modal } from 'antd';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import Asterisk from '~/components/shared/Asterisk/Asterisk';
import Dialogue from '~/components/shared/Dialogue/Dialogue';
import { AD_DETAILS_API_IDS } from '~/helpers/adDetails';
import { HANDLE_CHANGE } from '~/store/reducers/products';
import breakpoints from '~/styles/breakpoints.module.scss';

import './Category.scss';
import CategorySelection from './CategorySelection/CategorySelection';
import CategorySelectionDrawer from './CategorySelectionDrawer/CategorySelectionDrawer';
import CategorySuggestion from './CategorySuggestion/CategorySuggestion';

const breakpointMobile = parseInt(breakpoints.xsMax.slice(0, -2));

function Category({ dispatch, component, editionMode, titleValue, selectedCategories }) {
  const [showModal, setShowModal] = useState(false);
  const changeCategoryTemp = useRef(null);
  const showSuggestedCategories = titleValue.length > 0;
  const { choices, label, helptext } = component;
  const required = true;

  const propagateChangeCategory = (value) => {
    dispatch({
      type: HANDLE_CHANGE,
      payload: {
        input_key: AD_DETAILS_API_IDS.CATEGORIES.CATEGORIE,
        value: value,
      },
    });
  };

  const handleModal = (confirm = false) => {
    if (confirm) {
      propagateChangeCategory(changeCategoryTemp.current.value);
      changeCategoryTemp.current.callback();
    }
    setShowModal(false);
  };

  const changeCategory = (value, callback = () => {}) => {
    if (editionMode && _.isEqual(value, selectedCategories) === false) {
      setShowModal(true);
      changeCategoryTemp.current = {
        value: value,
        callback: callback,
      };
    } else {
      propagateChangeCategory(value);
      callback();
    }
  };

  return (
    <>
      <div className='form-item-header'>
        <p className='sub2'>
          {label}
          {required && <Asterisk />}
        </p>
        {helptext && <p className='subtext'>{helptext}</p>}
      </div>
      <p className='body1 text-grey-medium'>Suggestion de catégorie selon votre titre</p>
      {showSuggestedCategories && <CategorySuggestion changeCategory={changeCategory} title={titleValue} selectedCategories={selectedCategories} />}
      {window.innerWidth < breakpointMobile ? (
        <CategorySelectionDrawer changeCategory={changeCategory} categories={choices} selectedCategories={selectedCategories} />
      ) : (
        <CategorySelection changeCategory={changeCategory} categories={choices} selectedCategories={selectedCategories} />
      )}
      {editionMode && (
        <Dialogue
          type='warning'
          light
          message='Changer de catégorie entraine la suppression définitive des informations saisies dans la section “Caractéristiques”. '
        />
      )}
      <Modal open={showModal} closeIcon={null} footer={null} maskClosable={false}>
        <Flex vertical gap={32}>
          <Flex vertical gap={16}>
            <p className='sub2'>Êtes-vous sûr·e de vouloir changer la catégorie ?</p>
            <p className='body1'>Cela entraine la suppression définitive des informations saisies dans la section “Caractéristiques”.</p>
            <p className='body1'>
              De nouvelles caractéristiques correspondantes à la nouvelle catégorie seront à remplir. Cela vous permet de proposer une annonce plus pertinente
              pour vos client·es !
            </p>
          </Flex>
          <Flex vertical gap={16}>
            <Button type='primary' onClick={() => handleModal(true)}>
              Oui, je suis sûr·e
            </Button>
            <Button onClick={() => handleModal()}>Annuler</Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}

export default connect((state) => {
  const { form_fields } = state.products;
  const titleValue = form_fields[AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.TITLE];
  const selectedCategories = form_fields[AD_DETAILS_API_IDS.CATEGORIES.CATEGORIE];
  return {
    titleValue,
    selectedCategories,
  };
})(Category);
