import * as Sentry from '@sentry/react';
import { Button, App as GlobalApp, Layout, Result } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoginNavigator from '~/components/nav/LoginNavigator/LoginNavigator';
import MainNavigator from '~/components/nav/MainNavigator/MainNavigator';
import { getUserCookie } from '~/helpers/cookies';
import { RoutingUrl } from '~/helpers/general';
import { USER_LOGIN_SUCCESS } from '~/store/reducers/session';

import './App.scss';

dayjs.locale('fr');

function ErrorFallback({ error, resetError }) {
  return (
    <>
      <Result
        status='error'
        title='Une erreur est survenue'
        extra={
          <>
            <Button onClick={resetError}>Réessayer</Button>
            <Button type='primary' href={RoutingUrl.home()}>
              Retour à l'accueil
            </Button>
          </>
        }
      >
        {error && <p className='caption-1 text-grey'>{error.toString()}</p>}
      </Result>
    </>
  );
}

function App({ user, dispatch }) {
  const userLocalStorage = getUserCookie();
  const loggedIn = !!user.email;

  /* Trick because we need this component to be attached to user in state (for changes deconnexion)
  Because when component is mounted the first time the user is still not in state.
  So it renders twice and goes by both navigator.
  Resulting in two redirections to finish at home.
  Solutions : ??? proly remove user from redux and use prop drilling
  */
  const doWeWaitStateChange = userLocalStorage && !user.email;

  useEffect(() => {
    if (userLocalStorage) {
      dispatch({
        type: USER_LOGIN_SUCCESS,
        user: JSON.parse(userLocalStorage),
      });
    }
  }, []);

  return (
    <GlobalApp>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <Layout className='base-layout'>{!doWeWaitStateChange && (loggedIn ? <MainNavigator /> : <LoginNavigator />)}</Layout>
      </Sentry.ErrorBoundary>
    </GlobalApp>
  );
}

export default connect((state) => ({ user: state.session.user }))(App);
