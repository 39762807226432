import { CloseCircleFilled } from '@ant-design/icons';
import { Flex, Steps } from 'antd';
import { format } from 'date-fns';
import React from 'react';

import { transitionApiKeys } from '../OrderDetails';
import './OrderStatusStepper.scss';

/* eslint-disable */

export default function OrderStatusStepper({ statusHistory }) {
  const currentTransitionLog = statusHistory.length > 0 ? statusHistory[0] : null;
  const currentTransition = currentTransitionLog?.transition;

  if (currentTransition === transitionApiKeys.CANCELED) {
    return (
      <Flex vertical gap={8} align='center'>
        <CloseCircleFilled className='icon text-error' />
        <p className='body1 text-error'>Annulée</p>
        <p className='caption1 text-grey-medium' title={format(currentTransitionLog.timestamp, 'DD.MM.YYYY HH:mm:ss')}>
          {format(currentTransitionLog.timestamp, 'DD.MM.YY')}
        </p>
      </Flex>
    );
  }

  const steps = [
    {
      title: 'Authorisée',
      key: transitionApiKeys.AUTHORIZED,
    },
    {
      title: 'Confirmée',
      key: transitionApiKeys.CONFIRMED,
    },
    {
      title: 'Traitée',
      key: transitionApiKeys.PROCESSED,
    },
  ].map((step) => {
    const transition = statusHistory.find((transition) => transition.transition === step.key);
    if (!transition) return step;

    return {
      ...step,
      description: (
        <p className='body1' title={format(transition.timestamp, 'DD.MM.YYYY HH:mm:ss')}>
          {format(transition.timestamp, 'DD.MM.YY')}
        </p>
      ),
    };
  });

  const currentStep = steps.reduce((acc, step) => {
    if (statusHistory.find((transition) => transition.transition === step.key)) {
      return acc + 1;
    }
    return acc;
  }, 0);

  return (
    <>
      <Steps className='order-status-stepper' items={steps} current={currentStep} />
    </>
  );
}
