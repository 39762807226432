export default {
  name: 'Nom',
  save: 'Enregistrer',
  saveAs: 'Enregistrer sous',
  back: 'Retour',
  loading: 'Chargement...',
  resetOperations: 'Réinitialiser/supprimer toutes les opérations',
  changesLoseWarningHint: 'Si vous appuyez sur le bouton "réinitialiser", vos modifications seront perdues. Voulez-vous continuer ?',
  discardChangesWarningHint: 'Si vous fermez la fenêtre, votre dernière modification ne sera pas enregistrée.',
  cancel: 'Annuler',
  apply: 'Appliquer',
  warning: 'Avertissement',
  confirm: 'Confirmer',
  discardChanges: 'Annuler les modifications',
  undoTitle: 'Annuler la dernière opération',
  redoTitle: 'Rétablir la dernière opération',
  showImageTitle: "Afficher l'image originale",
  zoomInTitle: 'Agrandir',
  zoomOutTitle: 'Rétrécir',
  toggleZoomMenuTitle: 'Basculer le menu de zoom',
  adjustTab: 'Ajuster',
  finetuneTab: 'Affiner',
  filtersTab: 'Filtres',
  watermarkTab: 'Filigrane',
  annotateTabLabel: 'Annoter',
  resize: 'Redimensionner',
  resizeTab: 'Redimensionner',
  imageName: "Nom de l'image",
  invalidImageError: 'Image invalide fournie.',
  uploadImageError: "Erreur lors du téléchargement de l'image.",
  areNotImages: 'ne sont pas des images',
  isNotImage: "n'est pas une image",
  toBeUploaded: 'à télécharger',
  cropTool: 'Rogner',
  original: 'Original',
  custom: 'Personnalisé',
  square: 'Carré',
  landscape: 'Paysage',
  portrait: 'Portrait',
  ellipse: 'Ellipse',
  classicTv: 'TV classique',
  cinemascope: 'Cinémascope',
  arrowTool: 'Flèche',
  blurTool: 'Flou',
  brightnessTool: 'Luminosité',
  contrastTool: 'Contraste',
  ellipseTool: 'Ellipse',
  unFlipX: 'Dé-retourner X',
  flipX: 'Retourner X',
  unFlipY: 'Dé-retourner Y',
  flipY: 'Retourner Y',
  hsvTool: 'HSV',
  hue: 'Teinte',
  brightness: 'Luminosité',
  saturation: 'Saturation',
  value: 'Valeur',
  imageTool: 'Image',
  importing: 'Importation...',
  addImage: '+ Ajouter une image',
  uploadImage: 'Télécharger une image',
  fromGallery: 'Depuis la galerie',
  lineTool: 'Ligne',
  penTool: 'Stylo',
  polygonTool: 'Polygone',
  sides: 'Côtés',
  rectangleTool: 'Rectangle',
  cornerRadius: 'Rayon de coin',
  resizeWidthTitle: 'Largeur en pixels',
  resizeHeightTitle: 'Hauteur en pixels',
  toggleRatioLockTitle: 'Basculer le verrouillage du ratio',
  resetSize: "Réinitialiser à la taille d'image originale",
  rotateTool: 'Pivoter',
  textTool: 'Texte',
  textSpacings: 'Espacements de texte',
  textAlignment: 'Alignement du texte',
  fontFamily: 'Police de caractères',
  size: 'Taille',
  letterSpacing: 'Espacement des lettres',
  lineHeight: 'Hauteur de ligne',
  warmthTool: 'Chaleur',
  addWatermark: '+ Ajouter un filigrane',
  addTextWatermark: '+ Ajouter un filigrane texte',
  addWatermarkTitle: 'Choisissez le type de filigrane',
  uploadWatermark: 'Télécharger un filigrane',
  addWatermarkAsText: 'Ajouter en tant que texte',
  padding: 'Marge intérieure',
  paddings: 'Marges intérieures',
  shadow: 'Ombre',
  horizontal: 'Horizontal',
  vertical: 'Vertical',
  blur: 'Flou',
  opacity: 'Opacité',
  transparency: 'Transparence',
  position: 'Position',
  stroke: 'Trait',
  saveAsModalTitle: 'Enregistrer sous',
  extension: 'Extension',
  format: 'Format',
  nameIsRequired: 'Le nom est requis.',
  quality: 'Qualité',
  imageDimensionsHoverTitle: "Taille de l'image enregistrée (largeur x hauteur)",
  cropSizeLowerThanResizedWarning:
    'Notez que la zone de recadrage sélectionnée est inférieure à la taille redimensionnée appliquée, ce qui pourrait entraîner une diminution de la qualité',
  actualSize: 'Taille réelle (100%)',
  fitSize: 'Ajuster à la taille',
  addImageTitle: "Sélectionnez l'image à ajouter...",
  mutualizedFailedToLoadImg: "Échec du chargement de l'image.",
  tabsMenu: 'Menu',
  download: 'Télécharger',
  width: 'Largeur',
  height: 'Hauteur',
  plus: '+',
  cropItemNoEffect: 'Aucun aperçu disponible pour cet élément de recadrage',
};
