import Icon, { ArrowLeftOutlined, RightOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import React, { Fragment, useState } from 'react';
import categoryIdToIconMapper from '~/components/shared/CategoryIcon/CategoryIcon';
import CustomSelect from '~/components/shared/CustomSelect/CustomSelect';

import { getChoicesForSelection } from '../helpers';

function getLeaves(category, leaves, parents) {
  if (category.choices.length === 0) {
    leaves.push({
      ...category,
      parents: parents,
    });
    return;
  }
  return category.choices.forEach((c) => getLeaves(c, leaves, [...parents, category]));
}

function getLeavesFromCategories(categories) {
  const leaves = [];
  categories.forEach((category) => getLeaves(category, leaves, []));
  return leaves;
}

export default function CategorySelection({ changeCategory, categories, selectedCategories }) {
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState([]);
  const currentCategory = selectedCategories && selectedCategories.length > 0 ? selectedCategories[selectedCategories.length - 1].value : null;
  const [searchValue, setSearchValue] = useState(null);
  const [searchItems, setSearchItems] = useState([]);

  const leaves = getLeavesFromCategories(categories);
  const choices = getChoicesForSelection(categories, selection);

  const onSearch = (value) => {
    setSearchValue(value);
    setSelection([]);

    if (value.length > 2) {
      const filteredLeaves = leaves
        .filter((leaf) => leaf.value.toLowerCase().includes(value.toLowerCase()))
        .sort((a, b) => {
          return a.value.toLowerCase().indexOf(value.toLowerCase()) + value.length - (b.value.toLowerCase().indexOf(value.toLowerCase()) + value.length);
        });
      setSearchItems(
        filteredLeaves.map((category) => {
          const categoryToSubmit = [...category.parents, category].map((c) => ({ id: c.id, value: c.value }));
          return {
            key: category.id,
            value: (
              <Flex
                justify='space-between'
                align='center'
                className='category-select-item'
                onClick={() =>
                  changeCategory(categoryToSubmit, () => {
                    // Delay for the animation of closing dropdown to finish
                    setOpen(false);
                    setSearchValue(null);
                    setSearchItems([]);
                  })
                }
              >
                <Flex gap={8} align='center'>
                  {category.parents.length > 0 && (
                    <>
                      <Icon className='category-icon' component={categoryIdToIconMapper(category.parents[0].id)} />
                      <RightOutlined className='category-arrow-icon' />
                      {category.parents.length > 1 &&
                        category.parents.slice(1).map((parent) => (
                          <Fragment key={`parent-${category.id}-${parent.id}`}>
                            <p className='body1'>{parent.value}</p>
                            <RightOutlined className='category-arrow-icon' />
                          </Fragment>
                        ))}
                    </>
                  )}
                  <p className='body1'>{category.value}</p>
                </Flex>
              </Flex>
            ),
          };
        })
      );
    } else {
      setSearchItems([]);
    }
  };

  const items = choices.map((category) => {
    return {
      key: category.id,
      value: (
        <Flex
          justify='space-between'
          align='center'
          className='category-select-item'
          onClick={() => {
            if (category.leaf) {
              setOpen(false);
              changeCategory(
                [...selection, category].map((c) => ({ id: c.id, value: c.name })),
                () => {
                  // Delay for the animation of closing dropdown to finish
                  setTimeout(() => {
                    setSelection([]);
                  }, 100);
                }
              );
            } else {
              setSelection([...selection, category]);
            }
          }}
        >
          <Flex gap={8} align='center'>
            {selection.length === 0 && <Icon className='category-icon' component={categoryIdToIconMapper(category.id)} />}
            {selection.length > 0 && (
              <>
                <Icon className='category-icon' component={categoryIdToIconMapper(selection[0].id)} />
                <RightOutlined className='category-arrow-icon' />
              </>
            )}
            <p className='body1'>{category.name}</p>
          </Flex>
          {!category.leaf && <RightOutlined className='text-primary' />}
        </Flex>
      ),
    };
  });

  if (selection.length > 0) {
    items.unshift({
      key: 'back',
      value: (
        <Flex className='category-select-item category-back' onClick={() => setSelection(selection.slice(0, -1))} gap={8}>
          <ArrowLeftOutlined />
          {selection.length > 1 && (
            <>
              {selection.slice(1).map((category, index) => (
                <Fragment key={`selection-${category.id}`}>
                  <p className='body2'>{category.name}</p>
                  {index !== selection.length - 2 && <RightOutlined className='category-arrow-icon' />}
                </Fragment>
              ))}
            </>
          )}
        </Flex>
      ),
    });
  }

  return (
    <CustomSelect
      listHeight={355}
      openOverride={open}
      onDropdownVisibleChange={(visible) => {
        if (visible) {
          setOpen(true);
        }
      }}
      onBlur={() => setOpen(false)}
      setOpenOverride={setOpen}
      onSelect={(val) => val.props.onClick()}
      virtual={false}
      placeholder={currentCategory ? currentCategory : 'Recherchez ou sélectionnez'}
      value={currentCategory}
      options={searchItems.length ? searchItems : items}
      searchValue={searchValue}
      onSearch={onSearch}
      showSearch
      filterOption={false}
    />
  );
}
