import Icon, { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import SearchIcon from '~/assets/svg/icon-search.svg?react';

import './SearchInput.scss';

export default function SearchInput({ placeholder, defaultValue, search }) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Input.Search
      className='ant-search-bar'
      value={value}
      placeholder={placeholder}
      enterButton={null}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      onPressEnter={() => search(value)}
      suffix={
        <Button
          type='text'
          size='large'
          className='btn-icon suffix-search-btn'
          icon={value ? <CloseCircleOutlined className='text-grey' /> : <Icon component={SearchIcon} className='text-primary' />}
          onClick={() => {
            if (!value) return;
            setValue('');
            search('');
          }}
        />
      }
    />
  );
}
