import { Cascader } from 'antd';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import Asterisk from '~/components/shared/Asterisk/Asterisk';
import Tooltip from '~/components/shared/Tooltip/Tooltip';
import { AD_DETAILS_API_IDS } from '~/helpers/adDetails';
import { HANDLE_CHANGE } from '~/store/reducers/products';

import './OldCategories.scss';

function OldCategories(props) {
  const findCategoryInTree = (categoryId, tree) => {
    let result = null;
    tree.forEach((item) => {
      if (Number(categoryId) === item.id) {
        result = item;
      } else if (!result && 'choices' in item && item.choices instanceof Array && item.choices.length > 0) {
        result = findCategoryInTree(categoryId, item.choices);
      }
    });
    return result;
  };

  const populateCategories = () => {
    const {
      allExistingCategories: categoryTree,
      /* currentlySelectedCategoryIds is an Array of category ids */
      currentlySelectedCategoryIds: selectedCategories,
    } = props;

    return selectedCategories.map((categoryId) => {
      const categoryData = findCategoryInTree(categoryId, categoryTree);
      return {
        id: categoryData.id,
        value: categoryData.value,
      };
    });
  };

  const simplifyCategory = (category) => {
    /* Simplify Category array */
    if (category instanceof Array && category.length > 0) {
      return category.map((item) => {
        return { id: item.id, value: item.value };
      });
    }
    return [];
  };

  const cascaderRef = useRef(null);
  const [currentCategory, setCurrentCategory] = useState(populateCategories);

  const changeCategoryProcess = (category) => {
    props.dispatch({
      type: HANDLE_CHANGE,
      payload: {
        input_key: AD_DETAILS_API_IDS.CATEGORIES.CATEGORIE,
        value: category,
      },
    });
    setCurrentCategory(simplifyCategory(category));

    if (props.updateAdModifiedFlag) {
      props.updateAdModifiedFlag();
    }
  };

  const onChangeCategory = (selectedCategory) => {
    if (selectedCategory === undefined && currentCategory.length) {
      changeCategoryProcess([]);
    }
    if (
      selectedCategory &&
      selectedCategory.length &&
      currentCategory &&
      currentCategory.length &&
      selectedCategory[selectedCategory.length - 1].id === currentCategory[currentCategory.length - 1].id
    ) {
      return;
    }
    if (props.usedAsCatalogueFilter) {
      props.updateSearchParameters(selectedCategory.map((cat) => cat.id));
      setCurrentCategory(simplifyCategory(selectedCategory));
    } else {
      changeCategoryProcess(selectedCategory);
    }
  };

  const formatCategoriesForANTDCascader = (categories) => {
    let optionsForANTD = [];

    categories.forEach((category) => {
      let childrenCategories = formatCategoriesForANTDCascader(category['choices']);

      optionsForANTD.push({
        id: category.id,
        label: category.value,
        value: category.value,
        children: childrenCategories.length > 0 ? childrenCategories : null,
      });
    });

    return optionsForANTD;
  };

  const getCategoriesOptionsIncludingInputValue = (inputValue, categoriesOption) => {
    let lowerCasedInputValue = inputValue.toLowerCase();
    return categoriesOption.some((category) => {
      return category.value.toLowerCase().indexOf(lowerCasedInputValue) > -1;
    });
  };

  const currentCategoriesValues = currentCategory && currentCategory.map((item) => item.value);

  return (
    <div
      className='CATEGORIE categorie_list_wrapper ad-detail-edit-block-item'
      id='CATEGORIE_EDITION'
      style={props.usedAsCatalogueFilter && { paddingLeft: '0px' }}
    >
      <div className='categorie-container'>
        <h3 className='categorie-title'>
          Catégorie
          {props.isMandatory && <Asterisk />}
          <Tooltip
            placement='right'
            title='Choisissez la sous-catégorie la plus précise possible. Si vous ne savez pas dans quelle catégorie ranger votre objet,
              vous pouvez rechercher des objets similaires pour vous inspirer ou nous contacter'
          />
        </h3>
        <div className={'appear original input-container'} ref={cascaderRef}>
          <Cascader
            expandTrigger='hover'
            changeOnSelect={props.usedAsCatalogueFilter}
            placeholder='Choisir une catégorie'
            showSearch={{ filter: getCategoriesOptionsIncludingInputValue }}
            className='original2 input-list'
            // eslint-disable-next-line
            onChange={(values, selectedOptions) => onChangeCategory(selectedOptions)}
            options={formatCategoriesForANTDCascader(props.allExistingCategories)}
            defaultValue={currentCategoriesValues}
            value={currentCategoriesValues}
            getPopupContainer={() => cascaderRef.current}
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    allExistingCategories: state.products.form_schema.components[0].components.find((c) => c.key === AD_DETAILS_API_IDS.CATEGORIES.MAIN_KEY).components[0]
      .choices,
  };
}

export default connect(mapStateToProps)(OldCategories);
