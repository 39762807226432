import { AD_DETAILS_API_IDS } from '~/helpers/adDetails';
import { isDefinedAndIsNotEmptyString } from '~/helpers/general';

function getWordingOfAiReadableAttributes(currentlySelectedAttributes) {
  const { ATTRIBUTES } = AD_DETAILS_API_IDS;
  return {
    [ATTRIBUTES.AGE]: 'Age: ' + currentlySelectedAttributes[ATTRIBUTES.AGE],
    [ATTRIBUTES.AVEC_CONGELATEUR]: currentlySelectedAttributes[ATTRIBUTES.AVEC_CONGELATEUR] ? 'Avec congélateur' : 'Sans congélateur',
    [ATTRIBUTES.AVEC_FREEZER]: currentlySelectedAttributes[ATTRIBUTES.AVEC_FREEZER] ? 'Avec freezer' : 'Sans freezer',
    [ATTRIBUTES.ANNEE]: 'Année: ' + currentlySelectedAttributes[ATTRIBUTES.ANNEE],
    [ATTRIBUTES.AUTEUR]: 'Auteur: ' + currentlySelectedAttributes[ATTRIBUTES.AUTEUR],
    [ATTRIBUTES.AUTONOMIE]: 'Autonomie: ' + currentlySelectedAttributes[ATTRIBUTES.AUTONOMIE] + ' minutes',
    [ATTRIBUTES.BATTERIE]: 'Batterie: ' + currentlySelectedAttributes[ATTRIBUTES.BATTERIE],
    [ATTRIBUTES.BLUETOOTH]: currentlySelectedAttributes[ATTRIBUTES.BLUETOOTH] ? 'Avec bluetooth' : 'Sans bluetooth',
    [ATTRIBUTES.BOOK_FORMAT]: 'Format: ' + currentlySelectedAttributes[ATTRIBUTES.BOOK_FORMAT],
    [ATTRIBUTES.CAPACITE_LAVAGE]: 'Capacité de lavage: ' + currentlySelectedAttributes[ATTRIBUTES.CAPACITE_LAVAGE] + ' kg',
    [ATTRIBUTES.CAPACITE_STOCKAGE_GO]: 'Stockage disque dur: ' + currentlySelectedAttributes[ATTRIBUTES.CAPACITE_STOCKAGE_GO] + ' Go',
    [ATTRIBUTES.CAPACITE_STOCKAGE_L]: 'Capacité: ' + currentlySelectedAttributes[ATTRIBUTES.CAPACITE_STOCKAGE_L] + ' L',
    [ATTRIBUTES.CAPTEUR_FRONTAL]: 'Capteur frontal: ' + currentlySelectedAttributes[ATTRIBUTES.CAPTEUR_FRONTAL],
    [ATTRIBUTES.CAPTEUR_PRINCIPAL]: 'Capteur principal (caméra - photo): ' + currentlySelectedAttributes[ATTRIBUTES.CAPTEUR_PRINCIPAL],
    [ATTRIBUTES.CARTE_GRAPHIQUE]: 'Carte graphique: ' + currentlySelectedAttributes[ATTRIBUTES.CARTE_GRAPHIQUE],
    [ATTRIBUTES.CHARGEMENT_SANS_FIL]: currentlySelectedAttributes[ATTRIBUTES.CHARGEMENT_SANS_FIL] ? 'Chargement sans fil' : '',
    [ATTRIBUTES.CLASSE_ENERGETIQUE]: 'Classe énergétique: ' + currentlySelectedAttributes[ATTRIBUTES.CLASSE_ENERGETIQUE],
    [ATTRIBUTES.COLLECTION]: 'Collection ' + currentlySelectedAttributes[ATTRIBUTES.COLLECTION],
    [ATTRIBUTES.CONNECTEUR]: 'Connecteur ' + currentlySelectedAttributes[ATTRIBUTES.CONNECTEUR],
    [ATTRIBUTES.CONNECTIQUE_ECRAN]: 'Connectique Écran: ' + currentlySelectedAttributes[ATTRIBUTES.CONNECTIQUE_ECRAN],
    [ATTRIBUTES.CONNECTIVITE]: 'Connectivité: ' + currentlySelectedAttributes[ATTRIBUTES.CONNECTIVITE],
    [ATTRIBUTES.CONSOMMATION_EAU]: "Consommation d'eau: " + currentlySelectedAttributes[ATTRIBUTES.CONSOMMATION_EAU] + ' L',
    [ATTRIBUTES.COULEUR]: 'Couleur: ' + currentlySelectedAttributes[ATTRIBUTES.COULEUR],
    [ATTRIBUTES.COUPE]: 'Coupe ' + currentlySelectedAttributes[ATTRIBUTES.COUPE],
    [ATTRIBUTES.CREATIONS.MAIN_KEY]: currentlySelectedAttributes[ATTRIBUTES.CREATIONS.MAIN_KEY],
    [ATTRIBUTES.DAS]: 'DAS: ' + currentlySelectedAttributes[ATTRIBUTES.DAS] + ' W/kg',
    [ATTRIBUTES.DISQUE_DUR]: 'Disque dur: ' + currentlySelectedAttributes[ATTRIBUTES.DISQUE_DUR] + ' Go',
    [ATTRIBUTES.DOUBLE_SIM]: currentlySelectedAttributes[ATTRIBUTES.DOUBLE_SIM] ? 'Double SIM' : '',
    [ATTRIBUTES.ECRAN_TACTILE]: currentlySelectedAttributes[ATTRIBUTES.ECRAN_TACTILE] ? 'Écran tactile' : '',
    [ATTRIBUTES.EDITIONS]: 'Éditions ' + currentlySelectedAttributes[ATTRIBUTES.EDITIONS],
    [ATTRIBUTES.ETAT.MAIN_KEY]: 'État: ' + currentlySelectedAttributes[ATTRIBUTES.ETAT.MAIN_KEY],
    [ATTRIBUTES.ETAT_PRECISION]: currentlySelectedAttributes[ATTRIBUTES.ETAT_PRECISION],
    [ATTRIBUTES.FINITION]: 'Finition: ' + currentlySelectedAttributes[ATTRIBUTES.FINITION],
    [ATTRIBUTES.FORMAT]: 'Format: ' + currentlySelectedAttributes[ATTRIBUTES.FORMAT],
    [ATTRIBUTES.FORMAT_CARTE_SIM]: 'Carte ' + currentlySelectedAttributes[ATTRIBUTES.FORMAT_CARTE_SIM] + ' SIM',
    [ATTRIBUTES.GARANTIE]: 'Garantie ' + currentlySelectedAttributes[ATTRIBUTES.GARANTIE],
    [ATTRIBUTES.GPS]: currentlySelectedAttributes[ATTRIBUTES.GPS] ? 'GPS' : '',
    [ATTRIBUTES.HAUTEUR]: 'Hauteur: ' + currentlySelectedAttributes[ATTRIBUTES.HAUTEUR] + ' cm',
    [ATTRIBUTES.HAUTEUR_DU_PIED]: 'Hauteur du pied: ' + currentlySelectedAttributes[ATTRIBUTES.HAUTEUR_DU_PIED] + ' cm',
    [ATTRIBUTES.LANGUE]: 'Langue: ' + currentlySelectedAttributes[ATTRIBUTES.LANGUE],
    [ATTRIBUTES.LARGEUR]: 'Largeur: ' + currentlySelectedAttributes[ATTRIBUTES.LARGEUR] + ' cm',
    [ATTRIBUTES.LASER]: 'Laser: ' + currentlySelectedAttributes[ATTRIBUTES.LASER],
    [ATTRIBUTES.LONGUEUR]: 'Longueur: ' + currentlySelectedAttributes[ATTRIBUTES.LONGUEUR] + ' cm',
    [ATTRIBUTES.MARQUE]: 'Marque: ' + currentlySelectedAttributes[ATTRIBUTES.MARQUE],
    [ATTRIBUTES.MARQUE_EDITORIALE]: 'Marque éditoriale: ' + currentlySelectedAttributes[ATTRIBUTES.MARQUE_EDITORIALE],
    [ATTRIBUTES.MARQUE_PROCESSEUR]: 'Marque processeur: ' + currentlySelectedAttributes[ATTRIBUTES.MARQUE_PROCESSEUR],
    [ATTRIBUTES.MATIERE]: 'Matière: ' + currentlySelectedAttributes[ATTRIBUTES.MATIERE],
    [ATTRIBUTES.MATIERE_CORPS]: 'Matière du corps: ' + currentlySelectedAttributes[ATTRIBUTES.MATIERE_CORPS],
    [ATTRIBUTES.MODELE]: 'Modèle: ' + currentlySelectedAttributes[ATTRIBUTES.MODELE],
    [ATTRIBUTES.MODELE_PROCESSEUR]: 'Modèle processeur: ' + currentlySelectedAttributes[ATTRIBUTES.MODELE_PROCESSEUR],
    [ATTRIBUTES.MEMOIRE_INTERNE]: 'Mémoire interne: ' + currentlySelectedAttributes[ATTRIBUTES.MEMOIRE_INTERNE],
    [ATTRIBUTES.MOTIF]: currentlySelectedAttributes[ATTRIBUTES.MOTIF],
    [ATTRIBUTES.NFC]: currentlySelectedAttributes[ATTRIBUTES.NFC] ? 'NFC' : '',
    [ATTRIBUTES.NIVEAU_SONORE]: currentlySelectedAttributes[ATTRIBUTES.NIVEAU_SONORE] + ' dB',
    [ATTRIBUTES.NIVEAUX_VITESSE]: currentlySelectedAttributes[ATTRIBUTES.NIVEAUX_VITESSE] + ' niveaux de vitesse',
    [ATTRIBUTES.NOMBRE_COEURS]: currentlySelectedAttributes[ATTRIBUTES.NOMBRE_COEURS] + ' coeurs',
    [ATTRIBUTES.NOMBRE_COUVERTS]: currentlySelectedAttributes[ATTRIBUTES.NOMBRE_COUVERTS] + ' couverts',
    [ATTRIBUTES.POINTURE]: 'Pointure ' + currentlySelectedAttributes[ATTRIBUTES.POINTURE],
    [ATTRIBUTES.PRESSION]: currentlySelectedAttributes[ATTRIBUTES.PRESSION] + ' bars',
    [ATTRIBUTES.PRIX_LITTERAIRE]: 'Prix littéraire: ' + currentlySelectedAttributes[ATTRIBUTES.PRIX_LITTERAIRE],
    [ATTRIBUTES.PROCESSEUR]: 'Processeur: ' + currentlySelectedAttributes[ATTRIBUTES.PROCESSEUR],
    [ATTRIBUTES.PROFONDEUR]: 'Profondeur: ' + currentlySelectedAttributes[ATTRIBUTES.PROFONDEUR] + ' cm',
    [ATTRIBUTES.PUISSANCE]: 'Puissance: ' + currentlySelectedAttributes[ATTRIBUTES.PUISSANCE] + ' W',
    [ATTRIBUTES.RAM]: 'Ram: ' + currentlySelectedAttributes[ATTRIBUTES.RAM],
    [ATTRIBUTES.RECHARGEABLE]: currentlySelectedAttributes[ATTRIBUTES.RECHARGEABLE] ? 'Rechargeable' : '',
    [ATTRIBUTES.RELIURE]: 'Reliure: ' + currentlySelectedAttributes[ATTRIBUTES.RELIURE],
    [ATTRIBUTES.RESEAU_MOBILE]: 'Réseau ' + currentlySelectedAttributes[ATTRIBUTES.RESEAU_MOBILE],
    [ATTRIBUTES.RESOLUTION]: 'Résolution: ' + currentlySelectedAttributes[ATTRIBUTES.RESOLUTION],
    [ATTRIBUTES.SANS_FIL]: currentlySelectedAttributes[ATTRIBUTES.SANS_FIL] ? 'Sans fil' : '',
    [ATTRIBUTES.STYLE]: 'Style: ' + currentlySelectedAttributes[ATTRIBUTES.STYLE],
    [ATTRIBUTES.SYSTEME_EXPLOITATION]: "Système d'exploitation: " + currentlySelectedAttributes[ATTRIBUTES.SYSTEME_EXPLOITATION],
    [ATTRIBUTES.TAILLE.MAIN_KEY]: 'Taille ' + currentlySelectedAttributes[ATTRIBUTES.TAILLE.MAIN_KEY],
    [ATTRIBUTES.TAILLE_ECRAN]: "Taille de l'écran: " + currentlySelectedAttributes[ATTRIBUTES.TAILLE_ECRAN],
    [ATTRIBUTES.TYPE_ASPIRATEUR]: "Type d'aspirateur: " + currentlySelectedAttributes[ATTRIBUTES.TYPE_ASPIRATEUR],
    [ATTRIBUTES.TYPE_CONGELATEUR]: 'Type de congélateur: ' + currentlySelectedAttributes[ATTRIBUTES.TYPE_CONGELATEUR],
    [ATTRIBUTES.TYPE_DOSETTES]: 'Type de dosettes: ' + currentlySelectedAttributes[ATTRIBUTES.TYPE_DOSETTES],
    [ATTRIBUTES.TYPE_FROID]: 'Type de froid: ' + currentlySelectedAttributes[ATTRIBUTES.TYPE_FROID],
    [ATTRIBUTES.TYPE_LAVE_LINGE]: 'Type de lave-linge: ' + currentlySelectedAttributes[ATTRIBUTES.TYPE_LAVE_LINGE],
    [ATTRIBUTES.TYPE_LAVE_VAISSELLE]: 'Type de lave-vaisselle: ' + currentlySelectedAttributes[ATTRIBUTES.TYPE_LAVE_VAISSELLE],
    [ATTRIBUTES.TYPE_MACHINE_CAFE]: 'Type de machine à café: ' + currentlySelectedAttributes[ATTRIBUTES.TYPE_MACHINE_CAFE],
    [ATTRIBUTES.TYPE_REFRIGERATEUR]: 'Type de réfrigérateur: ' + currentlySelectedAttributes[ATTRIBUTES.TYPE_REFRIGERATEUR],
    [ATTRIBUTES.TYPE_ROBOT]: 'Type de robot: ' + currentlySelectedAttributes[ATTRIBUTES.TYPE_ROBOT],
    [ATTRIBUTES.TYPE_STOCKAGE]: 'Stockage ' + currentlySelectedAttributes[ATTRIBUTES.TYPE_STOCKAGE],
    [ATTRIBUTES.TYPE_VENTILATEUR]: 'Ventilateur ' + currentlySelectedAttributes[ATTRIBUTES.TYPE_VENTILATEUR],
    [ATTRIBUTES.TYPE_OUVERTURE]: "Type d'ouverture: " + currentlySelectedAttributes[ATTRIBUTES.TYPE_OUVERTURE],
    [ATTRIBUTES.VITESSE_DU_PROCESSEUR]: 'Vitesse du processeur: ' + currentlySelectedAttributes[ATTRIBUTES.VITESSE_DU_PROCESSEUR] + ' GHz',
    [ATTRIBUTES.WIFI]: currentlySelectedAttributes[ATTRIBUTES.WIFI] ? 'Wifi' : '',
  };
}

export function getParametersForOpenai(attributes, title, productId) {
  const wordingOfAiReadableAttributes = getWordingOfAiReadableAttributes(attributes);
  let adParametersForAi = `adTitle=${title}`;

  for (const currentAttrKey in wordingOfAiReadableAttributes) {
    if (isDefinedAndIsNotEmptyString(attributes[currentAttrKey])) {
      adParametersForAi += ' - ' + wordingOfAiReadableAttributes[currentAttrKey];
    }
  }

  if (productId) {
    adParametersForAi += '&productId=' + productId;
  }

  return adParametersForAi;
}
