import { UpOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import './CustomSelect.scss';

function CustomSelect({ openOverride = undefined, setOpenOverride = (state) => !!state, onDropdownVisibleChange = undefined, ...props }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (openOverride !== undefined) {
      setOpen(openOverride);
    }
  }, [openOverride]);

  const onClick = () => {
    setOpen(!open);
    setOpenOverride(!open);
    if (props.onClick) {
      props.onClick();
    }
  };

  const baseOnDropdownVisibleChange = (visible) => setOpen(visible);

  return (
    <Select
      {...props}
      className={`custom-select${props.className ? ` ${props.className}` : ''}`}
      open={open}
      onDropdownVisibleChange={onDropdownVisibleChange ? onDropdownVisibleChange : baseOnDropdownVisibleChange}
      suffixIcon={
        <Button
          onClick={onClick}
          className='btn-icon select-btn-dropdown'
          size='large'
          icon={<UpOutlined className={`select-icon ${open ? 'up' : 'down'}`} />}
          type='text'
        />
      }
    />
  );
}

export default CustomSelect;
