import Icon from '@ant-design/icons';
import { App, Button, Tooltip } from 'antd';
import React, { useRef } from 'react';
import DownloadIcon from '~/assets/svg/icon-download.svg?react';

export default function DownloadFileButton({ useQuery, textConfig }) {
  const { notification } = App.useApp();
  const anchorRef = useRef(null);
  const { isLoading, isError, error, refetch } = useQuery();

  const downloadFile = async () => {
    await refetch().then((query) => {
      if (query.isSuccess && query.data) {
        const blobUrl = window.URL.createObjectURL(query.data);
        anchorRef.current.href = blobUrl;
        anchorRef.current.click();
        window.URL.revokeObjectURL(blobUrl);
      }
    });
  };

  if (isError) {
    notification.open({
      className: 'notification-dialogue-error',
      message: textConfig.errorTitle,
      description: error.message,
      placement: 'topRight',
    });
  }

  return (
    <>
      <a ref={anchorRef} className='hidden' download />

      <Tooltip placement='bottomLeft' title={textConfig.tooltip}>
        <Button type='text' className='btn-icon' size='large' icon={<Icon component={DownloadIcon} />} loading={isLoading} onClick={downloadFile} />
      </Tooltip>
    </>
  );
}
