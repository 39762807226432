import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Dropdown, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MAX_SELECTED_PRODUCTS, computeParametersNumber } from '~/helpers/catalogue';
import { loadProducts } from '~/store/reducers/products/actions';

import './BulkItemsUpdater.scss';

function optPlur(count) {
  if (count > 1) {
    return 's';
  }
  return '';
}

function areNextProductPagesLoaded(productsList, currentPage, currentLimit) {
  for (let index = 1; index < MAX_SELECTED_PRODUCTS / currentLimit; index++) {
    const nextPage = currentPage + index;
    if (productsList[nextPage] === undefined) {
      return false;
    }
  }
  return true;
}

function getSelectionDescription(selectedProductIds, currentProducts, totalItems, searchActivated) {
  const lenSelectedProducts = selectedProductIds.length;
  if (lenSelectedProducts === 0) {
    const currentTotal = currentProducts.length;
    if (searchActivated) {
      return (
        <>
          {totalItems} annonce{optPlur(totalItems)} correspondante{optPlur(totalItems)}
        </>
      );
    }
    return (
      <>
        {currentTotal} annonce{optPlur(currentTotal)} sur {totalItems}
      </>
    );
  }
  const selectionDescription = (
    <>
      {lenSelectedProducts} annonce{optPlur(lenSelectedProducts)} sélectionnée{optPlur(lenSelectedProducts)}
    </>
  );

  if (lenSelectedProducts > MAX_SELECTED_PRODUCTS) {
    return (
      <>
        <Tag color='warning'>Attention !</Tag>
        <br />
        Le maximum d'annonces à modifié est de {MAX_SELECTED_PRODUCTS}.
        <br />
        {selectionDescription}
      </>
    );
  }
  return selectionDescription;
}

function BulkItemsUpdater({ dispatch, searchParams, selectedProductIds, updateSelectedProductIds, currentPage, currentLimit, productsList, totalItems }) {
  const [isLoading, setIsLoading] = useState(false);
  const currentProducts = productsList[currentPage] ? productsList[currentPage] : [];

  useEffect(() => {
    if (isLoading && areNextProductPagesLoaded(productsList, currentPage, currentLimit)) {
      let productsToSelect = [];
      for (let index = 0; index < MAX_SELECTED_PRODUCTS / currentLimit; index++) {
        const nextPage = currentPage + index;
        productsToSelect = productsToSelect.concat(productsList[nextPage].map((product) => product.product_id));
      }
      updateSelectedProductIds(selectedProductIds.concat(productsToSelect));
      setIsLoading(false);
    }
  }, [isLoading, productsList]);

  const bulkItemsActions = {
    'unselect-all': () => {
      if (selectedProductIds.length > 0) {
        updateSelectedProductIds([]);
      }
    },
    'select-page': () => {
      const currentProductsIds = currentProducts.map((product) => product.product_id);
      if (currentProductsIds.every((productId) => selectedProductIds.includes(productId))) {
        updateSelectedProductIds(selectedProductIds.filter((productId) => !currentProductsIds.includes(productId)));
      } else {
        updateSelectedProductIds(selectedProductIds.concat(currentProductsIds));
      }
    },
    'select-max': () => {
      if (!areNextProductPagesLoaded(productsList, currentPage, currentLimit)) {
        for (let index = 1; index < MAX_SELECTED_PRODUCTS / currentLimit; index++) {
          const nextPage = currentPage + index;
          dispatch(loadProducts({ ...searchParams, page: nextPage }));
        }
      }
      setIsLoading(true);
    },
  };

  const dropdownItems = [
    {
      key: 'select-page',
      title: 'Sélectionner les annonces de cette page',
      label: <p className='body1'>Sélectionner les annonces de cette page</p>,
    },
  ];

  if (selectedProductIds.length === 0 && currentPage * currentLimit < totalItems) {
    const remainingItemsIncludingCurrentPage = totalItems - currentPage * currentLimit + currentLimit;
    const maxSelectableProducts = Math.min(remainingItemsIncludingCurrentPage, MAX_SELECTED_PRODUCTS);
    dropdownItems.push({
      key: 'select-max',
      title: `Sélectionner les ${maxSelectableProducts} annonces suivantes`,
      label: <p className='body1'>Sélectionner les {maxSelectableProducts} annonces suivantes</p>,
    });
  } else {
    dropdownItems.unshift({
      key: 'unselect-all',
      title: 'Désélectionner tout',
      label: <p className='body1'>Désélectionner tout</p>,
    });
  }

  const onDropdownItemClick = ({ key }) => {
    bulkItemsActions[key]();
  };

  return (
    <Dropdown
      className='bulk-items-updater-dropdown'
      trigger={['click']}
      menu={{ items: dropdownItems, onClick: onDropdownItemClick }}
      disabled={isLoading ? true : null}
    >
      <Space>
        <div className='number-selected-wrapper'>
          <p className='body1'>{getSelectionDescription(selectedProductIds, currentProducts, totalItems, computeParametersNumber(searchParams) !== 0)}</p>
        </div>
        {!isLoading ? <DownOutlined className='flip-on-dropdown text-primary' /> : <LoadingOutlined className='text-primary' />}
      </Space>
    </Dropdown>
  );
}

export default connect()(BulkItemsUpdater);
