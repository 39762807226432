import { getFavoriteMerchantId } from '~/helpers/cookies';

const initialState = {
  active_merchant: null,
  user: {
    display_name: null,
    email: null,
    first_name: null,
    id: null,
    merchants: [],
  },
  toast: {
    text: '',
    type: '',
    sent_at: null,
  },
};

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';
export const ACTIVE_MERCHANT_UPDATE = 'ACTIVE_MERCHANT_UPDATE';

export default (state = initialState, action) => {
  const favoriteMerchantId = getFavoriteMerchantId();

  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        active_merchant: action.user.merchants.find((merchant) => merchant.id === favoriteMerchantId) || action.user.merchants[0],
        user: action.user,
      };

    case USER_LOGOUT:
      return {
        ...state,
        active_merchant: null,
        user: initialState.user,
      };

    case ACTIVE_MERCHANT_UPDATE:
      return {
        ...state,
        active_merchant: state.user.merchants.find((merchant) => merchant.id === action.payload.merchant_id),
      };
    default:
      return state;
  }
};
