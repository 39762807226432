import { useQuery } from '@tanstack/react-query';
import { api } from '~/services/api';

export const useGetCategoryAttributes = (category) => {
  return useQuery({
    queryKey: ['attributes', category],
    queryFn: () =>
      api.get(`/category/${category}/attributes/`).then((res) => {
        if (res.success && res.data.components && res.data.components.components) {
          return res.data.components.components;
        }
        if (typeof res.message?.error === 'string') {
          throw new Error(res.message.error);
        }
        throw new Error(res.message.error.message);
      }),
  });
};
