import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import React, { useRef } from 'react';
import FilerobotImageEditor, { TABS } from 'react-filerobot-image-editor';
import { getImageUrl } from '~/helpers/general';
import colors from '~/styles/colors.module.scss';

import './ImageEditor.scss';
import translations from './translations';

function ImageEditor({ image, saveImage, loading, goPrevious, goNext }) {
  const editorRef = useRef(null);

  const imageUrl = getImageUrl(image.url, null, null);

  return (
    <>
      <Flex vertical align='center'>
        <p className='cap2'>Image n.{image.order}</p>
        <div className='image-editor'>
          <FilerobotImageEditor
            source={imageUrl}
            theme={{
              palette: {
                'bg-primary-active': colors.primaryLight,
                'accent-stateless': colors.primaryDefault,
                'accent-primary': colors.primaryDefault,
                'accent-primary-hover': colors.primaryMedium,
                'accent-primary-active': colors.primaryDefault,
                'icon-primary': colors.primaryDefault,
              },
            }}
            language='fr'
            translations={translations}
            useBackendTranslations={false}
            avoidChangesNotSavedAlertOnLeave
            observePluginContainerSize
            savingPixelRatio={0}
            previewPixelRatio={0}
            disableSaveIfNoChanges
            getCurrentImgDataFnRef={editorRef}
            tabsIds={[TABS.ADJUST, TABS.FINETUNE, TABS.ANNOTATE]}
            Crop={{
              ratio: 1,
              ratioTitleKey: 'Carré',
              presetsItems: [{ titleKey: 'Carré', ratio: 1 }],
            }}
          />
        </div>
        <Button
          type='primary'
          loading={loading}
          onClick={() => {
            const { imageData } = editorRef.current();
            imageData.imageCanvas.toBlob((blob) => {
              const newImage = new File([blob], imageData.fullName, {
                lastModified: new Date().getTime(),
                type: imageData.mimeType,
              });
              saveImage(image, newImage);
            }, 'image/jpeg');
          }}
        >
          Enregister
        </Button>
      </Flex>
      <Button id='nav-prev' className='btn-icon btn-editor-nav' size='large' type='link' icon={<LeftOutlined />} onClick={goPrevious} />
      <Button id='nav-next' className='btn-icon btn-editor-nav' size='large' type='link' icon={<RightOutlined />} onClick={goNext} />
    </>
  );
}

export default ImageEditor;
