import { ArrowLeftOutlined } from '@ant-design/icons';
import { Affix, Button, Flex } from 'antd';
import React, { useEffect, useState } from 'react';
import SeeOnlineProductButton from '~/components/shared/SeeOnlineProductButton/SeeOnlineProductButton';

import './FocusBar.scss';

function FocusBar({ pageTitleText, goBack, productUrl }) {
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    let lastScrollPosition = window.scrollY;
    window.onscroll = () => {
      if (lastScrollPosition > window.scrollY) {
        setVisible(true);
      } else {
        setVisible(false);
      }
      lastScrollPosition = window.scrollY;
    };
  }, []);
  return (
    <Affix offsetTop={0} rootClassName={`top-bar-affix ${visible ? 'show' : 'hide'}`}>
      <Flex justify='space-between' align='center' className='ad-details-title-focus-wrapper'>
        <Flex gap={8} align='center'>
          <Button type='text' size='large' className='btn-icon' icon={<ArrowLeftOutlined />} onClick={goBack} />
          <p className='sub1'>{pageTitleText}</p>
        </Flex>
        {productUrl && <SeeOnlineProductButton productUrl={productUrl} />}
      </Flex>
    </Affix>
  );
}

export default FocusBar;
