export const SEARCH_PARAMETERS_API_IDS = {
  CREATION_DATE_MIN: 'created_on__gte',
  CREATION_DATE_MAX: 'created_on__lte',
  MODIFICATION_DATE_MIN: 'last_modified__gte',
  MODIFICATION_DATE_MAX: 'last_modified__lte',
  ORDER_ID_NUMBER: 'order__id_number',
  USER_EMAIL: 'user__email',
  FIRST_NAME: 'user__first_name',
  LAST_NAME: 'user__last_name',
  ITEM_SKU_EXACT: 'items__sku',
  ITEM_NAME: 'items__name',
  STATUS: 'status',
  INCIDENT_STATUS: 'incident_status__in',
  LIMIT: 'limit',
  PAGE: 'page',
};

export const SORT_API_IDS = {
  DEFAULT: 1,
  ORDER_ID_NUMBER: 2,
  SHIPPING_OPTIONS: 3,
};

export const STATUS_API_IDS = {
  AUTHORIZED: '60',
  CONFIRMED: '80',
  PROCESSED: '85',
};

export const INCIDENT_STATUS_API_IDS = {
  PENDING_MERCHANT: 'pending_merchant',
  PENDING_CUSTOMER: 'pending_customer',
  PROCESSING: 'processing',
  NO_INCIDENT: 'no_incident',
};

export const OPEN_INCIDENT_STATUS = [
  INCIDENT_STATUS_API_IDS.PENDING_MERCHANT,
  INCIDENT_STATUS_API_IDS.PENDING_CUSTOMER,
  INCIDENT_STATUS_API_IDS.PROCESSING,
].join(',');

export const INCIDENT_STATUS_OPEN_API_ID = '1';

export const defaultParams = {
  [SEARCH_PARAMETERS_API_IDS.STATUS]: STATUS_API_IDS.AUTHORIZED,
};

export const colorStatusMapping = {
  Initial: 'processing',
  'Paiement Autorisé': 'warning',
  Confirmé: 'processing',
  Traité: 'success',
  Annulé: 'error',
  Finalisé: 'success',
  'Retour en cours': 'warning',

  // Old statuses
  Envoyé: 'grey',
  Expirée: 'error',
  Payé: 'grey',
  Reçu: 'processing',
  Retournée: 'processing',
  'Partiellement remboursée': 'success',
};
