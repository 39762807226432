import React from 'react';

import './FormItemPanel.scss';

function FormItemPanel({ id = null, children, classNameWrapper = null }) {
  return (
    <div id={id} className={`form-item-panel-wrapper${classNameWrapper ? ` ${classNameWrapper}` : ''}`}>
      {children}
    </div>
  );
}

export default FormItemPanel;
