import { Button } from 'antd';
import React from 'react';

export default function SubmitButton({ loading, children }) {
  return (
    <>
      <Button type='primary' htmlType='submit' loading={loading} disabled={loading} className='submit-btn'>
        {children}
      </Button>
    </>
  );
}
