import Icon from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useSetAtom } from 'jotai';
import React from 'react';
import { Link } from 'react-router-dom';
import CustomAddIcon from '~/assets/svg/icon-add.svg?react';
import { collapsedWithCookieAtom } from '~/atoms';
import { RoutingUrl } from '~/helpers/general';

export default function CreateAdButton({ className = '', onClick = null, iconMode = false }) {
  const setCollapsed = useSetAtom(collapsedWithCookieAtom);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    setCollapsed(true);
  };

  return (
    <>
      <Link
        className='text-center'
        to={{
          pathname: RoutingUrl.adCreation(),
          state: {
            createAd: true,
          },
        }}
      >
        <Tooltip title={iconMode ? 'Créer une annonce' : ''} placement='right'>
          <Button
            type='primary'
            size={iconMode ? 'large' : null}
            block
            className={`${className}${iconMode ? ' btn-icon' : ''}`}
            onClick={handleClick}
            icon={<Icon component={CustomAddIcon} />}
          >
            {!iconMode && <>Créer une annonce</>}
          </Button>
        </Tooltip>
      </Link>
    </>
  );
}
