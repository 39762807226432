import { Col, Layout } from 'antd';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from '~/components/screens/Authentication/Login/Login';
import PasswordResetRequest from '~/components/screens/Authentication/PasswordResetRequest/PasswordResetRequest';
import ResetPassword from '~/components/screens/Authentication/ResetPassword/ResetPassword';
import LayoutRow from '~/components/shared/LayoutRow/LayoutRow';
import { RoutingUrl } from '~/helpers/general';

import './LoginNavigator.scss';

const { Content } = Layout;

function Redirections() {
  return (
    <>
      <Route path='*'>
        <Redirect to={RoutingUrl.login()} />
      </Route>
    </>
  );
}

export default function LoginNavigator() {
  return (
    <>
      <Layout className='layout-login-navigator-block'>
        <Content className='content-login-wrapper'>
          <LayoutRow align='middle'>
            <Col xs={24} md={{ span: 18, offset: 3 }} xl={{ span: 12, offset: 6 }}>
              <div className='panel panel-login'>
                <Switch>
                  <Route path={RoutingUrl.login()} component={Login} />
                  <Route path={RoutingUrl.passwordResetRequest()} component={PasswordResetRequest} />
                  <Route path={RoutingUrl.passwordReset([':token1', ':token2'])} component={ResetPassword} />
                  <Redirections />
                </Switch>
              </div>
            </Col>
          </LayoutRow>
        </Content>
      </Layout>
    </>
  );
}
