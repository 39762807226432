import Icon, { ArrowLeftOutlined, RightOutlined } from '@ant-design/icons';
import { Drawer, Flex } from 'antd';
import React, { Fragment, useState } from 'react';
import categoryIdToIconMapper from '~/components/shared/CategoryIcon/CategoryIcon';
import CustomSelect from '~/components/shared/CustomSelect/CustomSelect';

import { getChoicesForSelection } from '../helpers';
import './CategorySelectionDrawer.scss';

function createSelectionHeader(selection) {
  return (
    <Flex gap={8} align='center'>
      <Icon className='category-icon' component={categoryIdToIconMapper(selection[0].id)} />
      {selection.length > 0 &&
        selection.map((parent, index) => (
          <Fragment key={parent.id}>
            <p className='body1'>{parent.name}</p>
            {index !== selection.length - 1 && <RightOutlined className='category-arrow-icon' />}
          </Fragment>
        ))}
    </Flex>
  );
}

export default function CategorySelectionDrawer({ changeCategory, categories, selectedCategories }) {
  const [selection, setSelection] = useState([]);
  const currentCategory = selectedCategories && selectedCategories.length > 0 ? selectedCategories[selectedCategories.length - 1].value : null;

  const choices = getChoicesForSelection(categories, selection);

  const items = categories.map((category) => ({
    key: category.id,
    value: (
      <Flex
        justify='space-between'
        align='center'
        className='category-select-item'
        onClick={() => setSelection([{ id: category.id, name: category.value, leaf: false }])}
      >
        <Flex gap={8} align='center'>
          <Icon className='category-icon' component={categoryIdToIconMapper(category.id)} />
          <p className='body1'>{category.value}</p>
        </Flex>
        <RightOutlined className='text-primary' />
      </Flex>
    ),
  }));

  const drawerItems = choices.map((category) => (
    <Flex
      key={category.id}
      justify='space-between'
      align='center'
      className='category-drawer-item'
      onClick={() => {
        if (category.leaf) {
          changeCategory(
            [...selection, category].map((c) => ({ id: c.id, value: c.name })),
            () => {
              setSelection([]);
            }
          );
        } else {
          setSelection([...selection, category]);
        }
      }}
    >
      <p className='body1'>{category.name}</p>
      {!category.leaf && <RightOutlined className='text-primary' />}
    </Flex>
  ));

  return (
    <>
      <CustomSelect
        virtual={false}
        onSelect={(val) => val.props.onClick()}
        placeholder={currentCategory ? currentCategory : 'Sélectionnez une catégorie'}
        value={currentCategory}
        options={items}
        listHeight={355}
      />
      {selection.length > 0 && (
        <Drawer
          className='category-drawer'
          open
          extra={
            <Flex className='category-drawer-header' align='center' gap={16} onClick={() => setSelection(selection.slice(0, -1))}>
              <ArrowLeftOutlined />
              <p className='body1'>Retour{selection.length === 1 && " à la création d'annonces"}</p>
            </Flex>
          }
        >
          <Flex className='category-drawer-body' gap={24} vertical>
            {createSelectionHeader(selection)}
            <Flex vertical>{drawerItems}</Flex>
          </Flex>
        </Drawer>
      )}
    </>
  );
}
