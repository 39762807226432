import { Badge, Button, Col, DatePicker, Drawer, Form, Input, Radio, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { connect } from 'react-redux';
import { OldCategories } from '~/components/screens/AdDetails/CreationEditionForm/Fieldsets';
import { AD_STATUS } from '~/helpers/adDetails';
import { INITIAL_SEARCH, SEARCH_PARAMETERS_API_IDS, computeParametersNumber, getCleanedSearchParamsFromURL, isSleepingAd } from '~/helpers/catalogue';
import { API_DATE_FORMAT, RoutingUrl, USER_DATE_FORMAT, appendParamsToEndpoint, isDefinedAndIsNotEmptyString } from '~/helpers/general';

import { SearchFiltersDeleter } from '../SearchFiltersDeleter/SearchFiltersDeleter';
import './AdvancedSearch.scss';

const { RangePicker } = DatePicker;
const { Item: FormItem } = Form;
const { Group: RadioGroup } = Radio;
const { Button: RadioButton } = Radio;
const GUTTER_SIZE = 16;

let old_url = window.location.pathname + window.location.search;
let new_url = null;

let event = new Date();
event.setMonth(event.getMonth() - 6);
event = event.toISOString('fr-FR');
event = event.slice(0, 10);

class AdvancedSearch extends Component {
  state = {
    drawer_visible: false,
    skuSearch: '',
    searchParams: this.props.searchParams,
    reload: false,
  };

  componentDidMount() {
    this.setState(this.props);
    this.searchedParametersNumber = computeParametersNumber(this.state.searchParams);
  }

  rebootResearch = () => {
    this.launchResearch(true);
  };

  keyboardEnterResearchLauncher = (e) => {
    if (e.keyCode === 13) {
      this.launchResearchWithoutReboot();
    }
  };

  launchResearchWithoutReboot = () => {
    this.launchResearch(false);
  };

  launchResearch = (reboot) => {
    let { searchParams } = this.state;
    if (reboot) {
      searchParams = INITIAL_SEARCH();
    }
    let finalSearchParams = { ...searchParams };
    finalSearchParams[SEARCH_PARAMETERS_API_IDS.CATEGORIES] = finalSearchParams[SEARCH_PARAMETERS_API_IDS.CATEGORIES].join(',');

    if (!isSleepingAd(searchParams) && searchParams[SEARCH_PARAMETERS_API_IDS.STATUS].includes(AD_STATUS.SLEEPING.API_ID)) {
      finalSearchParams[SEARCH_PARAMETERS_API_IDS.STATUS] = finalSearchParams[SEARCH_PARAMETERS_API_IDS.STATUS].filter(
        (statut) => statut !== AD_STATUS.SLEEPING.API_ID
      );
    }

    this._setDrawerVisible(false);

    this.props.history.push(appendParamsToEndpoint(RoutingUrl.catalogue(), finalSearchParams));
  };

  _setDrawerVisible = (drawer_visible) => {
    this.setState({ drawer_visible });
  };

  castApiDateToUserFormatDate = (dateInApiFormat) => {
    if (isDefinedAndIsNotEmptyString(dateInApiFormat)) {
      return dayjs(dayjs(dateInApiFormat, API_DATE_FORMAT).format(USER_DATE_FORMAT), USER_DATE_FORMAT);
    }
    return null;
  };

  updateSearchParameters = (queryParamName, value) => {
    let { QUERY, SKU, LOCATION, STATUS, CREATION_DATE_MIN, CREATION_DATE_MAX, MODIFICATION_DATE_MIN, MODIFICATION_DATE_MAX } = SEARCH_PARAMETERS_API_IDS;

    switch (queryParamName) {
      case QUERY:
      case SKU:
      case LOCATION:
        this.setState((prevState) => {
          let newValueIsEqualToOldValue = prevState.searchParams[queryParamName] === value;
          let newSkuQandEmplacement = {
            [QUERY]: '',
            [SKU]: '',
            [LOCATION]: '',
          };

          if (!newValueIsEqualToOldValue) {
            newSkuQandEmplacement[queryParamName] = value;
          }

          return {
            ...prevState,
            searchParams: {
              ...prevState.searchParams,
              [QUERY]: newSkuQandEmplacement[QUERY],
              [SKU]: newSkuQandEmplacement[SKU],
              [LOCATION]: newSkuQandEmplacement[LOCATION],
            },
          };
        });
        break;

      case STATUS:
        this.setState((prevState) => {
          let newStatusArray = [];

          if (value) {
            newStatusArray = prevState.searchParams[queryParamName];
            if (!this.state.searchParams[queryParamName].includes(value)) {
              newStatusArray.push(value);
            } else {
              // La selection d'un statut deja existant le supprime de la liste
              newStatusArray = newStatusArray.filter((statut) => statut !== value);
            }
          }

          return {
            ...prevState,
            searchParams: {
              ...prevState.searchParams,
              [STATUS]: newStatusArray,
            },
          };
        });
        break;

      case CREATION_DATE_MIN:
      case MODIFICATION_DATE_MIN: {
        let paramMinToUpdate = CREATION_DATE_MIN,
          paramMaxToUpdate = CREATION_DATE_MAX;

        if (queryParamName === MODIFICATION_DATE_MIN) {
          paramMinToUpdate = MODIFICATION_DATE_MIN;
          paramMaxToUpdate = MODIFICATION_DATE_MAX;
        }

        this.setState((prevState) => {
          return {
            ...prevState,
            searchParams: {
              ...prevState.searchParams,
              [paramMinToUpdate]: isDefinedAndIsNotEmptyString(value[0]) ? dayjs(value[0], USER_DATE_FORMAT).format(API_DATE_FORMAT) : '',
              [paramMaxToUpdate]: isDefinedAndIsNotEmptyString(value[1])
                ? dayjs(value[1] + 'T23:59:59', USER_DATE_FORMAT + 'Thh:mm:ss').format(API_DATE_FORMAT)
                : '',
            },
          };
        });
        break;
      }
      default:
        this.setState((prevState) => {
          return {
            ...prevState,
            searchParams: {
              ...prevState.searchParams,
              [queryParamName]: prevState.searchParams[queryParamName] !== value ? value : '',
            },
          };
        });
        break;
    }
  };

  componentDidUpdate() {
    new_url = window.location.pathname + window.location.search;

    if (old_url !== new_url) {
      this.setState({
        searchParams: getCleanedSearchParamsFromURL(this.state.searchParams),
      });
      old_url = new_url;
    }

    this.searchedParametersNumber = computeParametersNumber(this.props.searchParams);
  }

  searchedParametersNumber = computeParametersNumber(getCleanedSearchParamsFromURL(this.state.searchParams));

  render() {
    let { drawer_visible, searchParams } = this.state;
    let searchedParametersNumber = this.searchedParametersNumber;
    let {
      QUERY,
      SKU,
      LOCATION,
      STATUS,
      CREATION_DATE_MIN,
      CREATION_DATE_MAX,
      MODIFICATION_DATE_MIN,
      MODIFICATION_DATE_MAX,
      IS_IN_STOCK,
      IS_LABEL_AFFAIRE,
      PRICE_MIN,
      PRICE_MAX,
      CATEGORIES,
    } = SEARCH_PARAMETERS_API_IDS;

    return (
      <>
        <Badge count={searchedParametersNumber}>
          <Button onClick={() => this._setDrawerVisible(true)}>Voir tous les filtres</Button>
        </Badge>
        <Drawer placement='right' closable={true} onClose={() => this._setDrawerVisible(false)} open={drawer_visible}>
          <Form className='search_drawer_container__form' layout='vertical' hideRequiredMark onKeyDown={this.keyboardEnterResearchLauncher}>
            <FormItem label='Rechercher une annonce par titre'>
              <Input
                value={searchParams[QUERY]}
                placeholder='Rechercher une annonce par titre'
                onChange={(event) => this.updateSearchParameters(QUERY, event.target.value)}
                allowClear
              />
            </FormItem>
            <Typography level={5} style={{ marginTop: '-25px' }}>
              Ou
            </Typography>
            <FormItem label='Rechercher une annonce par SKU'>
              <Input
                value={searchParams[SKU]}
                onChange={(event) => this.updateSearchParameters(SKU, event.target.value)}
                placeholder='Rechercher une annonce par SKU'
                allowClear
              />
            </FormItem>
            <Typography level={5} style={{ marginTop: '-25px' }}>
              Ou
            </Typography>
            <FormItem label='Rechercher une annonce par Emplacement'>
              <Input
                value={searchParams[LOCATION]}
                placeholder='Rechercher une annonce par Emplacement'
                onChange={(event) => this.updateSearchParameters(LOCATION, event.target.value)}
                allowClear
              />
            </FormItem>
            <Row gutter={GUTTER_SIZE}>
              <Col xs={24} lg={12} style={{ position: 'relative' }}>
                <FormItem label={<span>Date de création</span>}>
                  <RangePicker
                    allowEmpty={[true, true]}
                    value={[
                      this.castApiDateToUserFormatDate(searchParams[CREATION_DATE_MIN]),
                      this.castApiDateToUserFormatDate(searchParams[CREATION_DATE_MAX]),
                    ]}
                    placeholder={['Création au plus tôt', 'Création au plus tard']}
                    format={USER_DATE_FORMAT}
                    onChange={(arrayOfDayjsObject, arrayOfFormattedStrings) => this.updateSearchParameters(CREATION_DATE_MIN, arrayOfFormattedStrings)}
                    role='textbox'
                  />
                </FormItem>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem label={<span>Date de modification</span>}>
                  <RangePicker
                    allowEmpty={[true, true]}
                    value={[
                      this.castApiDateToUserFormatDate(searchParams[MODIFICATION_DATE_MIN]),
                      this.castApiDateToUserFormatDate(searchParams[MODIFICATION_DATE_MAX]),
                    ]}
                    placeholder={['Modification au plus tôt', 'Modification au plus tard']}
                    format={USER_DATE_FORMAT}
                    onChange={(arrayOfDayjsObject, arrayOfFormattedStrings) => this.updateSearchParameters(MODIFICATION_DATE_MIN, arrayOfFormattedStrings)}
                    role='textbox'
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={GUTTER_SIZE}>
              <Col xs={24}>
                <FormItem label='Statut'>
                  <RadioGroup value={true}>
                    <RadioButton
                      value={searchParams[STATUS] && searchParams[STATUS].includes(AD_STATUS.ACTIVE.API_ID)}
                      onClick={(event) => {
                        this.updateSearchParameters(STATUS, AD_STATUS.ACTIVE.API_ID);
                        event.stopPropagation();
                      }}
                    >
                      Active
                    </RadioButton>
                    <RadioButton
                      value={searchParams[STATUS] && searchParams[STATUS].includes(AD_STATUS.DRAFT.API_ID)}
                      onClick={(event) => {
                        this.updateSearchParameters(STATUS, AD_STATUS.DRAFT.API_ID);
                        event.stopPropagation();
                      }}
                    >
                      En brouillon
                    </RadioButton>
                    <RadioButton
                      value={searchParams[STATUS] && searchParams[STATUS].includes(AD_STATUS.INACTIVE.API_ID)}
                      onClick={(event) => {
                        this.updateSearchParameters(STATUS, AD_STATUS.INACTIVE.API_ID);
                        event.stopPropagation();
                      }}
                    >
                      Supprimée
                    </RadioButton>
                    {searchParams[STATUS] &&
                      searchParams[STATUS].length > 0 &&
                      !(searchParams[STATUS].includes(AD_STATUS.SLEEPING.API_ID) && searchParams[STATUS].length === 1) && (
                        <RadioButton
                          value={undefined}
                          onClick={(event) => {
                            this.updateSearchParameters(STATUS, false);
                            event.stopPropagation();
                          }}
                        >
                          <IoIosCloseCircleOutline className='search_drawer_container__radio_button' />
                        </RadioButton>
                      )}
                  </RadioGroup>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={GUTTER_SIZE}>
              <Col xs={12}>
                <FormItem label='En stock'>
                  <RadioGroup value={searchParams[IS_IN_STOCK]} onChange={(event) => this.updateSearchParameters(IS_IN_STOCK, event.target.value)}>
                    <RadioButton value='1'>Oui</RadioButton>
                    <RadioButton value='0'>Non</RadioButton>
                    {isDefinedAndIsNotEmptyString(searchParams[IS_IN_STOCK]) && (
                      <RadioButton value={undefined}>
                        <IoIosCloseCircleOutline className='search_drawer_container__radio_button' />
                      </RadioButton>
                    )}
                  </RadioGroup>
                </FormItem>
              </Col>
              <Col xs={12}>
                <FormItem label='Bon plan (Label Affaire)'>
                  <RadioGroup value={searchParams[IS_LABEL_AFFAIRE]} onChange={(event) => this.updateSearchParameters(IS_LABEL_AFFAIRE, event.target.value)}>
                    <RadioButton value='1'>Oui</RadioButton>
                    <RadioButton value='-1'>Non</RadioButton>
                    {searchParams[IS_LABEL_AFFAIRE] != null && searchParams[IS_LABEL_AFFAIRE] !== '' && (
                      <RadioButton value={undefined}>
                        <IoIosCloseCircleOutline className='search_drawer_container__radio_button' />
                      </RadioButton>
                    )}
                  </RadioGroup>
                </FormItem>
              </Col>
            </Row>
            <FormItem label='Tranche de prix'>
              <Row gutter={GUTTER_SIZE}>
                <Col xs={12}>
                  <Input
                    onChange={(event) => this.updateSearchParameters(PRICE_MIN, event.target.value)}
                    type='number'
                    placeholder='De'
                    value={searchParams[PRICE_MIN]}
                    allowClear
                  />
                </Col>
                <Col xs={12}>
                  <Input
                    onChange={(event) => this.updateSearchParameters(PRICE_MAX, event.target.value)}
                    type='number'
                    placeholder='A'
                    value={searchParams[PRICE_MAX]}
                    allowClear
                  />
                </Col>
              </Row>
            </FormItem>
            <FormItem>
              <Row gutter={GUTTER_SIZE}>
                <Col>
                  <OldCategories
                    updateSearchParameters={(values) => this.updateSearchParameters(CATEGORIES, values)}
                    usedAsCatalogueFilter
                    currentlySelectedCategoryIds={searchParams[CATEGORIES]}
                  />
                </Col>
              </Row>
            </FormItem>
            <Row gutter={GUTTER_SIZE}>
              <Col xs={5}>
                <Button onClick={() => this._setDrawerVisible(false)}>Fermer</Button>
              </Col>
              <Col xs={7}>
                <SearchFiltersDeleter
                  history={this.props.history}
                  searchParams={this.props.searchParams}
                  onEraseFilters={() => {
                    this._setDrawerVisible(false);
                  }}
                />
              </Col>
              <Col xs={12}>
                <Button
                  className='search_drawer_container__search_button'
                  onClick={this.launchResearchWithoutReboot}
                  type='primary'
                  style={{ position: 'absolute', right: 8 }}
                >
                  Lancer la recherche
                </Button>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    );
  }
}

function mapStateToProps(state) {
  let {
    session: { active_merchant },
    products: { product_list },
  } = state;

  return {
    merchant_id: active_merchant.id,
    product_list,
  };
}
export default connect(mapStateToProps)(AdvancedSearch);
