import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip as ANTDesignTooltip } from 'antd';
import React from 'react';

import './Tooltip.scss';

function Tooltip(props) {
  let triggerAction = 'hover';
  if (window.screen.width < 992) {
    triggerAction = 'click';
  }
  return (
    <ANTDesignTooltip {...props} trigger={triggerAction}>
      <QuestionCircleOutlined className='tooltip_icon' />
    </ANTDesignTooltip>
  );
}

export default Tooltip;
