import { Flex, Tag } from 'antd';
import React from 'react';

import './TabsGroup.scss';

export default function TabsGroup({ items }) {
  return (
    <>
      <Flex gap={40} className='tabs-group'>
        {items.map((item, index) => (
          <Flex key={`tab-item-${index}`} gap={12} align='center' className={`tab-item${item.active ? ' active' : ''}`} onClick={item.onClick}>
            <p className='sub1'>{item.label}</p>
            {item.count && (
              <Tag className='round' color={item.count.color}>
                {item.count.value}
              </Tag>
            )}
          </Flex>
        ))}
      </Flex>
    </>
  );
}
