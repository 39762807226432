import { AD_STATUS } from './adDetails/status';
import { isDefinedAndIsNotEmptyString, valueIfNotNullElseEmptyString } from './general';

const SORT_FACETS_API_IDS = {
  PRICE: {
    DEFAULT: '3',
    REVERSED: '2',
  },
  STOCK: {
    DEFAULT: '4',
    REVERSED: '5',
  },
  STATUS: {
    DEFAULT: '10',
    REVERSED: '11',
  },
  CREATION_DATE: {
    DEFAULT: '1',
    REVERSED: '7',
  },
  MODIFICATION_DATE: {
    DEFAULT: '0',
    REVERSED: '6',
  },
};

const SORT_ARROWS = {
  ANTD_DIRECTION_TYPE: {
    UP: 'up',
    DOWN: 'down',
  },
  COLORS: {
    DEFAULT: '',
    SELECTED: 'text-primary',
  },
};

export const SEARCH_PARAMETERS_API_IDS = {
  QUERY: 'q',
  SKU: 'sku',
  LOCATION: 'emplacement',
  CREATION_DATE_MIN: 'date_created_min',
  CREATION_DATE_MAX: 'date_created_max',
  MODIFICATION_DATE_MIN: 'date_modified_min',
  MODIFICATION_DATE_MAX: 'date_modified_max',
  STATUS: 'status',
  IS_IN_STOCK: 'stock',
  IS_LABEL_AFFAIRE: 'affaire',
  PRICE_MIN: 'prix_min',
  PRICE_MAX: 'prix_max',
  CATEGORIES: 'categoryIds',
  LIMIT: 'limit',
  PAGE: 'page',
  SORT: 'tri',
};

const newInitialSearchObject = () =>
  Object.assign(
    {},
    {
      [SEARCH_PARAMETERS_API_IDS.QUERY]: '',
      [SEARCH_PARAMETERS_API_IDS.SKU]: '',
      [SEARCH_PARAMETERS_API_IDS.LOCATION]: '',
      [SEARCH_PARAMETERS_API_IDS.CREATION_DATE_MIN]: '',
      [SEARCH_PARAMETERS_API_IDS.CREATION_DATE_MAX]: '',
      [SEARCH_PARAMETERS_API_IDS.MODIFICATION_DATE_MIN]: '',
      [SEARCH_PARAMETERS_API_IDS.MODIFICATION_DATE_MAX]: '',
      [SEARCH_PARAMETERS_API_IDS.STATUS]: [],
      [SEARCH_PARAMETERS_API_IDS.IS_IN_STOCK]: '',
      [SEARCH_PARAMETERS_API_IDS.IS_LABEL_AFFAIRE]: '',
      [SEARCH_PARAMETERS_API_IDS.PRICE_MIN]: '',
      [SEARCH_PARAMETERS_API_IDS.PRICE_MAX]: '',
      [SEARCH_PARAMETERS_API_IDS.CATEGORIES]: [],
      [SEARCH_PARAMETERS_API_IDS.PAGE]: '1',
      [SEARCH_PARAMETERS_API_IDS.SORT]: '0',
    }
  );

export const INITIAL_SEARCH = newInitialSearchObject;

function isSortInSortPart(sortId, sortPart) {
  return (
    Object.keys(SORT_FACETS_API_IDS).includes(sortPart) &&
    (SORT_FACETS_API_IDS[sortPart]['DEFAULT'] === String(sortId) || SORT_FACETS_API_IDS[sortPart]['REVERSED'] === String(sortId))
  );
}

export function getSortArrowColor(sortId, sortPart) {
  if (isSortInSortPart(sortId, sortPart)) {
    return SORT_ARROWS.COLORS.SELECTED;
  }
  return SORT_ARROWS.COLORS.DEFAULT;
}

export function getSortArrowDirection(sortId, sortPart) {
  if (isSortInSortPart(sortId, sortPart) && SORT_FACETS_API_IDS[sortPart]['REVERSED'] === String(sortId)) {
    return SORT_ARROWS.ANTD_DIRECTION_TYPE.UP;
  }
  return SORT_ARROWS.ANTD_DIRECTION_TYPE.DOWN;
}

export function getNewSortId(currentSortId, newSortPart) {
  if (isSortInSortPart(currentSortId, newSortPart)) {
    let objectSortPart = SORT_FACETS_API_IDS[newSortPart];
    if (objectSortPart['DEFAULT'] === String(currentSortId)) {
      return objectSortPart['REVERSED'];
    }
    if (objectSortPart['REVERSED'] === String(currentSortId)) {
      return objectSortPart['DEFAULT'];
    }
  }

  if (Object.keys(SORT_FACETS_API_IDS).includes(newSortPart)) {
    return SORT_FACETS_API_IDS[newSortPart]['DEFAULT'];
  }

  return SORT_FACETS_API_IDS.MODIFICATION_DATE.DEFAULT;
}

export const BULK_ACTION_EVENTS = {
  DEFAULT: 'selectionner',
  EDIT_STOCK: 'edit_stock',
  UPDATE_CATEGORY: 'edit_category',
  ADD_LABEL_AFFAIRE: 'add_label',
  REMOVE_LABEL_AFFAIRE: 'remove_label',
  ACTIVATE: 'activer',
  EDIT_INSPIRATION: 'edit_inspiration',
  DELETE: 'delete',
};

export const SEARCH_PAGE_REF = 'search-screen';

export function getCleanedSearchParamsFromURL(searchParams) {
  const URLGetParams = new URLSearchParams(window.location.search);
  let cleanedSearchParams = {};
  let {
    QUERY,
    SKU,
    LOCATION,
    CREATION_DATE_MIN,
    CREATION_DATE_MAX,
    MODIFICATION_DATE_MIN,
    MODIFICATION_DATE_MAX,
    STATUS,
    IS_IN_STOCK,
    IS_LABEL_AFFAIRE,
    PRICE_MIN,
    PRICE_MAX,
    CATEGORIES,
    PAGE,
    SORT,
    LIMIT,
  } = SEARCH_PARAMETERS_API_IDS;

  let firstGetParamsToClean = [
      CREATION_DATE_MIN,
      CREATION_DATE_MAX,
      MODIFICATION_DATE_MIN,
      MODIFICATION_DATE_MAX,
      IS_IN_STOCK,
      IS_LABEL_AFFAIRE,
      PRICE_MIN,
      PRICE_MAX,
      SORT,
      QUERY,
      SKU,
      LOCATION,
    ],
    qAndSkuAndEmplacement = URLGetParams.get(QUERY);

  firstGetParamsToClean.forEach((paramToClean) => {
    cleanedSearchParams[paramToClean] = valueIfNotNullElseEmptyString(URLGetParams.get(paramToClean));
  });

  if (isDefinedAndIsNotEmptyString(qAndSkuAndEmplacement) && qAndSkuAndEmplacement.includes('sku:')) {
    // Si q vaut "aaaasku:1076894-20220913-001/A-001" par exemple, alors q vaut aaaa
    cleanedSearchParams[QUERY] = qAndSkuAndEmplacement.split('sku:')[0];
    // sku est la partie entre sku: et / (1076894-20220913-001 dans l'exemple)
    cleanedSearchParams[SKU] = qAndSkuAndEmplacement.split('sku:')[1].split('/')[0];
    // Emplacement est la partie après le / (A-001 dans l'exemple)
    if (qAndSkuAndEmplacement.includes('/')) {
      cleanedSearchParams[LOCATION] = qAndSkuAndEmplacement.split('/')[1];
    }
  }

  cleanedSearchParams[STATUS] = isDefinedAndIsNotEmptyString(URLGetParams.get(STATUS))
    ? URLGetParams.get(STATUS)
        .split(',')
        .filter((status) => status !== '')
    : [];
  cleanedSearchParams[CATEGORIES] = isDefinedAndIsNotEmptyString(URLGetParams.get(CATEGORIES)) ? URLGetParams.get(CATEGORIES).split(',') : [];
  cleanedSearchParams[PAGE] = isDefinedAndIsNotEmptyString(URLGetParams.get(PAGE)) ? URLGetParams.get(PAGE) : 1;
  cleanedSearchParams[LIMIT] = isDefinedAndIsNotEmptyString(URLGetParams.get(LIMIT)) ? URLGetParams.get(LIMIT) : 100;

  return {
    ...searchParams,
    [QUERY]: cleanedSearchParams[QUERY],
    [CREATION_DATE_MIN]: cleanedSearchParams[CREATION_DATE_MIN],
    [CREATION_DATE_MAX]: cleanedSearchParams[CREATION_DATE_MAX],
    [MODIFICATION_DATE_MIN]: cleanedSearchParams[MODIFICATION_DATE_MIN],
    [MODIFICATION_DATE_MAX]: cleanedSearchParams[MODIFICATION_DATE_MAX],
    [IS_IN_STOCK]: cleanedSearchParams[IS_IN_STOCK],
    [IS_LABEL_AFFAIRE]: cleanedSearchParams[IS_LABEL_AFFAIRE],
    [PRICE_MIN]: cleanedSearchParams[PRICE_MIN],
    [PRICE_MAX]: cleanedSearchParams[PRICE_MAX],
    [SORT]: cleanedSearchParams[SORT],
    [STATUS]: cleanedSearchParams[STATUS],
    [PAGE]: cleanedSearchParams[PAGE],
    [LIMIT]: cleanedSearchParams[LIMIT],
    [CATEGORIES]: cleanedSearchParams[CATEGORIES],
    [SKU]: cleanedSearchParams[SKU],
    [LOCATION]: cleanedSearchParams[LOCATION],
  };
}

export function computeParametersNumber(searchParams) {
  let { STATUS, CATEGORIES, PAGE, SORT, LIMIT } = SEARCH_PARAMETERS_API_IDS;
  let parametersNumber = 0;
  let irrelevantSearchParams = [PAGE, SORT, LIMIT];

  for (const parameter in searchParams) {
    if (
      parameter === STATUS &&
      parseInt(searchParams[parameter].length) > 0 &&
      searchParams[parameter][0] &&
      searchParams[parameter][0] !== '' &&
      !(searchParams[parameter].includes(AD_STATUS.SLEEPING.API_ID) && searchParams[parameter].length === 1)
    ) {
      parametersNumber++;
    }

    if (
      (parameter === CATEGORIES && parseInt(searchParams[parameter].length) > 0 && searchParams[parameter][0] && searchParams[parameter][0] !== '') ||
      (parameter !== CATEGORIES &&
        parameter !== STATUS &&
        searchParams[parameter] &&
        !irrelevantSearchParams.includes(parameter) &&
        searchParams[parameter] !== '')
    ) {
      parametersNumber++;
    }
  }

  return parametersNumber;
}

export function getChildCategory(categorie) {
  if (!categorie.children.length) {
    return categorie;
  }
  return getChildCategory(categorie.children[0]);
}

export const MAX_SELECTED_PRODUCTS = 400;

export function isSleepingAd(searchParams) {
  let { STATUS, IS_IN_STOCK, CREATION_DATE_MIN, CREATION_DATE_MAX, MODIFICATION_DATE_MIN, MODIFICATION_DATE_MAX } = SEARCH_PARAMETERS_API_IDS;

  return (
    searchParams[STATUS].includes(AD_STATUS.SLEEPING.API_ID) &&
    searchParams[STATUS].filter((statut) => statut !== '').length === 1 &&
    searchParams[IS_IN_STOCK] === '1' &&
    (!searchParams[CREATION_DATE_MIN] || searchParams[CREATION_DATE_MIN] === '') &&
    (!searchParams[CREATION_DATE_MAX] || searchParams[CREATION_DATE_MAX] === '') &&
    (!searchParams[MODIFICATION_DATE_MIN] || searchParams[MODIFICATION_DATE_MIN] === '') &&
    (!searchParams[MODIFICATION_DATE_MAX] || searchParams[MODIFICATION_DATE_MAX] === '')
  );
}
