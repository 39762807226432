import { Anchor } from 'antd';
import { useAtomValue } from 'jotai';
import React from 'react';
import { collapsedWithCookieAtom } from '~/atoms';

import './AnchorNavigation.scss';

export default function AnchorNavigation({ anchorItems, panelRef }) {
  const collapsed = useAtomValue(collapsedWithCookieAtom);

  return (
    <div className='ad-details-anchor-wrapper'>
      <Anchor
        className={`ad-details-anchor${!collapsed ? ' side-menu-opened' : ''}`}
        affix
        getContainer={() => panelRef.current}
        offsetTop={100}
        items={anchorItems}
        replace={true}
      />
    </div>
  );
}
