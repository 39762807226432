import { Affix } from 'antd';
import React from 'react';

import './StickyTitle.scss';

function StickyTitle({ target, className = null, children }) {
  return (
    <>
      <Affix target={() => target} className='sticky-title'>
        <div className={`sticky-inner-wrapper${className ? ` ${className}` : ''}`}>{children}</div>
      </Affix>
    </>
  );
}

export default StickyTitle;
