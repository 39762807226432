import _ from 'lodash';
import { AD_DETAILS_API_IDS, createInitialFormFieldsAndValidationRules, updateLabelAffairesToCurrentCategories } from '~/helpers/adDetails';
import { fillFormFieldsWithTempData, reconciliateAttributes } from '~/helpers/adDetails/general';

import './actions';

const initialState = {
  last_query_params: {},
  current_form_status: null, // ['draft', 'pending', 'published']
  products_count: 0,
  product_list: {},
  is_loading_schema: false,
  is_loading_product_list: false,
  drafts: [],
  all_form_components: {},
  validation_rules: {},
  form_schema: {},
  initial_form_fields: {},
  form_fields: {},
  form_fields_temp: null,
  params_drawer: null,
  shipping_list: [],
  image_replace_loading: {
    status: false,
    order: null,
  },
  form_fields_has_sku: false,
};

// Product image CRUD
export const REORDER_IMAGE = 'REORDER_IMAGE';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const IMAGE_UPLOAD = 'IMAGE_UPLOAD';
export const IMAGE_UPLOAD_ERROR = 'IMAGE_UPLOAD_ERROR';
export const IMAGE_UPLOAD_SUCCESS = 'IMAGE_UPLOAD_SUCCESS';
export const IMAGE_REPLACE_LOADING = 'IMAGE_REPLACE_LOADING';
export const IMAGE_REPLACE_SUCCESS = 'IMAGE_REPLACE_SUCCESS';
export const IMAGE_REPLACE_ERROR = 'IMAGE_REPLACE_ERROR';

// Product other fieldsets CRUD
export const AD_SAVE_SUCCESS = 'AD_SAVE_SUCCESS';
export const HANDLE_CHANGE = 'HANDLE_CHANGE';
export const CLEAN_FORM_STATE = 'CLEAN_FORM_STATE';
export const DUPLICATE_PRODUCT = 'DUPLICATE_PRODUCT';
export const ADD_SHIPPING_OPTIONS = 'ADD_SHIPPING_OPTIONS';
export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const SETUP_ATTRIBUTES_REQUIRED = 'SETUP_ATTRIBUTES_REQUIRED';

// Redux state variable form_fields load
export const LOAD_FORM_SCHEMA = 'LOAD_FORM_SCHEMA';
export const LOADED_FORM_SCHEMA = 'LOADED_FORM_SCHEMA';
export const ERROR_FORM_SCHEMA = 'ERROR_FORM_SCHEMA';

// Catalogue
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const LOAD_PRODUCTS_SUCCESS = 'LOAD_PRODUCTS_SUCCESS';
export const LOAD_PRODUCTS_ERROR = 'LOAD_PRODUCTS_ERROR';

export default (state = initialState, action) => {
  switch (action.type) {
    case DUPLICATE_PRODUCT: {
      const form_fields_temp = _.cloneDeep(state.form_fields);
      form_fields_temp[AD_DETAILS_API_IDS.TECHNICAL_INFO.SKU] = '';
      form_fields_temp[AD_DETAILS_API_IDS.IMAGES.IMAGES] = [];

      // Remove etat_precision from duplicated form
      if (
        form_fields_temp[AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY] &&
        form_fields_temp[AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY][AD_DETAILS_API_IDS.ATTRIBUTES.ETAT_PRECISION]
      ) {
        form_fields_temp[AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY][AD_DETAILS_API_IDS.ATTRIBUTES.ETAT_PRECISION] = '';
      }
      return {
        ...state,
        form_fields_temp: form_fields_temp,
      };
    }
    case REORDER_IMAGE:
      return {
        ...state,
        form_fields: {
          ...state.form_fields,
          images: action.payload.images,
        },
      };

    case CLEAN_FORM_STATE: {
      let { current_form_status, validation_rules, form_schema, form_fields, initial_form_fields } = initialState;

      return {
        ...state,
        current_form_status,
        validation_rules,
        form_schema,
        form_fields,
        initial_form_fields,
      };
    }

    case LOAD_PRODUCTS:
      return {
        ...state,
        is_loading_product_list: true,
      };

    case LOAD_PRODUCTS_ERROR:
    case LOAD_PRODUCTS_SUCCESS:
      if (action.error) {
        // on signale une erreur que si le back retourne un message d'erreur, sinon le front fait comme s'il n'y en avait pas
        return {
          ...state,
          is_loading_product_list: false,
        };
      }
      return {
        ...state,
        params_drawer: action.payload.params_drawer !== null ? action.payload.params_drawer : state.params_drawer,
        products_count: action.payload.products_count,
        is_loading_product_list: false,
        product_list: {
          [action.payload.merchant_id]: {
            ...state.product_list[action.payload.merchant_id],
            [action.payload.requested_page]: action.payload.product_list,
            currentPage: action.payload.requested_page,
          },
        },
      };

    case DELETE_IMAGE:
      return {
        ...state,
        form_fields: {
          ...state.form_fields,
          images: state.form_fields.images.filter((image) => image.url !== action.url),
        },
      };

    case IMAGE_UPLOAD:
      return {
        ...state,
        form_fields: {
          ...state.form_fields,
          images: [...(state.form_fields.images || []), { id: action.random_id, uploading: true, url: null }],
        },
      };

    case IMAGE_UPLOAD_ERROR:
      return {
        ...state,
        form_fields: {
          ...state.form_fields,
          images: state.form_fields.images?.filter((image) => image.id !== action.random_id) || [],
        },
      };

    case IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        form_fields: {
          ...state.form_fields,
          images: state.form_fields.images.map((image, index) => {
            if (image.id === action.random_id) {
              return {
                ...image,
                uploading: false,
                order: index + 1,
                url: action.url,
              };
            }
            return image;
          }),
        },
      };

    case IMAGE_REPLACE_LOADING:
      return {
        ...state,
        image_replace_loading: {
          status: true,
          order: action.order,
        },
      };

    case IMAGE_REPLACE_ERROR:
      return {
        ...state,
        image_replace_loading: {
          status: true,
          order: action.order,
        },
      };

    case IMAGE_REPLACE_SUCCESS: {
      const imageArray = state.form_fields.images;
      const index = imageArray.findIndex((element) => element.order === action.order);
      const newImageArray = [];
      for (let i = 0; imageArray.length - 1 > i; i++) {
        if (i === index) {
          let newObject = {
            ...imageArray[i],
            url: action.url,
            id: action.id,
            order: action.order,
          };
          // Dans le cas où l'image à été modifiée par l'éditeur photo, on supprime  image_id
          // car il s'agit d'un id Izberg qui lui permet de lier la photo à l'offre.
          // C'est une nouvelle image, il faut donc un nouvel identifiant
          delete newObject.image_id;
          newImageArray.push(newObject);
        } else {
          newImageArray.push(imageArray[i]);
        }
      }

      return {
        ...state,
        form_fields: {
          ...state.form_fields,
          images: newImageArray,
        },
        image_replace_loading: {
          status: false,
          order: null,
        },
      };
    }

    case SETUP_ATTRIBUTES_REQUIRED: {
      const form_components_with_attributes = action.payload.attributes.reduce(
        (all_form_components, attribute) => {
          all_form_components[attribute.key] = {
            key: attribute.key,
            label: attribute.label,
            required: attribute.required,
            field_type: 'attribute',
          };
          return all_form_components;
        },
        { ...state.all_form_components }
      );

      const validation_rules_with_attributes = action.payload.attributes.reduce(
        (validation_rules, attribute) => {
          validation_rules[attribute.key] = {
            required: attribute.required,
          };
          return validation_rules;
        },
        { ...state.validation_rules }
      );

      const { attributes, deletedAttributes } = state.form_fields;

      if (attributes && deletedAttributes) {
        const reconciliatedAttributes = reconciliateAttributes({ attributes, deletedAttributes }, action.payload.attributes);

        if (reconciliatedAttributes.reconciliated === true) {
          const { attributes, deletedAttributes } = reconciliatedAttributes;
          return {
            ...state,
            form_fields: {
              ...state.form_fields,
              attributes,
            },
            deletedAttributes,
            all_form_components: form_components_with_attributes,
            validation_rules: validation_rules_with_attributes,
          };
        }
      }

      return {
        ...state,
        all_form_components: form_components_with_attributes,
        validation_rules: validation_rules_with_attributes,
      };
    }

    case HANDLE_CHANGE: {
      if (action.payload.input_key) {
        let { ATTRIBUTES, TITLE_AND_DESCRIPTION, TECHNICAL_INFO, CATEGORIES, PROMOTION } = AD_DETAILS_API_IDS;

        switch (action.payload.input_key) {
          case ATTRIBUTES.MAIN_KEY: {
            const { substate_key, value } = action.payload;

            return {
              ...state,
              form_fields: {
                ...state.form_fields,
                attributes: {
                  ...state.form_fields.attributes,
                  [substate_key]: value,
                },
              },
            };
          }
          case TITLE_AND_DESCRIPTION.DESCRIPTION:
            return {
              ...state,
              form_fields: {
                ...state.form_fields,
                [action.payload.input_key]: action.payload.value,
              },
            };

          case TECHNICAL_INFO.STOCK:
            return {
              ...state,
              form_fields: {
                ...state.form_fields,
                [action.payload.input_key]: action.payload.value,
              },
            };

          case TECHNICAL_INFO.PRICE:
            return {
              ...state,
              form_fields: {
                ...state.form_fields,
                [action.payload.input_key]: action.payload.value,
              },
            };

          case CATEGORIES.CATEGORIE:
            return {
              ...state,
              form_fields: {
                ...state.form_fields,
                [action.payload.input_key]: action.payload.value,
                [PROMOTION.LABEL_AFFAIRE]: updateLabelAffairesToCurrentCategories(
                  action.payload.value,
                  state.form_fields[PROMOTION.LABEL_AFFAIRE],
                  state.all_form_components[PROMOTION.LABEL_AFFAIRE].choices
                ),
              },
            };

          default:
            break;
        }
      }

      return {
        ...state,
        form_fields: {
          ...state.form_fields,
          [action.payload.input_key]: action.payload.value,
        },
      };
    }

    case LOAD_FORM_SCHEMA:
      return {
        ...state,
        is_loading_schema: true,
        form_fields_has_sku: false,
      };

    case ERROR_FORM_SCHEMA:
      return {
        ...state,
        is_loading_schema: false,
      };

    case LOADED_FORM_SCHEMA: {
      const { form_fields_temp } = state;
      const { form_fields, validation_rules, all_form_components } = createInitialFormFieldsAndValidationRules(action.schema);
      const initial_form_fields = _.cloneDeep(form_fields);

      // If we have temp data (dupplication process), we fill the form with it
      if (form_fields_temp) {
        fillFormFieldsWithTempData(form_fields, form_fields_temp);
      }

      return {
        ...state,
        current_form_status: action.schema['components'][0][AD_DETAILS_API_IDS.STATUS],
        is_loading_schema: false,
        form_schema: action.schema,
        form_fields,
        initial_form_fields,
        validation_rules,
        all_form_components,
        form_fields_has_sku: action.with_sku,
        form_fields_temp: null,
      };
    }

    case AD_SAVE_SUCCESS: {
      const { form_fields } = state;
      const initial_form_fields = _.cloneDeep(form_fields);

      return {
        ...state,
        initial_form_fields: initial_form_fields,
      };
    }

    default: {
      return state;
    }
  }
};
