/* eslint-disable */
import { Steps } from 'antd';
import { format } from 'date-fns';
import React from 'react';
import ExpandableContent from '~/components/shared/ExpandableContent/ExpandableContent';

export default function OrderStatusHistory({ statusHistory }) {
  const steps = statusHistory
    .slice()
    .reverse()
    .map((transition) => ({
      title: transition.to_state_localized,
      description: (
        <p className='body1' title={format(transition.timestamp, 'DD.MM.YYYY HH:mm:ss')}>
          {format(transition.timestamp, 'DD.MM.YY')}
        </p>
      ),
      status: 'wait',
    }));
  return (
    <>
      <ExpandableContent title='Historique' withArrow>
        <Steps progressDot direction='vertical' items={steps} />
      </ExpandableContent>
    </>
  );
}
