import { api } from '~/services/api';
import { USER_LOGIN, USER_LOGIN_ERROR, USER_LOGIN_SUCCESS } from '~/store/reducers/session';

export function logUser(inputs, callback) {
  return (dispatch) => {
    dispatch({ type: USER_LOGIN });
    api
      .post('/user/sso/', {
        body: JSON.stringify({
          username: inputs.email,
          password: inputs.password,
          remember_me: inputs.rememberMe,
        }),
      })
      .then((body) => {
        if (body.success) {
          dispatch({ type: USER_LOGIN_SUCCESS, user: body.data });
          callback(null, body.success);
        } else {
          dispatch({ type: USER_LOGIN_ERROR, error: body.message.error });
          callback(body.message.error, null);
        }
      })
      .catch((error) => {
        dispatch({ type: USER_LOGIN_ERROR, error });
        callback(error, null);
      });
  };
}
