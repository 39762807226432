import Icon, { ArrowDownOutlined, RightOutlined } from '@ant-design/icons';
import { Flex, Table, Tag } from 'antd';
import format from 'date-fns/format';
import React from 'react';
import { Link } from 'react-router-dom';
import categoryIdToIconMapper from '~/components/shared/CategoryIcon/CategoryIcon';
import { AD_STATUS, LABEL_AFFAIRE_API_IDS, getColorFromStatus, getStatusLabel } from '~/helpers/adDetails';
import { SEARCH_PAGE_REF, getChildCategory, getNewSortId, getSortArrowColor, getSortArrowDirection } from '~/helpers/catalogue';
import { RoutingUrl, appendParamsToEndpoint, getImageUrl } from '~/helpers/general';
import breakpoints from '~/styles/breakpoints.module.scss';

import './ResultsTable.scss';

const breakpointMobile = parseInt(breakpoints.xsMax.slice(0, -2));

export function ResultsTable(props) {
  const currentPageProductsList = props.productList[props.currentPage] ? props.productList[props.currentPage] : [];

  const updateSort = (newSortPart) => {
    let newSortId = getNewSortId(props.searchParams.tri, newSortPart);
    let newSearchQueryParams = {
      ...props.searchParams,
      tri: newSortId,
    };
    props.history.push(appendParamsToEndpoint(RoutingUrl.catalogue(), newSearchQueryParams));
  };

  const isSold = (product) => {
    return (!product.stock || product.stock === 0) && product.status !== AD_STATUS.DRAFT.API_ID;
  };

  const renderTitleHeadCell = (title, key = null) => (
    <div className='title-head-cell' onClick={() => key && updateSort(key)}>
      {key && (
        <ArrowDownOutlined className={`sort-icon ${getSortArrowColor(props.searchParams.tri, key)} ${getSortArrowDirection(props.searchParams.tri, key)}`} />
      )}
      {title}
    </div>
  );

  const renderImage = (value) => {
    if (!value) return <div className='ad-img' />;
    return <img className='ad-img' src={getImageUrl(value, 100, 'crop')} alt='' />;
  };

  const renderSKU = (value) => <p className='body1'>{value}</p>;

  const renderTitle = (value) => <p className='body1 title'>{value}</p>;

  const renderPrice = (product) => (
    <>
      {product['price_with_vat'] !== null && product['price_with_vat'] !== undefined && (
        <p className={'price-with-vat' + (isSold(product) ? ' sold-price' : '')}>€{product['price_with_vat']}</p>
      )}

      {product['previous_price_with_vat'] !== null &&
        product['previous_price_with_vat'] !== undefined &&
        product['previous_price_with_vat'] > 0 &&
        product['previous_price_with_vat'] !== product['price_with_vat'] && <p className='previous-price-with-vat'>€{product['previous_price_with_vat']}</p>}
    </>
  );

  const renderStock = (stock) => <p className='body1 stock'>{stock !== '' ? stock : '0'}</p>;

  const renderCategories = (categoriesList) => {
    if (categoriesList.length === 0) {
      return null;
    }

    const labelAffairesIds = Object.keys(LABEL_AFFAIRE_API_IDS).map((key) => LABEL_AFFAIRE_API_IDS[key]);
    const mainCategory = categoriesList
      .map((cat) => ({
        leaf: getChildCategory(cat),
        originalParent: cat,
      }))
      .filter((cat) => !labelAffairesIds.includes(cat.leaf.id))
      .shift();
    if (!mainCategory || !mainCategory.leaf) {
      return null;
    }
    return (
      <Flex align='center'>
        {mainCategory.originalParent && (
          <>
            <Icon className='category-icon' component={categoryIdToIconMapper(mainCategory.originalParent?.id)} />
            <RightOutlined className='category-arrow-icon' />
          </>
        )}
        <p className='body1'>{mainCategory.leaf.value}</p>
      </Flex>
    );
  };

  const renderStatut = (status) => {
    return (
      <Tag className='light capitalize-first-letter' color={getColorFromStatus(status)}>
        {getStatusLabel(status)}
      </Tag>
    );
  };

  const renderDate = (dateObject) => (
    <p className='body1' title={format(dateObject, 'DD.MM.YYYY HH:mm:ss')}>
      {format(dateObject, 'DD.MM.YYYY')}
    </p>
  );

  let columns = [
    {
      title: renderTitleHeadCell('Image'),
      key: 'image',
      dataIndex: 'image',
      className: 'image',
      render: renderImage,
    },
    {
      title: renderTitleHeadCell('SKU'),
      key: 'sku',
      className: 'sku',
      dataIndex: 'sku',
      render: renderSKU,
    },
    {
      title: renderTitleHeadCell('Titre'),
      key: 'title',
      className: 'title',
      dataIndex: 'title',
      render: renderTitle,
    },
    {
      title: renderTitleHeadCell('Prix', 'PRICE'),
      key: 'price',
      className: 'price title-sortable text-center',
      render: renderPrice,
      width: 110,
    },
    {
      title: renderTitleHeadCell('Stock', 'STOCK'),
      key: 'stock',
      className: 'stock title-sortable text-center',
      dataIndex: 'stock',
      render: renderStock,
      width: 110,
    },
    {
      title: renderTitleHeadCell('Catégorie'),
      key: 'categories',
      className: 'categories',
      dataIndex: 'categories',
      render: renderCategories,
    },
    {
      title: renderTitleHeadCell('Statut', 'AD_STATUS'),
      key: 'statut',
      className: 'status title-sortable text-center',
      dataIndex: 'status',
      render: renderStatut,
      width: 120,
    },
    {
      title: renderTitleHeadCell('Créé le', 'CREATION_DATE'),
      key: 'created_on',
      className: 'created_on title-sortable',
      dataIndex: 'created_on',
      render: renderDate,
      width: 120,
    },
    {
      title: renderTitleHeadCell('Modifié le', 'MODIFICATION_DATE'),
      key: 'modified_on',
      className: 'modified_on title-sortable',
      dataIndex: 'modified_on',
      render: renderDate,
      width: 130,
    },
  ];

  columns = columns.map((col) => ({
    ...col,
    render: (value, product) => (
      <Link className='link-edition-cell' to={RoutingUrl.adEdition([product.product_id, `?ref=${SEARCH_PAGE_REF}`])}>
        {col.render(value, product)}
      </Link>
    ),
  }));

  if (window.innerWidth < breakpointMobile) {
    const widthForMobile = {
      image: 120,
      sku: 220,
      title: 220,
      categories: 220,
    };
    columns = columns.map((col) => {
      if (widthForMobile[col.key]) {
        return {
          ...col,
          width: widthForMobile[col.key],
        };
      }
      return col;
    });
  }

  return (
    <>
      <Table
        scroll={{ x: 'max-content', y: '70vh' }}
        rowSelection={props.rowSelection}
        loading={props.loading}
        className='catalogue-table'
        pagination={false}
        onRow={(product) => ({
          className: isSold(product) ? 'sold' : '',
        })}
        rowKey={(product) => product.product_id}
        dataSource={currentPageProductsList}
        columns={columns}
      />
    </>
  );
}
