/* eslint-disable */
import { Flex } from 'antd';
import React from 'react';

import './OrderCustomerInfo.scss';

function AddressInfo({ address, email }) {
  const { name, address: street, address2: street_complementary, city, zipcode, country, phone } = address;

  const fullAddress = `${street}${street_complementary ? ` ${street_complementary}` : ''}, ${zipcode} ${city} ${country.name}`;
  return (
    <Flex className='address-info custom-list grey' vertical gap={4}>
      <p className='body1'>{name}</p>
      <p className='body1'>{fullAddress}</p>
      <p className='body1'>{phone}</p>
      <p className='body1'>{email}</p>
    </Flex>
  );
}

export default function OrderCustomerInfo({ shippindAddress, billingAddress, customerEmail }) {
  return (
    <>
      <Flex className='order-customer-info-wrapper' vertical gap={8}>
        <p className='sub1'>Le ou la client·e</p>
        {shippindAddress.id !== billingAddress.id ? (
          <>
            <div className='customer-shipping-address'>
              <p className='body2'>Adresse de livraison</p>
              <AddressInfo address={shippindAddress} email={customerEmail} />
            </div>
            <div className='customer-billing-address'>
              <p className='body2'>Adresse de facturation</p>
              <AddressInfo address={billingAddress} email={customerEmail} />
            </div>
          </>
        ) : (
          <div className='customer-address'>
            <p className='body2'>Adresse de livraison et de facturation</p>
            <AddressInfo address={shippindAddress} email={customerEmail} />
          </div>
        )}
      </Flex>
    </>
  );
}
