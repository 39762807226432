import { AD_DETAILS_API_IDS, PIXR_CLIENT_KEY, changeDescriptionForMultiline } from '~/helpers/adDetails';
import { adsCatalogueDataMongoValue, getAdsCatalogueData } from '~/helpers/cookies';
import { appendParamsToEndpoint } from '~/helpers/general';
import { api } from '~/services/api';
import {
  AD_SAVE_SUCCESS,
  ERROR_FORM_SCHEMA,
  IMAGE_REPLACE_ERROR,
  IMAGE_REPLACE_LOADING,
  IMAGE_REPLACE_SUCCESS,
  IMAGE_UPLOAD,
  IMAGE_UPLOAD_ERROR,
  IMAGE_UPLOAD_SUCCESS,
  LOADED_FORM_SCHEMA,
  LOAD_FORM_SCHEMA,
  LOAD_PRODUCTS,
  LOAD_PRODUCTS_ERROR,
  LOAD_PRODUCTS_SUCCESS,
} from '~/store/reducers/products';

export function loadProducts(params = {}, paramsDrawer) {
  let params_drawer = paramsDrawer === undefined ? null : paramsDrawer;
  return (dispatch, getState) => {
    let {
      products: { product_list },
      session: { active_merchant },
    } = getState();
    let currentPage = product_list[active_merchant.id] ? product_list[active_merchant.id].currentPage : 1;

    dispatch({ type: LOAD_PRODUCTS });
    if (!params.page && typeof params === 'object') {
      params.page = currentPage;
    }
    let baseUrl;
    let url;

    const dataType = getAdsCatalogueData();
    if (dataType === adsCatalogueDataMongoValue) {
      baseUrl = `/v2/${active_merchant.id}/mongo_products/`;
    } else {
      baseUrl = `/${active_merchant.id}/products/`;
    }

    // NOTE: params peut être une string si l'utilisateur a navigué
    // dans /annonces en mettant dans l'url les paramètres de recherche
    if (typeof params === 'string') {
      url = baseUrl + params;
    } else {
      url = appendParamsToEndpoint(baseUrl, params);
    }
    url = url.replace('status=&', '');
    api
      .get(url)
      .then((response) => {
        if (response.success === true) {
          return dispatch({
            type: LOAD_PRODUCTS_SUCCESS,
            payload: {
              merchant_id: active_merchant.id,
              products_count: response.data.total,
              product_list: response.data.results,
              requested_page: params.page || 1,
              params_drawer: params_drawer,
            },
          });
        }
        return dispatch({
          type: LOAD_PRODUCTS_ERROR,
          error: response.message.error,
        });
      })
      .catch((error) => dispatch({ type: LOAD_PRODUCTS_ERROR, error }));
  };
}

export function updateAd({ productId, modifiedFields, publish }, callback, errorCallback) {
  return (dispatch, getState) => {
    const {
      session: { active_merchant },
    } = getState();

    const formFieldsToSend = { ...modifiedFields };

    if (formFieldsToSend[AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.DESCRIPTION]) {
      formFieldsToSend[AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.DESCRIPTION] = changeDescriptionForMultiline(
        formFieldsToSend[AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.DESCRIPTION]
      );
    }

    const body = JSON.stringify({
      components: [formFieldsToSend],
      merchant: active_merchant.id,
    });

    api
      .patch(`/product/${productId}/`, { body })
      .then((response) => {
        if (response.success) {
          if (publish) {
            return api.patch(`/product/${response.data.product_id}/activate/`);
          }
          dispatch({ type: AD_SAVE_SUCCESS });
          callback(response.data.product_id);
        } else {
          errorCallback(response.message.error);
        }
      })
      .then((response) => {
        // NOTE: if you don't return anything from a then-function, you are effectively returning a resolved promise for the value undefined
        // so if response is undefined we know that we didn't return the api.patch
        // so no need to execute the callback twice
        if (response == null) {
          return;
        }
        if (response.success) {
          dispatch({ type: AD_SAVE_SUCCESS });
          callback(response.data.product_id);
        } else {
          errorCallback(response.message.error);
        }
      })
      .catch((error) => {
        errorCallback(error);
      });
  };
}

// Créé un brouillon et l'active si besoin
export function createAd(publish = false, callback, errorCallback) {
  return (dispatch, getState) => {
    const {
      products: { form_fields },
      session,
    } = getState();
    const { active_merchant } = session;

    const formFieldsToSend = { ...form_fields };

    if (formFieldsToSend[AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.DESCRIPTION]) {
      formFieldsToSend[AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.DESCRIPTION] = changeDescriptionForMultiline(
        formFieldsToSend[AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.DESCRIPTION]
      );
    }

    const formDataToSend = {
      components: [formFieldsToSend],
      merchant: active_merchant.id,
    };

    api
      .post(`/${active_merchant.id}/product/create/`, {
        body: JSON.stringify(formDataToSend),
      })
      .then((response) => {
        if (response.success) {
          if (publish === true) {
            return api.patch(`/product/${response.data.product_id}/activate/`);
          }
          dispatch({ type: AD_SAVE_SUCCESS });
          return callback(response.data.product_id);
        }
        errorCallback(response.message.error);
      })
      .then((response) => {
        // NOTE: if you don't return anything from a then-function, you are effectively returning a resolved promise for the value undefined
        // so if response is undefined we know that we didn't return the api.patch
        // so no need to execute the callback twice
        if (response == null) {
          return;
        }
        if (response.success) {
          dispatch({ type: AD_SAVE_SUCCESS });
          return callback(response.data.product_id);
        }
        return errorCallback(response.message.error);
      });
  };
}

export function uploadImage(file, callBackError) {
  return (dispatch) => {
    let random_id = Math.random().toString().slice(2, 10);

    dispatch({ type: IMAGE_UPLOAD, random_id });

    let form_data = new FormData();

    form_data.append('file', file);

    api
      .post(`/upload_image/?PIXR_CLIENT_KEY=${PIXR_CLIENT_KEY}&path=labo`, {
        body: form_data,
      })
      .then((response) => {
        if (response.success) {
          dispatch({
            type: IMAGE_UPLOAD_SUCCESS,
            url: response.url,
            random_id,
          });
        } else {
          dispatch({
            type: IMAGE_UPLOAD_ERROR,
            error: response.message.error,
            random_id,
          });
          callBackError(file, response.message.error);
        }
      })
      .catch((error) => {
        dispatch({ type: IMAGE_UPLOAD_ERROR, error, random_id });
        callBackError(file, error);
      });
  };
}

export function replaceImage(file, order, callbackSuccess, callbackError) {
  return (dispatch) => {
    dispatch({
      type: IMAGE_REPLACE_LOADING,
      order: order,
    });
    let random_id = Math.random().toString().slice(2, 10);

    dispatch({ type: IMAGE_UPLOAD, random_id });

    let form_data = new FormData();

    form_data.append('file', file);

    api
      .post(`/upload_image/?PIXR_CLIENT_KEY=${PIXR_CLIENT_KEY}&path=labo`, {
        body: form_data,
      })
      .then((response) => {
        if (response.success) {
          dispatch({
            type: IMAGE_REPLACE_SUCCESS,
            url: response.url,
            order,
          });
          callbackSuccess();
        } else {
          dispatch({
            type: IMAGE_REPLACE_ERROR,
            error: response.message.error,
            random_id,
          });
          callbackError(response.message.error);
        }
      })
      .catch((error) => {
        dispatch({ type: IMAGE_UPLOAD_ERROR, error, random_id });
        callbackError(error);
      });
  };
}

// Recupere les choix de categorie et Label affaires pour l'edition de masse, les propositions
// des champs et les textes d'aides sont geres dynamiquement
export function loadFormSchemaThunk(product_id, with_sku = true) {
  return (dispatch, getState) => {
    let {
      session: { active_merchant },
    } = getState();
    dispatch({ type: LOAD_FORM_SCHEMA });

    let url = product_id ? `/product/${product_id}/` : `/${active_merchant.id}/product/create/?without_sku=${!with_sku}`;

    api
      .get(url)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: LOADED_FORM_SCHEMA,
            schema: response.data,
            with_sku: with_sku,
          });
        } else {
          dispatch({ type: ERROR_FORM_SCHEMA, error: response.info });
        }
      })
      .catch((error) => dispatch({ type: ERROR_FORM_SCHEMA, error }));
  };
}
