import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { RoutingUrl, passwordPattern } from '~/helpers/general';
import { updatePassword } from '~/services/api.calls';

import AuthenticationAlert from '../common/AuthenticationAlert/AuthenticationAlert';
import SubmitButton from '../common/SubmitButton';

export default function ResetPasswordForm({ token1, token2, history }) {
  const [form] = Form.useForm();
  const [isFetching, setIsFetching] = useState(false);
  const [alert, setAlert] = useState(null);

  const onFinish = ({ password }) => {
    setIsFetching(true);

    const body = JSON.stringify({
      token1: token1,
      token2: token2,
      password: password,
    });
    updatePassword(
      body,
      () => {
        setIsFetching(false);
        setAlert({
          type: 'success',
          message: "Le mot de passe a été réinitialisé avec succès. Redirection vers la page d'accueil de LaBO en cours...",
        });
        setTimeout(() => {
          history.push(RoutingUrl.login());
        }, 1500);
      },
      (errorMessage) => {
        setIsFetching(false);
        setAlert({
          type: 'error',
          message: errorMessage,
        });
      }
    );
  };

  return (
    <>
      <AuthenticationAlert
        alert={alert}
        afterClose={() => {
          setAlert(null);
        }}
        topPosition='-100px'
      />
      <Form form={form} name='reset-password' onFinish={onFinish}>
        <Form.Item
          name='password'
          rules={[
            { required: true, message: 'Ce champ est obligatoire.' },
            {
              pattern: passwordPattern,
              message:
                'Votre mot de passe est trop faible. Celui-ci doit contenir au moins 8 caractères dont un chiffre, une majuscule, une minuscule et un caractère spécial (.-/!$@).',
            },
          ]}
        >
          <Input.Password placeholder='Nouveau mot de passe' />
        </Form.Item>

        <Form.Item
          name='confirm'
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Ce champ est obligatoire.',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Les deux mots de passe entrés sont différents.'));
              },
            }),
          ]}
        >
          <Input.Password placeholder='Confirmer le nouveau mot de passe' />
        </Form.Item>

        <div className='authentication-submit-block'>
          <Form.Item noStyle>
            <SubmitButton loading={isFetching}>Modifier le mot de passe</SubmitButton>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}
