import { selectValueOfChildJSONWhereOtherAttributeEquals } from '../general';

export const AD_STATUS = {
  DRAFT: {
    API_ID: 'draft',
    COLOR: 'warning',
    CAN_BE_UPDATED: true,
    LABEL: 'brouillon',
  },
  INACTIVE: {
    API_ID: 'inactive',
    COLOR: 'default',
    CAN_BE_UPDATED: true,
    LABEL: 'suprimée',
  },
  PENDING: {
    API_ID: 'pending',
    COLOR: 'default',
    CAN_BE_UPDATED: true,
    LABEL: 'en modération',
  },
  REFUSED: {
    API_ID: 'banned',
    COLOR: 'error',
    CAN_BE_UPDATED: true,
    LABEL: 'refusée',
  },
  ACTIVE: {
    API_ID: 'active',
    COLOR: 'success',
    CAN_BE_UPDATED: true,
    LABEL: 'active',
  },
  SLEEPING: {
    API_ID: 'sleeping',
    COLOR: 'default',
    CAN_BE_UPDATED: true,
    LABEL: 'dormante',
  },
  NOSTATUS: {
    API_ID: 'empty',
    COLOR: 'default',
    CAN_BE_UPDATED: false,
    LABEL: '',
  },
};

export function getColorFromStatus(status) {
  return selectValueOfChildJSONWhereOtherAttributeEquals('COLOR', AD_STATUS, 'API_ID', status);
}

export function canProductBeUpdated(status) {
  return selectValueOfChildJSONWhereOtherAttributeEquals('CAN_BE_UPDATED', AD_STATUS, 'API_ID', status);
}

export function getStatusLabel(status) {
  return selectValueOfChildJSONWhereOtherAttributeEquals('LABEL', AD_STATUS, 'API_ID', status);
}
