import { useQuery } from '@tanstack/react-query';
import { Alert, Spin } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import AlertBasicServerError from '~/components/shared/AlertBasicServerError/AlertBasicServerError';
import { api } from '~/services/api';

import './CustomCalendar.scss';

const localizer = dayjsLocalizer(dayjs);

const traductionCalendar = {
  date: 'Date',
  time: 'Horaire',
  event: 'Evènement',
  allDay: 'Toute la journée',
  week: 'Semaine',
  work_week: 'Semaine',
  day: 'Jour',
  month: 'Mois',
  previous: '<',
  next: '>',
  yesterday: 'Hier',
  tomorrow: 'Demain',
  today: "Aujourd'hui",
  agenda: 'Agenda',

  noEventsInRange: "Il n'y a pas d'évènements pour ces dates.",

  showMore: (total) => `+${total} autres`,
};

const sellerEventOrigin = 'seller';
const marketingEventOrigin = 'marketing';

function EventWrapper({ event, children }) {
  return (
    <>
      <a className='event-link' href={event.link} target='_blank' rel='noopener noreferrer'>
        {children}
      </a>
    </>
  );
}

function serialize_events(events, origin) {
  return events
    .filter((event) => event.status !== 'cancelled')
    .map((event) => {
      let allDay = false;
      let start, end;
      if (event.start?.date) {
        allDay = true;
        start = new Date(event.start.date);
        end = new Date(event.end.date);
        // End date is exclusive in all day events
        if (end.getTime() !== start.getTime()) {
          // Remove one day to the end date
          end = dayjs(end).subtract(1, 'day').toDate();
        }
      } else if (event.start?.dateTime) {
        start = new Date(event.start.dateTime);
        end = new Date(event.end.dateTime);
      }

      return {
        title: event.summary,
        start: start,
        end: end,
        allDay: allDay,
        link: event.htmlLink,
        origin: origin,
      };
    });
}

function EventCalendar() {
  const { isLoading, data, error } = useQuery({
    queryKey: ['calendar'],
    queryFn: () =>
      api.get('/v2/calendar/events/').then((res) => {
        if (res.success) {
          return res.data;
        }
        if (typeof res.message?.error === 'string') {
          throw new Error(res.message.error);
        }
        throw new Error(res.message.error.message);
      }),
    retry: false,
  });

  if (isLoading) {
    return <Spin size='large' />;
  }

  if (!data) {
    return <AlertBasicServerError />;
  }

  if (error) {
    return <Alert type='error' message={error?.message} />;
  }

  const sellerEvents = serialize_events(data.seller_events, sellerEventOrigin);
  const marketingEvents = serialize_events(data.marketing_events, marketingEventOrigin);

  const events = sellerEvents.concat(marketingEvents);

  return (
    <>
      <Calendar
        localizer={localizer}
        messages={traductionCalendar}
        views={['month', 'week', 'agenda']}
        components={{ eventWrapper: EventWrapper }}
        events={events}
        eventPropGetter={(event) => {
          let className = '';
          switch (event.origin) {
            case sellerEventOrigin:
              className = 'seller-color';
              break;
            case marketingEventOrigin:
              className = 'marketing-color';
              break;
            default:
              break;
          }
          return { className: className };
        }}
        drilldownView='week'
        scrollToTime={dayjs().set('h', 9).toDate()}
        className='calendar'
      />
    </>
  );
}

function CustomCalendar() {
  return (
    <>
      <div className='calendar-block panel bordered shadow'>
        <p className='sub1'>Calendrier Label</p>
        <div className='calendar-wrapper'>
          <EventCalendar />
        </div>
      </div>
    </>
  );
}

export default CustomCalendar;
