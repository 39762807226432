import { Spin } from 'antd';
import React from 'react';
import PhotoroomLogo from '~/assets/svg/logo-photoroom.svg?react';

function PhotoroomNotification() {
  return (
    <>
      <Spin spinning fullscreen />
      <a id='photoroom-logo-link' href='https://photoroom.com/' target='_blank' rel='noreferrer'>
        <PhotoroomLogo id='photoroom-logo' />
      </a>
    </>
  );
}

export default PhotoroomNotification;
