import { Divider, Drawer } from 'antd';
import React from 'react';
import NavMenu from '~/components/nav/NavMenu/NavMenu';
import SecondaryNavMenu from '~/components/nav/SecondaryNavMenu/SecondaryNavMenu';
import CreateAdButton from '~/components/shared/CreateAdButton/CreateAdButton';

import './BurgerHeader.scss';

export default function BurgerHeader({ open, setOpen }) {
  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer open={open} onClose={close} placement='top' height='100%'>
        <div className='burger-menu-wrapper'>
          <div className='burger-main-block'>
            <NavMenu onClick={close} />

            <Divider />

            <CreateAdButton onClick={close} />
          </div>

          <div className='burger-footer-block'>
            <SecondaryNavMenu />
          </div>
        </div>
      </Drawer>
    </>
  );
}
