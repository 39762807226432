import { Form } from 'antd';
import React, { useState } from 'react';
import { sendPasswordResetRequest } from '~/services/api.calls';

import AuthenticationAlert from '../common/AuthenticationAlert/AuthenticationAlert';
import EmailFormItem from '../common/EmailFormItem';
import SubmitButton from '../common/SubmitButton';

export default function PasswordResetRequestForm() {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState(null);

  const onFinish = ({ email }) => {
    sendPasswordResetRequest(
      email,
      () => {
        setAlert({
          type: 'success',
          message: "Si le compte existe sur LaBO, alors un e-mail de réinitialisation de mot de passe a été envoyé à l'adresse: " + email,
        });
      },
      (errorMessage) => {
        setAlert({
          type: 'error',
          message: errorMessage,
        });
      }
    );
  };

  return (
    <>
      <AuthenticationAlert
        alert={alert}
        afterClose={() => {
          setAlert(null);
        }}
        topPosition='-75px'
      />
      <Form form={form} name='password-reset-request' onFinish={onFinish}>
        <EmailFormItem />
        <div className='authentication-submit-block'>
          <Form.Item noStyle>
            <SubmitButton loading={false}>Réinitialiser son mot de passe</SubmitButton>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}
