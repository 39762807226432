import { Flex } from 'antd';
import React from 'react';

import PickingRoute from './PickingRoute';

export default function BulkOrdersActions({ merchantId, pageLength, totalOrders, selectedOrderIds }) {
  const numberOrdersText = `${pageLength} commande${pageLength > 1 ? 's' : ''} sur ${totalOrders}`;
  const selectedOrdersText = `${selectedOrderIds.length} commande${selectedOrderIds.length > 1 ? 's' : ''} sélectionnée${selectedOrderIds.length > 1 ? 's' : ''}`;

  const selectionActive = selectedOrderIds.length > 0;

  return (
    <>
      <Flex justify='space-between' align='center' className='bulk-edition-wrapper'>
        {selectionActive ? <p className='body1'>{selectedOrdersText}</p> : <p className='body1'>{numberOrdersText}</p>}
        {selectionActive && <PickingRoute merchantId={merchantId} orderIds={selectedOrderIds} />}
      </Flex>
    </>
  );
}
