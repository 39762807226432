import { Flex, Spin } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorMessage from '~/components/shared/ErrorMessage/ErrorMessage';
import { SEARCH_PAGE_REF } from '~/helpers/catalogue';
import { RoutingUrl } from '~/helpers/general';
import { CLEAN_FORM_STATE } from '~/store/reducers/products';
import { loadFormSchemaThunk } from '~/store/reducers/products/actions';

import './AdDetails.scss';
import CreationEditionForm from './CreationEditionForm/CreationEditionForm';

// @TODO : refactor this component to function and try to move all logic of loading schema in react query
// Maybe find a proper way to merge AdDetails and CreationEditionForm components, imo AdDetails should wrap for History, and edition state and match params.
// But maybe CreationEditionForm should be the only one
class AdDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextProductLink: null,
      previousProductLink: null,
      openedFromSearchScreen: false,
    };

    if (this.props.location.search !== '') {
      let params = new URLSearchParams(this.props.location.search);

      if (params.get('ref') === SEARCH_PAGE_REF) {
        // TODO récupérer directement les annonces suivantes / précédentes.
        this.state.openedFromSearchScreen = true;
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    let { product_id } = this.props.match.params;

    let { openedFromSearchScreen } = this.state;

    this.props.dispatch(loadFormSchemaThunk(product_id));

    if (openedFromSearchScreen) {
      let nextProductLink = this.getNextProductOf(product_id);
      let previousProductLink = this.getPreviousProductOf(product_id);
      if (this._isMounted === true) {
        this.setState({
          nextProductLink,
          previousProductLink,
        });
      }
    }
  }

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState) {
    const asksForNewSchema = prevProps.location.state?.createAd !== this.props.location.state?.createAd && this.props.location.key;
    if (prevProps.match.params.product_id !== this.props.match.params.product_id || asksForNewSchema) {
      let { product_id } = this.props.match.params;
      let { openedFromSearchScreen } = this.state;

      this.props.dispatch({ type: CLEAN_FORM_STATE });
      this.props.dispatch(loadFormSchemaThunk(product_id));

      if (openedFromSearchScreen) {
        let nextProductLink = this.getNextProductOf(product_id);
        let previousProductLink = this.getPreviousProductOf(product_id);

        this.setState({
          nextProductLink,
          previousProductLink,
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: CLEAN_FORM_STATE });
    this._isMounted = false;
  }

  getNextProductOf = (product_id) => {
    let { current_page_products_list } = this.props;

    if (!current_page_products_list) return null;

    const p_id = Number(product_id);
    // prettier-ignore
    const index = current_page_products_list.findIndex(product => product.product_id === p_id);
    // prettier-ignore
    const next_product_index = index + 1;
    let next_product = null;

    if (current_page_products_list[next_product_index]) {
      next_product = current_page_products_list[next_product_index];
    } else if (next_product_index > current_page_products_list.length - 1) {
      // on boucle sur les produits si l'index sur prochain produit dépasse la length total des éléments de la page
      next_product = current_page_products_list[0];
    } else {
      return null;
    }
    return RoutingUrl.adEdition([next_product.product_id, `?ref=${SEARCH_PAGE_REF}`]);
  };

  onButtonNextClick = () => {
    let { nextProductLink } = this.state;
    this.props.history.push(nextProductLink);
  };

  getPreviousProductOf = (product_id) => {
    let { current_page_products_list } = this.props;

    // prettier-ignore
    if (!current_page_products_list) return null;

    const p_id = Number(product_id);
    const index = current_page_products_list.findIndex((product) => product.product_id === p_id);
    const previous_product_index = index - 1;
    let idProductToRedirectTo = null;

    if (current_page_products_list[previous_product_index]) {
      idProductToRedirectTo = current_page_products_list[previous_product_index].product_id;
    } else if (previous_product_index < 0) {
      idProductToRedirectTo = current_page_products_list[current_page_products_list.length - 1].product_id;
    } else {
      return null;
    }

    return RoutingUrl.adEdition([idProductToRedirectTo, `?ref=${SEARCH_PAGE_REF}`]);
  };

  onButtonPreviousClick = () => {
    let { previousProductLink } = this.state;
    this.props.history.push(previousProductLink);
  };

  renderAdView() {
    let { match, schema, history, panelRef } = this.props;
    let { product_id } = match.params;
    let { nextProductLink, previousProductLink, openedFromSearchScreen } = this.state;

    return (
      <CreationEditionForm
        schema={schema}
        productId={product_id}
        history={history}
        navigation={{
          on: openedFromSearchScreen && (nextProductLink || previousProductLink),
          prev: {
            disabled: !previousProductLink,
            event: this.onButtonPreviousClick,
          },
          next: {
            disabled: !nextProductLink,
            event: this.onButtonNextClick,
          },
        }}
        isFocus={this.props.isFocus}
        panelRef={panelRef}
      />
    );
  }

  render() {
    let { is_loading_schema, form_fields_has_sku } = this.props;

    return (
      <>
        {is_loading_schema ? (
          <Flex className='ad-details-loading-wrapper' justify='center' align='center'>
            <Spin size='large' />
          </Flex>
        ) : (
          <>
            {form_fields_has_sku ? (
              <>{this.renderAdView()}</>
            ) : (
              <div className='error-details-wrapper'>
                <ErrorMessage errorCode={'LOADING_SCHEMA_FAILED'} />
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  let {
    products: { product_list, current_form_status, form_fields_has_sku, form_fields },
    session: { active_merchant },
  } = state;
  let user_products_list = [];
  let current_page = 1;
  let associated_products_for_active_merchant = product_list[active_merchant.id];

  if (associated_products_for_active_merchant) {
    current_page = associated_products_for_active_merchant.currentPage || 1;
    user_products_list = product_list[active_merchant.id];
  }

  return {
    current_page_products_list: user_products_list[current_page],
    merchant_id: state.session.active_merchant.id,
    active_merchant: state.session.active_merchant,
    form_fields,
    schema: state.products.form_schema,
    is_loading_schema: state.products.is_loading_schema,
    product_status: current_form_status,
    form_fields_has_sku: form_fields_has_sku,
  };
}

export default connect(mapStateToProps)(AdDetails);
